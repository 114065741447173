import axiosInstance from '../default-setup/AxiosInstance';

const defaultFields = {
    fld: [
        'id',
        'categoryCode',
        'label',
        'typeCode',
        'order',
        'isArchived',
        'createdAt',
        'updatedAt',
    ],
    srt: ['order'],
};

export default {
    fetchCategoryFields: function(categoryCode) {
        const requestUrl = `/jory/category-field`;

        let filter = {
            and: [
                {
                    field: 'categoryCode',
                    operator: '=',
                    data: categoryCode,
                },
            ],
        };

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    ...defaultFields,
                    filter,
                },
            },
        });
    },

};
