// @flow
import React from 'react';
import styled from '@emotion/styled';
import { ClipLoader } from 'react-spinners';

type Props = {
    children?: React.Node,
    type: string,
    variant: string,
    isLoading?: boolean,
    icon?: React.Node,
};

function ButtonSecondary(props: Props) {
    return (
        <StyledButton {...props} disabled={props.isLoading}>
            {props.icon ? <StyledIcon>{props.icon}</StyledIcon> : null}
            {props.children}{' '}
            {props.isLoading ? <ClipLoader color={'white'} size={17} /> : null}
        </StyledButton>
    );
}

ButtonSecondary.defaultProps = {
    type: 'button',
    variant: 'primary',
    icon: null,
};

export default ButtonSecondary;

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid
        ${props => props.theme.colors.button[props.variant].border};
    //border: 0;
    background: ${props => props.theme.colors.backgroundContainer};
    color: ${props => props.theme.colors.button[props.variant].border};
    border-radius: 3px;
    font-weight: 400;
    letter-spacing: 0.05rem;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 6px 8px;
    height: 28px;
    margin-left: 4px;

    &:active,
    :hover {
        background: ${props =>
            props.theme.colors.button[props.variant].backgroundDark};
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
        color: ${props => props.theme.colors.textWhite};
    }

    &:active {
        transform: scale(0.99);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &:focus {
        outline: 0;
    }
`;

const StyledIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
`;
