import axios from 'axios';
import axiosInstance from '../default-setup/AxiosInstance';

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

let cancelToken;

const defaultFields = {
    fld: [
        'id',
        'name',
        'address',
        'postalCode',
        'city',
        'email',
        'telephone',
        'lastTimeVisited',
        'isArchived',
    ],
};

export default {
    fetchCustomers: function(showWithArchived) {
        const requestUrl = `/jory/customer`;

        let filter = {};
        if (!showWithArchived) {
            filter = {
                flt: {
                    field: 'isArchived',
                    operator: '=',
                    data: false,
                },
            };
        }

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    fld: [
                        'id',
                        'name',
                        'address',
                        'postalCode',
                        'city',
                        'email',
                        'telephone',
                        'isArchived',
                        'fullAddress',
                    ],
                    srt: ['name'],
                    ...filter,
                },
            },
        });
    },

    fetchCustomerOptions: function() {
        const requestUrl = `/jory/customer`;

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    fld: ['id', 'name'],
                    srt: ['name'],
                    filter: {
                        field: 'isArchived',
                        operator: '=',
                        data: false,
                    },
                },
            },
        });
    },

    fetchCustomer: function(customerId) {
        const requestUrl = `/jory/customer/${customerId}`;

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    ...defaultFields,
                },
            },
        });
    },

    toggleArchive: function(customerId, toggleArchive) {
        let requestUrl = `/customer/${customerId}/${
            toggleArchive ? 'archive' : 'activate'
        }`;

        return axiosInstance.post(requestUrl);
    },

    lastVisited: function(customerId) {
        let requestUrl = `/customer/${customerId}/last-visited`;

        return axiosInstance.get(requestUrl);
    },

    importCustomers: function() {
        return axiosInstance.get('/customers/import');
    },

    fetchCustomerItemSearch: function(customerId, searchTermCustomerItem) {
        const requestUrl = `/customer/${customerId}/search?searchTerm=${searchTermCustomerItem}`;

        if (typeof cancelToken != typeof undefined) {
            //Check if there are any previous pending requests
            cancelToken.cancel('Api call canceled due to new request.');
        }

        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();

        return axiosInstance.get(requestUrl, {
            cancelToken: cancelToken.token,
        });
    },

};
