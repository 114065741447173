import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const StyledSidenavListItem = styled.li`
    display: flex;
    justify-content: left;
    align-content: baseline;
    padding-left: 30px;
    color: ${props => props.theme.colors.sidenav.color};
    border-radius: 3px;

    &:hover {
        background: ${props => props.theme.colors.sidenav.menuItemHover};
        cursor: pointer;
    }

    ${props =>
        props.active &&
        css`
            background: ${props.theme.colors.sidenav.menuItemActive};
        `}
`;

const StyledIcon = styled.div`
    flex: 1;
    font-size: 2rem;
`;

const StyledName = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
    text-align: left;
`;

function SidenavListItem({ icon, children, value, action, active }) {
    return (
        <StyledSidenavListItem onClick={() => action(value)} active={active}>
            <StyledIcon>{icon}</StyledIcon>
            <StyledName>{children}</StyledName>
        </StyledSidenavListItem>
    );
}

export default SidenavListItem;
