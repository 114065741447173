import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import AddUser from './add';
import EditUser from './edit';
import { H3 } from '../../../components/typography';
import UserAPI from '../../../api/user/UserAPI';
import ButtonSecondary from '../../../components/button/ButtonSecondary';
import ButtonGroup from '../../../components/button/ButtonGroup';
import UserListTable from './Table';
import LoadingView from '../../../components/loading-view';
import useUserPolicy from '../../../policies/useUserPolicy';
import TableTopBar from '../../../components/table/top-bar';
import { useNotification } from "../../../context/NotificationContext";

const LOCAL_STORAGE_WITH_ARCHIVED = `__customer-info-load_with_archived_users__`;

function UserList() {
    const [users, setUsers] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [isSavingArchive, setSavingArchive] = useState(false);
    const hasPermissionCreate = useUserPolicy('create');
    const showNotification = useNotification();

    const storedWithShowArchived = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_WITH_ARCHIVED)
    );
    const [showWithArchived, setShowWithArchived] = useState(
        storedWithShowArchived || false
    );

    useEffect(
        function() {
            (function callFetchUser() {
                setLoading(true);
                UserAPI.fetchUsers(showWithArchived)
                    .then(payload => {
                        setUsers(payload.data.data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        showNotification(
                            'Er is iets misgegaan met het laden van de gegevens. Herlaad de pagina.'
                        );
                        setLoading(false);
                    });
            })();
        },
        [showWithArchived]
    );

    function addUserToList(newUser) {
        setUsers([newUser, ...users]);
        setShowAddModal(false);
    }

    function updateUserInList(updatedUser) {
        const updatedItemsInList = users.map(user => {
            if (user.id === updatedUser.id) {
                user = updatedUser;
            }
            return user;
        });

        setUsers(updatedItemsInList);
        setShowEditModal(false);
    }

    function handleChangeShowWithArchived(value) {
        localStorage.setItem(
            LOCAL_STORAGE_WITH_ARCHIVED,
            JSON.stringify(value)
        );
        setShowWithArchived(value);
    }

    function updateIsArchivedUser(id, value) {
        setSavingArchive(true);
        UserAPI.setToArchivedUser(id, value)
            .then(function() {
                // If list shows archived then update user in list
                if (showWithArchived) {
                    const updatedItemsInList = users.map(user => {
                        if (user.id === id) user.isArchived = value;
                        return user;
                    });
                    setUsers(updatedItemsInList);
                    // If list don't show archived then remove user from list
                } else {
                    const updatedItemsInList = users.filter(
                        user => user.id !== id
                    );
                    setUsers(updatedItemsInList);
                }
                setShowEditModal(false);
                setSelectedRowData({});
                setSavingArchive(false);
            })
            .catch(function(error) {
                showNotification(
                    'Er is iets misgegaan met het opslaan. Probeer het nogmaals.'
                );
                setSavingArchive(false);
            });
    }
    return (
        <Container>
            <TitleBar>
                <H3>Gebruikers</H3>
                <ButtonGroup>
                    {hasPermissionCreate ? (
                        <ButtonSecondary
                            onClick={() => setShowAddModal(!showAddModal)}
                        >
                            Toevoegen
                        </ButtonSecondary>
                    ) : null}
                </ButtonGroup>
            </TitleBar>
            {showAddModal ? (
                <AddUser
                    closeModal={() => setShowAddModal(false)}
                    addUserToList={addUserToList}
                />
            ) : null}

            {isLoading ? (
                <LoadingView />
            ) : (
                <>
                    <TableTopBar
                        showWithArchived={showWithArchived}
                        handleChangeShowWithArchived={
                            handleChangeShowWithArchived
                        }
                    />
                    <UserListTable
                        users={users}
                        setSelectedRowData={setSelectedRowData}
                        setShowEditModal={setShowEditModal}
                    />
                </>
            )}
            {showEditModal ? (
                <EditUser
                    closeModal={() => setShowEditModal(false)}
                    updateUserInList={updateUserInList}
                    initialValues={selectedRowData}
                    updateIsArchivedUser={updateIsArchivedUser}
                    isSavingArchive={isSavingArchive}
                />
            ) : null}
        </Container>
    );
}

export default UserList;

/* --- START STYLING --- */
const Container = styled.div`
    margin: 20px;
    padding: 20px;
    background: ${props => props.theme.colors.backgroundContainer};
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    max-width: 1200px;
    width: 100vw;
`;

const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 2rem;
`;
/* --- END STYLING --- */
