import React, { useEffect, useState } from 'react';
import { useNotification } from "../../../context/NotificationContext";
import CurrentUserAPI from '../../../api/current-user/CurrentUserAPI';
import _ from 'lodash';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import LoadingView from '../../../components/loading-view';
import ButtonPrimaryTiny from '../../../components/button/ButtonPrimaryTiny';

function CustomersVisited({ history }) {
    const [customers, setCustomers] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const showNotification = useNotification();

    useEffect(function() {
        (function callFetchCustomer() {
            setLoading(true);
            CurrentUserAPI.fetchCustomersVisited()
                .then(payload => {
                    setCustomers(payload.data.data.customersVisited);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    showNotification(
                        'Er is iets misgegaan met het laden van de gegevens. Herlaad de pagina.'
                    );
                    setLoading(false);
                });
        })();
    }, []);

    function renderCustomersMostVisited() {
        let result = customers.reduce(function(acc, curr) {
            // Check if there exist an object in empty array whose CategoryId matches
            let isElemExist = acc.findIndex(function(item) {
                return item.id === curr.id;
            });
            if (isElemExist === -1) {
                let obj = {};
                obj.id = curr.id;
                obj.name = curr.name;
                obj.count = 1;
                acc.push(obj);
            } else {
                acc[isElemExist].count += 1;
            }
            return acc;
        }, []);

        result = result.map(item => {
            item.percentage = Math.round((item.count / customers.length) * 100);
            return item;
        });

        result = _.orderBy(result, ['percentage', 'name'], ['desc']);

        return (
            <Wrapper>
                {result.slice(0, 10).map(function(item) {
                    return (
                        <StyledButton
                            key={item.id}
                            onClick={() => history.push(`/klant/${item.id}`)}
                            variant={'primary'}
                        >
                            {`${item.name} ${item.percentage}%`}
                        </StyledButton>
                    );
                })}
            </Wrapper>
        );
    }

    return (
        <div style={{ width: '100%' }}>
            {isLoading ? (
                <LoadingContainer>
                    <LoadingView />
                </LoadingContainer>
            ) : customers.length > 0 ? (
                renderCustomersMostVisited()
            ) : (
                'Nog geen gegevens bekend'
            )}
        </div>
    );
}

export default withRouter(CustomersVisited);

/* --- START STYLING --- */
const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
`;

const StyledButton = styled(ButtonPrimaryTiny)`
    margin: 0.4rem;
`;
/* --- END STYLING --- */
