import React from 'react';
import styled from '@emotion/styled';
import { IoMdSunny, IoMdMoon } from 'react-icons/io';

const ThemeSwitch = styled.div`
    display: inline-block;
    height: 24px;
    position: relative;
    width: 48px;

    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const ThemeSwitchInput = styled.input`
    display: none;
`;

const Slider = styled.div`
    background-color: ${props => (props.toggled ? '#999' : '#f6c746')};
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
        background-color: #fff;
        bottom: 2px;
        content: '';
        height: 20px;
        left: 4px;
        position: absolute;
        transition: 0.4s;
        width: 20px;
        border-radius: 50%;
        transform: ${props =>
            props.toggled ? 'translateX(20px)' : 'translateX(0px)'};
    }
`;

const StyledIconSunny = styled(IoMdSunny)`
    position: relative;
    top: 3px;
    left: 8px;
    color: #252525;
    visibility: ${props => (props.toggled === 'true' ? 'hidden' : 'visible')};
    transition: 0.2s;
`;

const StyledIconMoon = styled(IoMdMoon)`
    position: relative;
    top: 3px;
    left: 3px;
    color: #f6c746;
    visibility: ${props => (props.toggled === 'true' ? 'visible' : 'hidden')};
    transition: 0.2s;
`;

function ToggleTheme({ toggled, onClick }) {
    return (
        <ThemeSwitch
            htmlFor="checkbox"
            onClick={onClick}
            data-testid={'dark/light-button'}
        >
            <ThemeSwitchInput type="checkbox" id="checkbox" value={toggled} />
            <Slider toggled={toggled}>
                <StyledIconMoon size={18} toggled={toggled.toString()} />
                <StyledIconSunny size={18} toggled={toggled.toString()} />
            </Slider>
        </ThemeSwitch>
    );
}

export default ToggleTheme;
