import React from 'react';
import CommonContainer from '../../components/layout/common-container';
import LogoXarisImage from '../../images/logo-xaris.png';
import styled from '@emotion/styled';
import { H1, P } from '../../components/typography';
import { Link } from 'react-router-dom';

const StyledLogoImage = styled.img`
    position: relative;
    padding: 0 1rem;
    max-width: 28rem;
`;

const StyledSection = styled.section`
    margin: 3rem auto;
`;

function NotFound() {
    return (
        <CommonContainer>
            <StyledLogoImage src={LogoXarisImage} />
            <StyledSection>
                <H1>
                    <strong>404:</strong> pagina niet gevonden!
                </H1>
                <P>
                    Helaas kunnen we de pagina niet vinden. Dit kan komen
                    doordat de pagina niet meer bestaat, of doordat er een
                    typefout is gemaakt bij het invoeren van het adres.
                </P>
                <P>
                    Ga terug naar de <Link to={'/'}>hoofdpagina</Link>
                </P>
            </StyledSection>
        </CommonContainer>
    );
}

export default NotFound;
