import React from 'react';
import styled from '@emotion/styled';
import { H4 } from '../../components/typography';
import CustomerList from './customer/list';
import CustomersVisited from './customers-visited';
import Search from './search';

function Dashboard() {
    return (
        <Container>
            <MainCards>
                <Card gridArea={'search'}>
                    <Search />
                </Card>
                <Card gridArea={'most-visited'} className={'mobile-hidden'}>
                    <StyledH4>Jouw meest bezochte klanten</StyledH4>
                    <CustomersVisited />
                </Card>

                <Card gridArea={'customers'}>
                    <StyledH4>Klanten</StyledH4>
                    <CustomerList />
                </Card>
            </MainCards>
        </Container>
    );
}

export default Dashboard;

/* --- START STYLING --- */
const MainCards = styled.div`
    display: grid;
    grid-template-columns: minmax(40rem, 120rem) 40rem;
    grid-template-rows: minmax(20rem, auto) 1fr;
    grid-template-areas:
        'search most-visited'
        'customers customers';
    grid-gap: 2rem;
    margin: 2rem;

    /* Medium-sized screen breakpoint (tablet, 1050px) */
    @media only screen and (max-width: 1050px) {
        grid-template-columns: 100%;
        grid-template-rows: minmax(18rem, 30rem) 1fr;
        grid-gap: 0.8rem;
        margin: 0.8rem;

        grid-template-areas:
            'search'
            'customers';
    }
`;

const Card = styled.div`
    grid-area: ${props => props.gridArea};
    display: flex;
    flex-direction: column;
    align-items: start;
    -webkit-column-break-inside: avoid;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    background: ${props => props.theme.colors.card.background.default};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
`;

const Container = styled.div`
    max-width: 1600px;
    width: 100%;
`;

const StyledH4 = styled(H4)`
    margin-top: 0;
    margin-bottom: 0;
`;
/* --- END STYLING --- */
