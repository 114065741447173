import React from 'react';
import { Global, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';

const GlobalStyle = props => {
    const theme = useTheme();

    return (
        <Global
            styles={css`
                @import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:300,400, 500, 600, 700,800,900');
                @import url('https://fonts.googleapis.com/css?family=Roboto:300,400, 500, 600, 700,800,900');

                *,
                *::after,
                *::before {
                    margin: 0;
                    padding: 0;
                    box-sizing: inherit;
                }

                html {
                    // This defines what 1rem is
                    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

                    /* Non-mobile styles for side nav responsiveness, 750px breakpoint */
                    @media only screen and (max-width: 750px) {
                        // width < 1200?
                        font-size: 50%; //1 rem = 8px, 8/16 = 50%
                    }

                    /* Non-mobile styles for side nav responsiveness, 900px breakpoint */
                    @media only screen and (max-width: 900px) {
                        // width < 900?
                        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
                    }
                }

                body {
                    box-sizing: border-box;
                    font-family: 'Roboto', 'Lato', sans-serif;
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 1.7;
                    color: ${theme.colors.textDefault};
                    height: 100%;
                    background: ${theme.colors.background};
                }

                ::selection {
                    background-color: ${theme.colors.primary};
                    color: #252525;
                }

                button,
                input,
                select,
                textarea {
                    color: inherit;
                    font-size: inherit;
                    font-family: inherit;
                }

                .mobile-hidden {
                    @media only screen and (max-width: 1050px) {
                        display: none;
                    }
                }
            `}
        />
    );
};

export default GlobalStyle;
