import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useDrag, useDrop } from 'react-dnd';
import { IoMdMove } from 'react-icons/all';

function FieldValueDragWrapper({ accept, moveRowValue, ...props }) {
    /* --- START DRAG AND DROP FUNCTIONALITY --- */
    const refDrop = useRef(null);
    const refDrag = useRef(null);

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: accept,
        hover(item, monitor) {
            if (!refDrop.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;

            if (dragIndex === hoverIndex) {
                return;
            }
        },
        drop(item, monitor) {
            if (!refDrop.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;

            if (dragIndex === hoverIndex) {
                return;
            }

            return { index: hoverIndex };
        },
        canDrop(item, monitor) {
            const dragIndex = item.index;
            const hoverIndex = props.index;
            const hasValue = props.value || props.valueEncrypted;

            if (dragIndex === hoverIndex) {
                return false;
            }

            if (hasValue) {
                return false;
            }
            return true;
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: accept, id: props.id, index: props.index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end(props, monitor) {
            const item = monitor.getItem();
            const dropResult = monitor.getDropResult();

            if (dropResult && dropResult.index !== undefined) {
                moveRowValue(item.index, dropResult.index);
            }
        },
    });

    drop(refDrop);
    drag(refDrag);

    const isActive = isOver && canDrop;
    /* --- END DRAG AND DROP FUNCTIONALITY --- */

    return (
        <DragWrapper
            key={props.id}
            isDragging={isDragging}
            ref={refDrop}
            isActive={isActive}
            canDrop={canDrop}
        >
            <StyledValueWrapper ref={preview}>
                {props.children}
            </StyledValueWrapper>
            <StyledDraggableWrapper ref={refDrag}>
                {props.value || props.valueEncrypted ? (
                    <StyledIconIoIoMdMove title={'Verplaats waarde'} />
                ) : null}
            </StyledDraggableWrapper>
        </DragWrapper>
    );
}

export default FieldValueDragWrapper;

/* --- START STYLING --- */
const DragWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    ${props =>
        props.isDragging &&
        css`
            input,
            textarea {
                background: ${props.theme.colors.greyLightest};
                color: ${props.theme.colors.textGrey};
            }
        `}

    ${props =>
        props.canDrop &&
        css`
            input,
            textarea {
                background: rgba(46, 139, 87, 0.6);
            }
        `};
    ${props =>
        props.isActive &&
        css`
            input,
            textarea {
                background: rgba(46, 139, 87, 0.9);
            }
        `}
`;

const StyledValueWrapper = styled.div`
    width: 100%;
`;
const StyledDraggableWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIconIoIoMdMove = styled(IoMdMove)`
    font-size: 2rem;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
    right: 0;
`;
/* --- END STYLING --- */
