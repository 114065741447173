import React, { createContext, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import categories from '../data/categories';
import moment from 'moment';
import CustomerItemAPI from "../api/customer-item/CustomerItemAPI";
import { useNotification } from "./NotificationContext";
import CustomerAPI from "../api/customer/CustomerAPI";

const CustomerItemsContext = createContext({
    setCustomerId: () => {},
    customerId: null,
    customer: {},
    isLoadingCustomer: true,
    setSelectedCustomerItemId: () => {},
    selectedCustomerItemId: null,
    selectedCustomerItem: {},
    isLoadingItems: true,
    showWithArchived: false,
    filteredCustomerItems: [],
    categoryFilter: '',
    searchFilter: '',
    activeCategories: [],
    addCustomerItem: () => {},
    addNewCustomerItem: () => {},
    duplicateCustomerItem: () => {},
    updateCustomerItem: () => {},
    removeCustomerItem: () => {},
    toggleCustomerArchive: () => {},
    handleChangeCategoryFilter: () => {},
    handleChangeShowWithArchived: () => {},
    handleChangeSearchFilter: () => {},
    handleChangeSort: () => {},
    setCustomerItemFieldOnArchived: () => {},
});

const DEFAULT_EMPTY_CUSTOMER_ITEM = {
    id: null,
    categoryCode: '',
    title: '',
    subTitle: '',
    isArchived: false,
    previousSavedPasswords: [],
    category: {
        name: '',
    },
    customerItemAttachments: [],
};

const CustomerItemsProvider = function(props) {
    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState({});
    const [isLoadingCustomer, setLoadingCustomer] = useState(true);
    const [isLoadingItems, setLoadingItems] = useState(true);
    const [customerItems, setCustomerItems] = useState([]);
    const [filteredCustomerItems, setFilteredCustomerItems] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState('all_items');
    const [sortBy, setSortBy] = useState('titleAZ');
    const [searchFilter, setSearchFilter] = useState('');
    const [showWithArchived, setShowWithArchived] = useState(false);
    const [activeCategories, setActiveCategories] = useState([]);
    const [selectedCustomerItem, setSelectedCustomerItem] = useState({});
    const [selectedCustomerItemId, setSelectedCustomerItemId] = useState(null);
    const [updateSelectedItem, setUpdateSelectedItem] = useState(false);
    const showNotification = useNotification();

    useEffect(
        function() {
            if (customerItems.length > 0) {
                fetchCustomerItemSearch().then(result => {
                        applyFiltersAndSorting(customerItems, result);
                    }
                );
            }
        },
        // eslint-disable-next-line
        [categoryFilter, searchFilter, sortBy]
    );
    useEffect(
        function() {
        if (filteredCustomerItems && filteredCustomerItems.length > 0) {

            // If no item is selected or selected item is not in filtered list
            if (selectedCustomerItemId === null ||
                !filteredCustomerItems.some(item => String(item.id) === String(selectedCustomerItemId))) {

                // Set selectedCustomerItemId to the first item's id
                setSelectedCustomerItemId(filteredCustomerItems[0].id);
            }
        }
    }, [filteredCustomerItems]);

    useEffect(
        function() {
            if (selectedCustomerItemId !== null) {
                if(selectedCustomerItemId.toString().includes('new_')){
                    setSelectedCustomerItem({});
                } else {
                    fetchCustomerItemWithFields(selectedCustomerItemId);
                }
            } else {
                setSelectedCustomerItem(null);
            }
            setUpdateSelectedItem(false)
        },
        [selectedCustomerItemId, updateSelectedItem === true]
    );
    useEffect(
        function() {
            if(customerId) {
                callFetchCustomer();
            }
        },
        [customerId, showWithArchived]
    );
    function callFetchCustomer() {
        setLoadingCustomer(true);
        setLoadingItems(true);

        CustomerAPI.fetchCustomer(customerId)
            .then(payload => {
                setCustomer(payload.data.data);
                setLoadingCustomer(false);
            })
            .catch(error => {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het laden van de klant gegevens. Herlaad de pagina.'
                );
                setLoadingCustomer(false);
            });

        fetchCustomerItems();

        CustomerAPI.lastVisited(customerId)
            .then()
            .catch(error => () => console.log(error));
    };

    function fetchCustomerItems() {
        CustomerItemAPI.fetchCustomerItems(customerId, showWithArchived)
            .then(payload => {
                const items = payload.data.data;

                handleChangeSearchFilter('');
                handleChangeCategoryFilter('all_items');
                handleChangeSort('titleAZ');

                setInitialCustomerItems(items, selectedCustomerItemId);

                setLoadingItems(false);
            })
            .catch(error => {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het laden van de klant item gegevens. Herlaad de pagina.'
                );
                setLoadingItems(false);
            });
    }


    const fetchCustomerItemSearch = async () => {
        if (searchFilter.length <= 1) return;

        try {
            const results = await CustomerAPI.fetchCustomerItemSearch(customerId, searchFilter);
            return results.data;
        } catch (error) {
            // console.log(error);
            return null;
        }
    }

    function fetchCustomerItemWithFields(selectedCustomerItemId) {
        CustomerItemAPI.fetchCustomerItem(selectedCustomerItemId, customerId)
            .then(payload => {
                setSelectedCustomerItem(payload.data.data);
            })
            .catch(error => {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het laden van de gegevens. Herlaad de pagina. (3)'
                );
                setSelectedCustomerItem({});
            });
    }

    function applyFiltersAndSorting(applyCustomerItems, customerItemFilterIds = null) {
        let filteredItems = null;

        if (!searchFilter || searchFilter.length <= 1) {
            filteredItems = applyCustomerItems;
        } else {
            filteredItems = applyCustomerItems.filter(item => {
                // Search in title and if something is found then return immediately
                if (item.title.toLowerCase().includes(searchFilter.toLowerCase())
                ) return true;

                if (customerItemFilterIds && customerItemFilterIds.includes(item.id)) return true;

                return false;
            });
        }

        filteredItems = applyCategoryFilter(filteredItems);
        setFilteredCustomerItems(applySorting(filteredItems));
    }


    function applyCategoryFilter(filteredItems) {
        if (categoryFilter === 'all_items') {
            return filteredItems;
        }

        return filteredItems.filter(item => {
            return item.categoryCode === categoryFilter;
        });
    }

    function applySorting(filteredItems) {
        switch (sortBy) {
            case 'titleAZ':
                return [...filteredItems].sort((a, b) => {
                    return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                });
            case 'titleZA':
                return [...filteredItems].sort((a, b) => {
                    return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
                });
            case 'lastUpdated':
                return orderBy(filteredItems, 'updatedAt', ['desc']);
            default:
                return filteredItems;
        }
    }

    function setInitialCustomerItems(payloadItems, selectedCustomerItemId) {
        defineActiveCategories(payloadItems);
        setCustomerItems(payloadItems);

        let filteredItems = applyCategoryFilter(payloadItems);
        setFilteredCustomerItems(applySorting(filteredItems));

        if(selectedCustomerItemId){
            setSelectedCustomerItemId(selectedCustomerItemId);
        } else if(payloadItems.length > 1){
            setSelectedCustomerItemId(payloadItems[0].id);
        }
    }

    function toggleCustomerArchive() {
        // todo WM: check wat bedoeling was van setLoading
        // setLoading(true);
        CustomerAPI.toggleArchive(customer.id, !customer.isArchived)
            .then(() => {
                setCustomer({ ...customer, isArchived: !customer.isArchived });

                // setLoading(false);
            })
            .catch(() => {
                showNotification(
                    'Er is iets misgegaan met het archiveren van de klant. Herlaad de pagina.'
                );

                // setLoading(false);
            });
    }


    function addCustomerItem(categoryCode, customerId, categoryFields) {
        const temporaryId = 'new_' + moment().unix();

        const newCustomerItem = {
            ...DEFAULT_EMPTY_CUSTOMER_ITEM,
            id: temporaryId,
            categoryCode,
            customerId,
            category: {
                name: categories.find(item => item.code === categoryCode).name,
            },
            customerItemFields: categoryFields.map((item, index) => {
                return {
                    id: 'new_' + index,
                    customerItemId: null,
                    categoryFieldId: item.id,
                    label: item.label,
                    typeCode: item.typeCode,
                    value: '',
                    valueEncrypted: '',
                    order: item.order,
                    isArchived: item.isArchived,
                };
            }),
        };
        setSelectedCustomerItemId(temporaryId);

        return newCustomerItem;
    }
    function duplicateCustomerItem(duplicatedCustomerItem, customerId) {
        const temporaryId = 'new_' + moment().unix();

        const newCustomerItem = {
            id: temporaryId,
            categoryCode: duplicatedCustomerItem.categoryCode ? duplicatedCustomerItem.categoryCode : '',
            customerId: customerId,
            title: duplicatedCustomerItem.title ? duplicatedCustomerItem.title : '',
            subTitle: '',
            isArchived: duplicatedCustomerItem.isArchived ? duplicatedCustomerItem.isArchived : '',
            previousSavedPasswords: [],
            category: {
                name: duplicatedCustomerItem.category && duplicatedCustomerItem.category.name ? duplicatedCustomerItem.category.name : '',
            },
            customerItemAttachments: duplicatedCustomerItem.customerItemAttachments ? duplicatedCustomerItem.customerItemAttachments : [],

            customerItemFields: duplicatedCustomerItem.customerItemFields && duplicatedCustomerItem.customerItemFields.map((item, index) => {
                return {
                    id: 'new_' + index,
                    customerItemId: null,
                    categoryFieldId: item.categoryFieldId,
                    label: item.label,
                    typeCode: item.typeCode,
                    value: item.value,
                    valueEncrypted: item.valueEncrypted,
                    order: item.order,
                    isArchived: item.isArchived,
                };
            }),
        };

        return newCustomerItem;
    }


    function defineActiveCategories(customerItems) {
        let categoryArray = [];

        customerItems.forEach(customerItem => {
            if (!categoryArray.includes(customerItem.categoryCode)) {
                categoryArray.push(customerItem.categoryCode);
            }
        });
        setActiveCategories(categoryArray);
    }

    function handleChangeSearchFilter(value, customerId) {
        setSearchFilter(value);
    }

    function handleChangeCategoryFilter(value) {
        setCategoryFilter(value);
    }

    function handleChangeShowWithArchived(withArchived) {
        setShowWithArchived(withArchived);
    }

    function handleChangeSort(value) {
        setSortBy(value);
    }

    function addNewCustomerItem(newCustomerItem) {
        const newItems = [...customerItems, newCustomerItem];
        setCustomerItems(newItems);
        applyFiltersAndSorting(newItems, null);
        setSelectedCustomerItemId(newCustomerItem.id);

        defineActiveCategories(newItems);
        setUpdateSelectedItem(true);
    }
    function updateCustomerItem(payload) {
        const updatedItems = customerItems.map(customerItem => {
            if (customerItem.id === payload.id) {
                return payload;
            }
            return customerItem;
        });

        setCustomerItems(updatedItems);
        applyFiltersAndSorting(updatedItems, null);
        defineActiveCategories(updatedItems, null);
        setUpdateSelectedItem(true);
    }

    function removeCustomerItem(id) {
        const updatedItems = customerItems.map(customerItem => {
            if (customerItem.id === id) {
                return {
                    ...customerItem,  // Spread operator to copy all other fields
                    isArchived: true  // Only override isArchived
                };
            }
            return customerItem;
        });
        setCustomerItems(updatedItems);
        applyFiltersAndSorting(updatedItems, null);
        defineActiveCategories(updatedItems, null);
        setUpdateSelectedItem(true);
    }

    function setCustomerItemFieldOnArchived(
        customerItemId,
        customerItemFieldId
    ) {
        const updatedItems = customerItems.map(customerItem => {
            if (customerItem.id === customerItemId) {
                return {
                    ...customerItem,
                    customerItemFields: customerItem.customerItemFields && customerItem.customerItemFields.map(
                        item => {
                            if (item.id === customerItemFieldId) {
                                item.isArchived = true;
                            }

                            return item;
                        }
                    ),
                };
            }
            return customerItem;
        });

        setCustomerItems(updatedItems);
        setUpdateSelectedItem(true);
    }

    return (
        <CustomerItemsContext.Provider
            value={{
                setCustomerId,
                customerId,
                customer,
                isLoadingCustomer,
                setSelectedCustomerItemId,
                selectedCustomerItemId,
                selectedCustomerItem,
                isLoadingItems,
                showWithArchived,
                filteredCustomerItems,
                categoryFilter,
                searchFilter,
                activeCategories,
                addCustomerItem,
                addNewCustomerItem,
                duplicateCustomerItem,
                updateCustomerItem,
                removeCustomerItem,
                toggleCustomerArchive,
                handleChangeCategoryFilter,
                handleChangeShowWithArchived,
                handleChangeSearchFilter,
                handleChangeSort,
                setCustomerItemFieldOnArchived,
            }}
        >
            {props.children}
        </CustomerItemsContext.Provider>
    );
};

const CustomerItemsConsumer = CustomerItemsContext.Consumer;

export { CustomerItemsProvider, CustomerItemsConsumer, CustomerItemsContext };
