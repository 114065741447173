import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled';

function ShowPreviousPasswords({ previousSavedPasswords }) {
    return (
        <Wrapper>
            <HeaderRow>
                <Column>Buiten gebruik</Column>
                <Column>Label</Column>
                <Column>Waarde</Column>
            </HeaderRow>
            {previousSavedPasswords.map(item => (
                <Row>
                    <Column>{moment(item.updatedAt).format('LLL')}</Column>
                    <Column>{item.label}</Column>
                    <Column>{item.valueEncrypted}</Column>
                </Row>
            ))}
        </Wrapper>
    );
}

export default ShowPreviousPasswords;

/* --- START STYLING --- */
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    margin-top: 2rem;
`;
const HeaderRow = styled.div`
    display: flex;
    color: ${props => props.theme.colors.textGrey};
    border-bottom: 1px solid ${props => props.theme.colors.textGrey};
`;
const Row = styled.div`
    display: flex;
`;

const Column = styled.div`
    width: 12rem;
`;
/* --- END STYLING --- */
