import React, { useState } from 'react';
import styled from '@emotion/styled';
import LogoXarisImage from '../../../images/logo-xaris.png';
import { H3 } from '../../../components/typography';
import LoginForm from './Form';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from '../../../context/AuthContext';
import AuthAPI from '../../../api/auth/AuthAPI';
import CommonContainer from '../../../components/layout/common-container';
import ErrorMessage from '../../../components/form/parts/ErrorMessage';
import { getMessageFromHttpCode } from '../../../helpers/getMessageFromHttpCode';

const StyledLogoImage = styled.img`
    position: relative;
    padding: 0 1rem;
    max-width: 28rem;
`;

const StyledLoginSection = styled.section`
    margin: 3rem auto;
`;

function Login({ location, login }) {
    const [error, setError] = useState('');
    const [redirectToReferrer, toggleRedirect] = useState(false);
    let { from } = location.state || { from: { pathname: '/' } };
    // const [duoSettings, setDuoSettings] = useState({
    //     host: '',
    //     sigRequest: '',
    // });
    const [loginCredentials, setLoginCredentials] = useState(null);

    function handleSubmit(values, setSubmittingFinished) {
        AuthAPI.login(values)
            .then(payload => {
                setError('');
                setLoginCredentials(values);
                setSubmittingFinished();
                if(payload.data.url){
                    window.location = payload.data.url;
                } else if(payload.data.access_token) {
                    login(payload.data.access_token, () => toggleRedirect(true));
                } else {
                    setError('Er is iets misgegaan met het verifieren van inloggevens. Probeer het nogmaals.');
                }
            })
            .catch(error => {
                // If login fails show error and then set submitting back to false
                setError(
                    getMessageFromHttpCode(
                        error.response ? error.response.status : null
                    )
                );
                setSubmittingFinished();
            });
    }

    function redirect() {
        return <Redirect to={from} />;
    }

    return (
        <>
            {redirectToReferrer ? (
                redirect()
            ) : (
                <CommonContainer>
                    <StyledLogoImage src={LogoXarisImage} />
                    <StyledLoginSection>
                        <H3>Inloggen in Customer Info 2.1.3</H3>
                        <LoginForm handleSubmit={handleSubmit} />
                        <ErrorMessage fontSize={'1.4rem'}>{error}</ErrorMessage>
                    </StyledLoginSection>
                </CommonContainer>
            )}
        </>
    );
}

export default function LoginWithContext(props) {
    return (
        <AuthConsumer>
            {({ login }) => <Login {...props} login={login} />}
        </AuthConsumer>
    );
}
