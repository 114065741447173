import React from 'react';
import styled from '@emotion/styled';

const StyledMain = styled.main`
    grid-area: main;
    display: flex;
    justify-content: center;
`;

function Main({ children }) {
    return <StyledMain id={'main-portal'}>{children}</StyledMain>;
}

export default Main;
