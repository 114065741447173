import React from 'react';
import styled from '@emotion/styled';
import ButtonPrimaryTiny from '../../../../../../../components/button/ButtonPrimaryTiny';
import { useNotification } from "../../../../../../../context/NotificationContext";

function TextField({ field }) {

    const showNotification = useNotification();
    function copyField() {
        navigator.clipboard.writeText(field.value);
        showNotification('Gegevens gekopieerd!', 'info');
    }

    function formattedString(value) {
        // If string has an url then return url as link
        let urlsInString = field.value.match(/\bhttps?:\/\/\S+/gi);

        if (urlsInString) {
            return (
                <a href={urlsInString[0]} target="blank">
                    {field.value}
                </a>
            );
        } else {
            return value;
        }
    }

    return (
        <DisplayTextField key={field.id}>
            <StyledLabel>{field.label}</StyledLabel>
            <StyledDisplayText>
                {formattedString(field.value)}{' '}
                <ButtonPrimaryTiny
                    className={'action-button'}
                    onClick={copyField}
                >
                    Kopieer
                </ButtonPrimaryTiny>
            </StyledDisplayText>
        </DisplayTextField>
    );
}

export default TextField;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledLabel = styled.label`
    flex: 1;
    text-align: end;
    padding: 0.4rem 1.2rem;
    color: ${props => props.theme.colors.textGrey};

    @media only screen and (max-width: 900px) {
        text-align: start;
        flex: 2;
    }
`;

const StyledDisplayText = styled.div`
    flex: 2;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
    white-space: pre-wrap;
    border: 2px dotted transparent;

    @media only screen and (max-width: 900px) {
        text-align: start;
        flex: 5;
    }

    .action-button {
        display: none;
    }

    &:hover {
        border-radius: 5px;
        border-color: ${props => props.theme.colors.grey};

        .action-button {
            display: inline;
        }
    }
`;
/* --- END STYLING --- */
