import React from 'react';
import { ThemeProvider } from './context/ThemeContext';
import GlobalStyle from './components/theme/GlobalStyle';
import { AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import Routes from './route/Routes';
import { CustomerItemsProvider } from './context/CustomerItemsContext';

function App() {
    return (
        <ThemeProvider>
            <GlobalStyle />
            <AuthProvider>
                <UserProvider>
                    <CustomerItemsProvider>
                        <Routes />
                    </CustomerItemsProvider>
                </UserProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
