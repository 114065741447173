import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import ErrorMessage from './parts/ErrorMessage';
import Label from './parts/Label';
import InputPassword from './parts/InputPassword';

const StyledInputPasswordSet = styled.div`
    display: flex;
    flex-direction: column;
`;

function InputPasswordSet({ label, required, autoComplete, ...props }) {
    const [field, meta] = useField(props);

    return (
        <StyledInputPasswordSet>
            <Label htmlFor={field.id || field.name} required={required}>
                {label}
            </Label>
            <InputPassword {...props} autoComplete={autoComplete} />
            {meta.touched && meta.error ? (
                <ErrorMessage testId={field.id || field.name}>
                    {meta.error}
                </ErrorMessage>
            ) : null}
        </StyledInputPasswordSet>
    );
}

InputPasswordSet.defaultProps = {
    required: false,
    autocomplete: 'off',
};

export default InputPasswordSet;
