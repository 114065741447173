// @flow
import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
    children: React.Node,
};

function ButtonGroup({ children }: Props) {
    return <StyledButtonGroup>{children}</StyledButtonGroup>;
}

export default ButtonGroup;

const StyledButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-of-type) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0;
        margin-left: 0;
        border-right: 0;
    }

    & > button:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-right: 0;
        margin-left: 0;
    }
`;
