import React, {useContext} from 'react';
import styled from '@emotion/styled';
import { getCategoryIcon } from '../../../../../../helpers/getCategoryIcon';
import TextField from './parts/TextField';
import * as Yup from 'yup';
import { FieldArray, Formik, useField } from 'formik';
import CustomerItemDetailsWrapper from '../ItemWrapper';
import CustomerItemAPI from '../../../../../../api/customer-item/CustomerItemAPI';
import { CustomerItemsContext } from '../../../../../../context/CustomerItemsContext';
import PasswordField from './parts/PasswordField';
import SectionField from './parts/SectionField';
import ButtonGroup from '../../../../../../components/button/ButtonGroup';
import TextAreaField from './parts/TextAreaField';
import InputText from '../../../../../../components/form/parts/InputText';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNotification } from "../../../../../../context/NotificationContext";
import CustomerItemFieldAPI from '../../../../../../api/customer-item-field/CustomerItemFieldAPI';
import ErrorMessage from '../../../../../../components/form/parts/ErrorMessage';
import AddFieldDropdown from '../../../../../../components/form/helpers/AddFieldDropdown';
import ViewCategoryField from "./parts/ViewCategoryField";

function CustomerItemDetailsNew({ showDuplicate, newCustomerItem, toggleShowNew, toggleShowDuplicate, handleSetSelectNewItem }) {

    const {
        addNewCustomerItem,
    } = useContext(CustomerItemsContext);
    const showNotification = useNotification();

    function processStoreCustomerItem(values, actions, originalId) {
        CustomerItemAPI.storeCustomerItem(
            setDefaultIdValueForNewItems(setFieldOrderValue(values))
        )
            .then(function(payload) {
                addNewCustomerItem(payload.data.data);
                handleSetSelectNewItem(payload.data.data.id);
                actions.setSubmitting(false);
                showDuplicate ? toggleShowDuplicate() : toggleShowNew();
            })
            .catch(function(error) {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het opslaan. Probeer het nogmaals.'
                );
                actions.setSubmitting(false);
            });
    }

    function setFieldOrderValue(values) {
        return {
            ...values,
            customerItemFields: values.customerItemFields.map((item, index) => {
                return { ...item, order: index };
            }),
        };
    }

    function setDefaultIdValueForNewItems(values) {
        return {
            ...values,
            customerItemFields: values.customerItemFields.map(item => {
                const itemId = item.id.toString();
                if (itemId.includes('new_')) {
                    item.id = null;
                }
                return item;
            }),
        };
    }

    function toggleArchive(customerItemFieldId, removeFromArray, closeModal) {
        if (customerItemFieldId.toString().includes('new_')) {
            removeFromArray(customerItemFieldId);
            return;
        }

        CustomerItemFieldAPI.toggleArchive(customerItemFieldId, true)
            .then(function() {
                removeFromArray(customerItemFieldId);
                closeModal();
            })
            .catch(function(error) {
                showNotification(
                    'Er is iets misgegaan met het archiveren van het veld. Herlaad de pagina.'
                );
            });
    }

    return (
        <Formik
            initialValues={newCustomerItem}
            validationSchema={Yup.object({
                title: Yup.string().required('Verplicht'),
                customerItemFields: Yup.array().of(
                    Yup.object().shape({
                        label: Yup.string().required('Verplicht'),
                    })
                ),
            })}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                processStoreCustomerItem(values, actions);
            }}
        >
            {({ isSubmitting, handleSubmit, values, resetForm }) => {

                return (
                    <CustomerItemDetailsWrapper
                        showDuplicate={showDuplicate}
                        toggleShowNew={toggleShowNew}
                        toggleShowDuplicate={toggleShowDuplicate}
                        customerItem={newCustomerItem}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        resetForm={resetForm}
                        action={'new'}

                    >
                        <EditWrapper>
                            {showDuplicate &&
                                (
                                    <TitleWrapper>
                                        <TitleText>Duplicate van: {newCustomerItem.title}</TitleText>
                                    </TitleWrapper>
                                )
                            }

                            <TitleWrapper>
                                <TitleIcon>
                                    {getCategoryIcon(
                                        values.categoryCode,
                                        'primary',
                                        '3.6'
                                    )}
                                </TitleIcon>
                                <TitleInput name={'title'} />
                            </TitleWrapper>
                            <ViewCategoryField
                                label={'Categorie'}
                                name={'categoryCode'}
                                customerItemId={values.id}
                            />

                            <CustomerItemWrapper>
                                <FieldArray
                                    name="customerItemFields"
                                    render={arrayHelpers => {
                                        function moveRow(fromIndex, toIndex) {
                                            arrayHelpers.swap(
                                                fromIndex,
                                                toIndex
                                            );
                                        }

                                        function moveRowValue(
                                            fromIndex,
                                            toIndex
                                        ) {
                                            const hoverItem = {
                                                ...values['customerItemFields'][
                                                    toIndex
                                                ],
                                                value:
                                                    values[
                                                        'customerItemFields'
                                                    ][fromIndex].value,
                                                valueEncrypted:
                                                    values[
                                                        'customerItemFields'
                                                    ][fromIndex].valueEncrypted,
                                            };

                                            const dragItem = {
                                                ...values['customerItemFields'][
                                                    fromIndex
                                                ],
                                                value: '',
                                                valueEncrypted: '',
                                            };

                                            arrayHelpers.replace(
                                                fromIndex,
                                                dragItem
                                            );
                                            arrayHelpers.replace(
                                                toIndex,
                                                hoverItem
                                            );
                                        }

                                        function handleToggleArchived(
                                            id,
                                            closeModal
                                        ) {
                                            toggleArchive(
                                                id,
                                                removeFromArray,
                                                closeModal
                                            );
                                        }

                                        function removeFromArray(id) {
                                            arrayHelpers.remove(
                                                values.customerItemFields.findIndex(
                                                    item => item.id === id
                                                )
                                            );
                                        }

                                        return (
                                            <>
                                                {values.customerItemFields &&
                                                values.customerItemFields
                                                    .length > 0 ? (
                                                    <DndProvider
                                                        backend={HTML5Backend}
                                                    >
                                                        {values.customerItemFields.map(
                                                            (
                                                                customerItemField,
                                                                index
                                                            ) => {
                                                                switch (
                                                                    customerItemField.typeCode
                                                                ) {
                                                                    case 'password':
                                                                        return (
                                                                            <PasswordField
                                                                                key={
                                                                                    customerItemField.id
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                label={
                                                                                    customerItemField.label
                                                                                }
                                                                                name={`customerItemFields.${index}`}
                                                                                {...customerItemField}
                                                                                moveRow={
                                                                                    moveRow
                                                                                }
                                                                                moveRowValue={
                                                                                    moveRowValue
                                                                                }
                                                                                handleToggleArchived={
                                                                                    handleToggleArchived
                                                                                }
                                                                            />
                                                                        );
                                                                    case 'section':
                                                                        return (
                                                                            <SectionField
                                                                                key={
                                                                                    customerItemField.id
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                label={
                                                                                    customerItemField.label
                                                                                }
                                                                                name={`customerItemFields.${index}`}
                                                                                {...customerItemField}
                                                                                moveRow={
                                                                                    moveRow
                                                                                }
                                                                                handleToggleArchived={
                                                                                    handleToggleArchived
                                                                                }
                                                                            />
                                                                        );
                                                                    case 'note':
                                                                        return (
                                                                            <TextAreaField
                                                                                key={
                                                                                    customerItemField.id
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                label={
                                                                                    customerItemField.label
                                                                                }
                                                                                name={`customerItemFields.${index}`}
                                                                                {...customerItemField}
                                                                                moveRow={
                                                                                    moveRow
                                                                                }
                                                                                moveRowValue={
                                                                                    moveRowValue
                                                                                }
                                                                                handleToggleArchived={
                                                                                    handleToggleArchived
                                                                                }
                                                                            />
                                                                        );
                                                                    default:
                                                                        return (
                                                                            <TextField
                                                                                key={
                                                                                    customerItemField.id
                                                                                }
                                                                                name={`customerItemFields.${index}`}
                                                                                index={
                                                                                    index
                                                                                }
                                                                                moveRow={
                                                                                    moveRow
                                                                                }
                                                                                moveRowValue={
                                                                                    moveRowValue
                                                                                }
                                                                                {...customerItemField}
                                                                                handleToggleArchived={
                                                                                    handleToggleArchived
                                                                                }
                                                                            />
                                                                        );
                                                                }
                                                            }
                                                        )}
                                                    </DndProvider>
                                                ) : (
                                                    <div>
                                                        Geen items gevonden
                                                    </div>
                                                )}
                                                <ButtonGroup>
                                                    <AddFieldDropdown
                                                        arrayHelpers={
                                                            arrayHelpers
                                                        }
                                                        emptyValueObject={{
                                                            id: `new_${values.customerItemFields.length}`,
                                                            customerItemId: newCustomerItem.id,
                                                            categoryFieldId: null,
                                                            value: '',
                                                            valueEncrypted: '',
                                                            order:
                                                                values
                                                                    .customerItemFields
                                                                    .length,
                                                        }}
                                                    />
                                                </ButtonGroup>
                                            </>
                                        );
                                    }}
                                />
                            </CustomerItemWrapper>
                        </EditWrapper>
                    </CustomerItemDetailsWrapper>
                );
            }}
        </Formik>
    );
}

export default CustomerItemDetailsNew;

const TitleInput = props => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <StyledTitleInputWrapper>
            <StyledTitleInput
                {...field}
                {...props}
                type="text"
                autoComplete="off"
            />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </StyledTitleInputWrapper>
    );
};

/* --- START STYLING --- */
const EditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`;

// const TitleTextWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//
//     @media only screen and (max-width: 900px) {
//         justify-content: flex-start;
//     }
// `;
// const TitleText = styled.h3`
//     font-size: 2rem;
//     font-weight: 500;
//     line-height: 2.2rem;
//     color: ${props => props.theme.colors.successDark};
// `;
const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;

    align-content: flex-start;
`;

const TitleText = styled.h3`
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.2rem;
`;

const TitleIcon = styled.div`
    margin: 0 1.6rem;
`;

const StyledTitleInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTitleInput = styled(InputText)`
    font-size: 2rem;
    font-weight: 500;
    height: 3.6rem;
    width: 50rem;
`;

const CustomerItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
`;
/* --- END STYLING --- */
