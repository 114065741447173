const fieldTypes = [
    { code: 'text', name: 'Tekst' },
    { code: 'password', name: 'Wachtwoord' },
    { code: 'link', name: 'Link' },
    { code: 'email', name: 'Email' },
    { code: 'note', name: 'Notitie' },
    { code: 'section', name: 'Sectie' },
    { code: 'date', name: 'Datum' },
];

export default fieldTypes;
