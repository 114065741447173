import React, { useState } from 'react';
import Sidenav from '../sidenav';
import Header from '../header';
import Main from '../main';
import styled from '@emotion/styled';
import Footer from '../footer';

const Container = styled.div`
    -webkit-box-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: flex;
    width: 100%;
`;

const GridWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr; /* Side nav is hidden on mobile */
    grid-template-rows: 50px 1fr 30px;
    grid-template-areas:
        'header'
        'main'
        'footer';
    height: 100vh;
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out;

    .mobile-hidden {
        @media only screen and (max-width: 900px) {
            display: none;
        }
    }
`;

function BaseContainer({ children }) {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Container>
            <Sidenav menuOpen={menuOpen} closeMenu={() => setMenuOpen(false)} />

            <GridWrapper menuOpen={menuOpen}>
                <Header setMenuOpen={setMenuOpen} menuOpen={menuOpen} />

                <Main>{children}</Main>
                <Footer />
            </GridWrapper>
        </Container>
    );
}

export default BaseContainer;
