import React from 'react';
import styled from '@emotion/styled';
import InputText from '../../../../../../../components/form/parts/InputText';
import TextArea from '../../../../../../../components/form/parts/TextArea';
import FieldWrapper from '../FieldWrapper';
import ErrorMessage from '../../../../../../../components/form/parts/ErrorMessage';
import { useField } from 'formik';
import FieldValueDragWrapper from './FieldValueWrapper';

function TextAreaField(props) {
    const [meta] = useField(props);

    return (
        <FieldWrapper {...props}>
            <StyledLabel
                htmlFor={props.id || props.name}
                hasInputField={props.categoryFieldId === null ? true : false}
            >
                {props.categoryFieldId === null ? (
                    <>
                        <InputText
                            {...props}
                            name={`${props.name}.label`}
                            placeholder={'Label'}
                        />
                        {meta.touched && meta.error && meta.error.label ? (
                            <ErrorMessage>{meta.error.label}</ErrorMessage>
                        ) : null}
                    </>
                ) : (
                    props.label
                )}
            </StyledLabel>
            <StyledDisplayText>
                <FieldValueDragWrapper {...props} accept={'textAreaField'}>
                    <TextArea
                        {...props}
                        name={`${props.name}.value`}
                        placeholder={'Waarde'}
                    />
                </FieldValueDragWrapper>
            </StyledDisplayText>
        </FieldWrapper>
    );
}

export default TextAreaField;

/* --- START STYLING --- */
const StyledLabel = styled.label`
    flex: 2;
    text-align: end;
    padding: ${props => (props.hasInputField ? '0.4' : '1.2')}rem 0.8rem;
    color: ${props => props.theme.colors.textGrey};
`;

const StyledDisplayText = styled.div`
    flex: 4;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
`;
/* --- END STYLING --- */
