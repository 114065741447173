import React, { useEffect, useState } from 'react';
import PasswordStrengthMeter from '../../../../../../../components/password-strength-meter';
import styled from '@emotion/styled';
import { useNotification } from "../../../../../../../context/NotificationContext";
import ButtonPrimaryTiny from '../../../../../../../components/button/ButtonPrimaryTiny';

function PasswordField({ field }) {
    const [showPassword, setShowPassword] = useState(false);
    const showNotification = useNotification();

    useEffect(
        function() {
            setShowPassword(false);
        },
        [field.valueEncrypted]
    );

    function copyField() {
        navigator.clipboard.writeText(field.valueEncrypted);
        showNotification('Gegevens gekopieerd!', 'info');
    }

    function toggleShowField() {
        setShowPassword(!showPassword);
    }

    return (
        <DisplayTextField key={field.id}>
            <StyledLabel>{field.label}</StyledLabel>
            <StyledDisplayText>
                {showPassword ? field.valueEncrypted : '••••••••••'}
                <ButtonPrimaryTiny
                    className={'action-button'}
                    onClick={copyField}
                >
                    Kopieer
                </ButtonPrimaryTiny>
                <ButtonPrimaryTiny
                    className={'action-button'}
                    onClick={toggleShowField}
                >
                    {showPassword ? 'Verberg' : 'Toon'}
                </ButtonPrimaryTiny>
                <PasswordStrengthMeter password={field.valueEncrypted} />
            </StyledDisplayText>
        </DisplayTextField>
    );
}

export default PasswordField;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledLabel = styled.label`
    flex: 1;
    text-align: end;
    padding: 0.4rem 1.2rem;
    color: ${props => props.theme.colors.textGrey};

    @media only screen and (max-width: 900px) {
        text-align: start;
        flex: 2;
    }
`;

const StyledDisplayText = styled.div`
    flex: 2;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
    border: 2px dotted transparent;

    @media only screen and (max-width: 900px) {
        flex: 5;
    }

    .action-button {
        display: none;
    }

    &:hover {
        border-radius: 5px;
        border-color: ${props => props.theme.colors.grey};

        .action-button {
            display: inline;
        }
    }
`;
/* --- END STYLING --- */
