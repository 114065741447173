import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from '../context/AuthContext';
import BaseContainer from '../components/layout/base-container';
import CurrentUserAPI from '../api/current-user/CurrentUserAPI';
import { UserConsumer } from '../context/UserContext';
import IdleTimer from '../helpers/IdleTimer';

const ProtectedRoute = ({
    component: Component,
    updateUser,
    updateSecureIpAddress,
    isAuth,
    ...rest
}) => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (isAuth) {
            CurrentUserAPI.fetchLoggedInUser()
                .then(payload => {
                    updateUser(payload.data.data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    alert(
                        'Er is iets misgegaan met laden. Herlaad de pagina opnieuw.'
                    );
                });
            CurrentUserAPI.fetchSecureIpAddresses()
                .then(payload => {
                    updateSecureIpAddress(payload.data.data);
                })
                .catch(error => {
                    alert(
                        'Er is iets misgegaan met laden. Herlaad de pagina opnieuw.'
                    );
                });
        }
    },
    // eslint-disable-next-line
    [isAuth]);

    return (
        <BaseContainer>
            <AuthConsumer>
                {({ isAuth, logout }) => (
                    <IdleTimer logout={logout}>
                        <Route
                            render={props =>
                                isAuth ? (
                                    !isLoading ? (
                                        <Component {...props} />
                                    ) : null
                                ) : (
                                    <Redirect to="/login" />
                                )
                            }
                            {...rest}
                        />
                    </IdleTimer>
                )}
            </AuthConsumer>
        </BaseContainer>
    );
};

export default function ProtectedRouteWithContext(props) {
    return (
        <AuthConsumer>
            {({ isAuth }) => (
                <UserConsumer>
                    {({ updateUser, updateSecureIpAddress }) => (
                        <ProtectedRoute
                            {...props}
                            isAuth={isAuth}
                            updateUser={updateUser}
                            updateSecureIpAddress={updateSecureIpAddress}
                        />
                    )}
                </UserConsumer>
            )}
        </AuthConsumer>
    );
}
