import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { FaSort, IoIosTrash } from 'react-icons/all';
import { useDrag, useDrop } from 'react-dnd';
import { css } from '@emotion/core';
import Modal from '../../../../../../components/modal';

function FieldWrapper(props) {
    const [showDelete, setShowDelete] = useState(false);

    /* --- START DRAG AND DROP FUNCTIONALITY --- */
    const refDrop = useRef(null);
    const refDrag = useRef(null);

    const [, drop] = useDrop({
        accept: 'categoryField',
        hover(item, monitor) {
            if (!refDrop.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = refDrop.current.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            props.moveRow(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'categoryField', id: props.id, index: props.index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end(item, monitor) {},
    });

    drop(refDrop);
    drag(refDrag);
    /* --- END DRAG AND DROP FUNCTIONALITY --- */

    /* DELETE FUNCTIONS */
    function toggleShowDelete() {
        setShowDelete(!showDelete);
    }

    function confirmDelete() {
        props.handleToggleArchived(props.id, toggleShowDelete);
    }

    return (
        <>
            <DisplayTextField
                key={props.id}
                isDragging={isDragging}
                ref={props.categoryFieldId === null ? refDrop : null}
            >
                {props.children}
                <StyledActions>
                    {props.categoryFieldId === null ? (
                        <span ref={refDrag}>
                            <StyledIconFaSort title={'Verplaats regel'} />
                        </span>
                    ) : null}
                    {props.categoryFieldId === null ? (
                        <StyledIconIoIosTrash
                            title={'Verwijder'}
                            onClick={toggleShowDelete}
                        />
                    ) : null}
                </StyledActions>
            </DisplayTextField>
            {showDelete ? (
                <Modal
                    variant={'danger'}
                    title={'Verwijderen'}
                    closeModal={toggleShowDelete}
                    confirmAction={confirmDelete}
                    confirmText={'Verwijder'}
                >
                    Weet je zeker, dat je item{' '}
                    <strong>{props.label ? props.label : 'Geen label'}</strong>{' '}
                    wilt verwijderen?
                </Modal>
            ) : null}
        </>
    );
}

export default FieldWrapper;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;

    ${props =>
        props.isDragging &&
        css`
            background: ${props.theme.colors.background};
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.32);
            transform: rotate(0.8deg);
            border-radius: 4px;
        `}
`;

const StyledActions = styled.div`
    flex: 1;
    padding: ${props => (props.hasInputField ? '0.4' : '1.2')}rem 0.4rem;
    width: 4rem;
`;

const StyledIconFaSort = styled(FaSort)`
    font-size: 2rem;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
`;

const StyledIconIoIosTrash = styled(IoIosTrash)`
    font-size: 2rem;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
`;

/* --- END STYLING --- */
