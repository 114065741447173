import React from 'react';
import { Formik, Form } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import InputTextSet from '../../../components/form/InputTextSet';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import ButtonLinkTertiary from '../../../components/button/ButtonLinkTertiary';

const StyledLoginFormSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledLoginButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
`;

function ForgotForm({ handleSubmit, showSuccessMessage }) {
    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('Ongeldig e-mail adres')
                    .required('Verplicht'),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <StyledLoginFormSection>
                            <InputTextSet
                                label="E-mail"
                                name="email"
                                placeholder="mijn@email.nl"
                                data-testid={'email'}
                            />
                            <StyledLoginButtons>
                                <ButtonLinkTertiary to={'/login'}>
                                    Terug naar login
                                </ButtonLinkTertiary>
                                <ButtonPrimary
                                    isLoading={isSubmitting}
                                    disabled={
                                        isSubmitting || showSuccessMessage
                                    }
                                    type={'submit'}
                                >
                                    {showSuccessMessage
                                        ? 'E-mail is verstuurd!'
                                        : 'Verstuur wachtwoord reset link'}
                                </ButtonPrimary>
                            </StyledLoginButtons>
                        </StyledLoginFormSection>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ForgotForm;
