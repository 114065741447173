import React, { useMemo, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { css } from '@emotion/core';
import {
    MdChevronLeft,
    MdChevronRight,
    MdFirstPage,
    MdLastPage,
} from 'react-icons/all';
import ButtonPrimary from '../../button/ButtonPrimary';
import ButtonGroup from '../../button/ButtonGroup';
import TableDefaultBodyRow from './tbody/Row';
import matchSorter from "match-sorter";

function TableDefault({
    columns,
    data,
    tableName,
    handleOnClickRow,
    defaultPageSize,
    usePolicy,
    usePolicyType,
    // showFooter,
}) {
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [pageIndex, setPageIndex] = useState(0);
    const [filters, setFilters] = useState({});
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortedDirection, setSortedDirection] = useState(null);

    const LOCAL_STORAGE_FILTER_NAME = `__customer-info-filters_${tableName}__`;
    const LOCAL_STORAGE_SORT_BY = `__customer-info-sort_by_${tableName}__`;

    useEffect(() => {
        // Load filters from local storage
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_FILTER_NAME);
        if (storedFilters) {
            setFilters(JSON.parse(storedFilters));
        }

        // Load sortBy from local storage
        const storedSortBy = localStorage.getItem(LOCAL_STORAGE_SORT_BY);
        if (storedSortBy) {
            const { sortedColumn, sortedDirection } = JSON.parse(storedSortBy);
            setSortedColumn(sortedColumn);
            setSortedDirection(sortedDirection);
        }
    }, [LOCAL_STORAGE_FILTER_NAME, LOCAL_STORAGE_SORT_BY]);

    useEffect(() => {
        // Save filters to local storage
        localStorage.setItem(LOCAL_STORAGE_FILTER_NAME, JSON.stringify(filters));
    }, [filters, LOCAL_STORAGE_FILTER_NAME]);

    useEffect(() => {
        // Save sortedColumn and sortedDirection to local storage
        localStorage.setItem(LOCAL_STORAGE_SORT_BY, JSON.stringify({ sortedColumn, sortedDirection }));
    }, [sortedColumn, sortedDirection, LOCAL_STORAGE_SORT_BY]);

    // Apply filters to data
    const filteredData = useMemo(() => {
        return data.filter((record) => {
            // Check if record matches all filter criteria
            return Object.entries(filters).every(([key, value]) =>
                String(record[key]).toLowerCase().includes(value.toLowerCase())
            );
        });
    }, [data, filters]);

    // Apply sorting to filtered data
    const sortedData = useMemo(() => {
        if (!sortedColumn) return filteredData;

        return filteredData.slice().sort((a, b) => {
            const aValue = a[sortedColumn];
            const bValue = b[sortedColumn];

            if (aValue < bValue) return sortedDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortedDirection === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredData, sortedColumn, sortedDirection]);

    const shouldShowPaginator = sortedData.length > pageSize;
    const totalPages = Math.ceil(sortedData.length / pageSize);

    // Paginate data
    const paginatedData = useMemo(() => {
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        return sortedData.slice(startIndex, endIndex);
    }, [sortedData, pageIndex, pageSize]);

    // Function to handle sorting
    const handleSort = (column) => {
        if (sortedColumn === column) {
            // Reverse sort direction if already sorted by this column
            setSortedDirection(sortedDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Sort by new column
            setSortedColumn(column);
            setSortedDirection('asc');
        }
    };

    // Function to handle filtering
    const handleFilterChange = (e, column) => {
        const { value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [column]: value,
        }));
    };

    function customAddressFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, {
            keys: [
                row => row.original['address'],
                row => row.original['houseNumber'],
                row => row.original['postalCode'],
                row => row.original['city'],
            ],
            threshold: matchSorter.rankings.CONTAINS,
        });
    }

    // Function to go to previous page
    const goToPreviousPage = () => {
        setPageIndex((prevIndex) => Math.max(0, prevIndex - 1));
    };

    // Function to go to next page
    const goToNextPage = () => {
        setPageIndex((prevIndex) => Math.min(prevIndex + 1, totalPages - 1));
    };

    // Function to go to first page
    const goToFirstPage = () => {
        setPageIndex(0);
    };

    // Function to go to last page
    const goToLastPage = () => {
        setPageIndex(totalPages - 1);
    };

    // Function to check if first page is currently shown
    const isFirstPage = pageIndex === 0;

    // Function to check if last page is currently shown
    const isLastPage = pageIndex === totalPages - 1;

    return (
        <StyledTable>
            <table>
                <thead>
                <tr>
                    <StyledTh width={18} maxWidth={'18px'} />
                    {columns.map((column, index) => {
                        return (
                            <StyledTh
                                key={index}
                                style={{ width: column.width }}
                            >
                                <StyledTitleAndSort>
                                    {column.header}
                                    {/* Add a sort direction indicator */}
                                    <StyledIconSort
                                        active={
                                            column.canSort &&
                                            column.accessorKey === sortedColumn
                                        }
                                        onClick={() => handleSort(column.accessorKey)}
                                    >
                                        {column.canSort ? (
                                            column.accessorKey === sortedColumn ? (
                                                sortedDirection === 'desc' ? (
                                                    <FaSortAmountUp />
                                                ) : (
                                                    <FaSortAmountDown />
                                                )
                                            ) : (
                                                <FaSortAmountDown />
                                            )
                                        ) : null}
                                    </StyledIconSort>
                                </StyledTitleAndSort>
                                <div>
                                    {column.canFilter ? (
                                        <StyledInput
                                            value={(column.searchKey && filters[column.searchKey])
                                                || (column.accessorKey && filters[column.accessorKey])
                                                || ''}
                                            onChange={e => {
                                                if(column.searchKey){
                                                    handleFilterChange(e, column.searchKey || undefined);
                                                } else {
                                                    handleFilterChange(e, column.accessorKey || undefined);
                                                }
                                            }}
                                            placeholder={`Zoek...`}
                                        />
                                    ) : (
                                        <StyledEmptyFilter />
                                    )}
                                </div>

                            </StyledTh>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {paginatedData.map((record, index) => (
                    <TableDefaultBodyRow
                        record={record}
                        columns={columns}
                        key={index}
                        usePolicy={usePolicy}
                        usePolicyType={usePolicyType}
                        handleOnClickRow={handleOnClickRow}
                    />
                ))}
                </tbody>
            </table>
            <StyledPagination>
                <StyledSelect
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Toon {pageSize}
                        </option>
                    ))}
                </StyledSelect>
                {shouldShowPaginator &&
                    <>
                        <ButtonGroup>
                            <ButtonPrimaryNavigation
                                onClick={() => goToFirstPage()}
                                disabled={isFirstPage}
                            >
                                <MdFirstPage />
                            </ButtonPrimaryNavigation>
                            <ButtonPrimaryNavigation
                                onClick={() => goToPreviousPage()}
                                disabled={isFirstPage}
                            >
                                <MdChevronLeft />
                            </ButtonPrimaryNavigation>
                            <ButtonPrimaryNavigation
                                onClick={() => goToNextPage()}
                                disabled={isLastPage}
                            >
                                <MdChevronRight />
                            </ButtonPrimaryNavigation>
                            <ButtonPrimaryNavigation
                                onClick={() => goToLastPage()}
                                disabled={isLastPage}
                            >
                                <MdLastPage />
                            </ButtonPrimaryNavigation>
                        </ButtonGroup>
                        <StyledPageSummary>
                            Pagina{' '}
                            <strong>
                                {pageIndex + 1} van {totalPages}
                            </strong>{' '}
                        </StyledPageSummary>
                    </>
                }
            </StyledPagination>
        </StyledTable>
    );
}

TableDefault.defaultProps = {
    handleOnClickRow: undefined,
    // showFooter: false,
    defaultPageSize: 20,
};

export default TableDefault;

/* --- START STYLING --- */
const StyledTable = styled.div`
    width: 100%;

    table {
        border-spacing: 0;
        width: 100%;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            max-width: 20rem;
            word-break: break-word;

            &:last-child {
                border-right: 0;
            }
        }
    }
`;

const StyledTh = styled.th`
    text-align: left;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    background: rgba(0, 0, 0, 0.08);
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.primary};
    font-weight: 400;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '20rem')};
    vertical-align: bottom;

    ${props =>
        props.width &&
        css`
            width: ${props.width}px;
        `};

    ${props =>
        props.mobileHidden &&
        css`
            @media only screen and (max-width: 1050px) {
                display: none;
            }
        `};
`;

const StyledTitleAndSort = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const StyledIconSort = styled.span`
    cursor: pointer;
    font-size: 1.4rem;

    color: ${props =>
        props.active ? props.theme.colors.primary : props.theme.colors.grey};
    transform: rotateY(180deg);
`;

const StyledInput = styled.input`
    width: 100%;
    max-width: 40rem;
    margin-top: 2px;
    padding: 4px;
    border-radius: 3px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${props => props.theme.colors.grey};
    background: ${props => props.theme.colors.backgroundContainer};
    color: ${props => props.theme.colors.greyDark};
    font-size: 1.3rem;
`;

const StyledPagination = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.8rem 0.4rem;
`;

const StyledSelect = styled.select`
    background: ${props => props.theme.colors.backgroundContainer};
    color: ${props => props.theme.colors.textDefault};
    font-size: 1.2rem;
    padding: 0.2rem 1.8rem 0.2rem 0.4rem;
    margin: 0;
    border: 1px solid ${props => props.theme.colors.grey};
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(
            to bottom,
            ${props => props.theme.colors.backgroundContainer} 0%,
            ${props => props.theme.colors.backgroundContainer} 100%
        );
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    outline: 0;

    &:hover {
        border-color: #888;
    }
`;

const StyledPageSummary = styled.div`
    color: ${props => props.theme.colors.textDefault};
    font-size: 1.4rem;
`;

const ButtonPrimaryNavigation = styled(ButtonPrimary)`
    font-size: 1.8rem;
`;

const StyledFooterTh = styled.th`
    border-top: 1px solid ${props => props.theme.colors.grey};
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

const StyledEmptyFilter = styled.div`
    background: transparent;
    height: 2.9rem;
`;
/* --- END STYLING --- */
