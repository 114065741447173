import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';

const StyledInputBadge = styled.label`
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    background: ${props =>
        props.checked
            ? props.theme.colors.primaryDark
            : props.theme.colors.grey};
    color: ${props => props.theme.colors.white};
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;

    :last-child {
        margin-right: 0;
    }
`;

function InputBadge(props) {
    const [field] = useField(props);

    return (
        <StyledInputBadge
            checked={field.value}
            htmlFor={props.id || field.name}
            data-testid={field.name}
        >
            <input
                type={'checkbox'}
                {...field}
                {...props}
                id={props.id || field.name}
                value={true}
                checked={field.value}
                hidden
            />
            {props.label}
        </StyledInputBadge>
    );
}

export default InputBadge;
