// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';

const StyledTextArea = styled.textarea`
    width: 100%;
    max-width: ${props => props.maxWidth};
    margin-top: 4px;
    padding: 8px;
    border-radius: 3px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${props => props.theme.colors.grey};
    background: ${props => props.theme.colors.backgroundContainer};
    color: ${props => props.theme.colors.textDefault};
    resize: vertical;

    &::placeholder {
        color: ${props => props.theme.colors.grey};
        background: ${props => props.theme.colors.backgroundContainer};
    }

    &:focus {
        outline: none;
    }
`;

type Props = {
    id?: string,
    autoComplete: string,
    rows: number,
};

function TextArea({ id, autoComplete, rows, maxWidth, ...props }: Props) {
    const [field] = useField(props);

    return (
        <StyledTextArea
            {...field}
            {...props}
            id={id || field.name}
            autoComplete={autoComplete}
            rows={rows}
            maxWidth={maxWidth}
        />
    );
}

TextArea.defaultProps = {
    autoComplete: 'off',
    rows: 4,
    maxWidth: '40rem',
};

export default TextArea;
