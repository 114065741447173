import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';

function SearchResult({ searchResult, history, searchTerm }) {
    function highlightSearchedText(value) {
        return reactStringReplace(value, searchTerm, (match, index) => (
            <HighlightText key={index}>{match}</HighlightText>
        ));
    }

    return (
        <OverlayScrollbarsComponent
            style={{ height: '30rem', width: '100%', maxWidth: '90vw' }}
        >
            {searchResult.slice(0, 500).map(function(item, index) {
                switch (item.type) {
                    case 'customers': {
                        const { id, name } = item.searchable;
                        return (
                            <SearchItemWrapper
                                key={index}
                                onClick={() => history.push(`/klant/${id}`)}
                            >
                                <SearchItemTitle>
                                    {highlightSearchedText(name)}
                                </SearchItemTitle>
                                <SearchItemSubtitle>
                                    Zoekterm gevonden in de naam van de klant
                                </SearchItemSubtitle>
                            </SearchItemWrapper>
                        );
                    }
                    case 'customer_items': {
                        const { id, title, customer } = item.searchable;
                        return (
                            <SearchItemWrapper
                                key={index}
                                onClick={() =>
                                    history.push(`/klant/${customer.id}/${id}`)
                                }
                            >
                                <SearchItemTitle>
                                    {highlightSearchedText(title)} |{' '}
                                    <SearchItemTitleAddition>
                                        {customer.name}
                                    </SearchItemTitleAddition>
                                </SearchItemTitle>
                                <SearchItemSubtitle>
                                    Zoekterm gevonden in de titel van een
                                    klantitem
                                </SearchItemSubtitle>
                            </SearchItemWrapper>
                        );
                    }
                    case 'customer_item_fields': {
                        const { customer_item, value } = item.searchable;
                        const { id, title, customer } = customer_item;
                        return (
                            <SearchItemWrapper
                                key={index}
                                onClick={() =>
                                    history.push(`/klant/${customer.id}/${id}`)
                                }
                            >
                                <SearchItemTitle>
                                    {highlightSearchedText(value)} |{' '}
                                    <SearchItemTitleAddition>
                                        {title}
                                    </SearchItemTitleAddition>{' '}
                                    |{' '}
                                    <SearchItemTitleAddition>
                                        {customer.name}
                                    </SearchItemTitleAddition>
                                </SearchItemTitle>
                                <SearchItemSubtitle>
                                    Zoekterm gevonden in de waarde van een
                                    klantitem
                                </SearchItemSubtitle>
                            </SearchItemWrapper>
                        );
                    }
                    default: {
                        return null;
                    }
                }
            })}
            {searchResult.length > 500 ? (
                <TooManyRecordsText>
                    Meer dan 500 records gevonden. Probeer je zoekterm beter te
                    specificeren.
                </TooManyRecordsText>
            ) : null}
        </OverlayScrollbarsComponent>
    );
}

export default withRouter(SearchResult);

/* --- START STYLING --- */
const SearchItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.4rem 0.1rem;
    margin-right: 1.4rem;
    padding: 0.4rem 0.2rem;
    border-radius: 3px;
    border-left: 4px solid transparent;

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    :hover {
        background: rgba(225, 225, 236, 0.8);
        border-left-color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }

    ${props =>
        props.selected &&
        css`
            background: rgba(225, 225, 236, 0.8);
            border-color: ${props.theme.colors.primary};
        `};
`;

const SearchItemTitle = styled.div`
    color: ${props => props.theme.colors.textDefault};
    font-size: 1.4rem;
    line-height: 1.6rem;

    ${props =>
        props.selected &&
        css`
            font-weight: bold;
        `};

    ${props =>
        props.isArchived &&
        css`
            color: ${props.theme.colors.danger};
        `}
`;

const SearchItemTitleAddition = styled.small`
    text-transform: uppercase;
    color: ${props => props.theme.colors.textGrey};
`;

const SearchItemSubtitle = styled.div`
    color: ${props => props.theme.colors.greyDark};
    font-size: 1.3rem;
    line-height: 1.5rem;
`;

const HighlightText = styled.span`
    background: #ecf158;
    color: ${props => props.theme.colors.greyDarkest};
`;

const TooManyRecordsText = styled.div`
    color: ${props => props.theme.colors.danger};
`;
/* --- END STYLING --- */
