import React from 'react';
import { Formik, Form } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import InputTextSet from '../../../components/form/InputTextSet';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import ButtonLinkTertiary from '../../../components/button/ButtonLinkTertiary';
import InputPasswordSet from '../../../components/form/InputPasswordSet';

const StyledLoginFormSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40rem;

    @media only screen and (max-width: 900px) {
        width: calc(100vw - 3rem);
    }
`;

const StyledLoginButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
`;

function LoginForm({ handleSubmit }) {
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('Ongeldig e-mail adres')
                    .required('Verplicht'),
                password: Yup.string().required('Verplicht'),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <StyledLoginFormSection>
                            <InputTextSet
                                label="E-mail"
                                name="email"
                                placeholder="mijn@email.nl"
                            />
                            <InputPasswordSet
                                label="Wachtwoord"
                                name="password"
                                type="password"
                                placeholder="Wachtwoord"
                            />
                            <StyledLoginButtons>
                                <ButtonLinkTertiary
                                    to={'wachtwoord-vergeten'}
                                    data-testid={'wachtwoord-vergeten'}
                                >
                                    Wachtwoord vergeten?
                                </ButtonLinkTertiary>
                                <ButtonPrimary
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting}
                                    type={'submit'}
                                >
                                    Inloggen
                                </ButtonPrimary>
                            </StyledLoginButtons>
                        </StyledLoginFormSection>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default LoginForm;
