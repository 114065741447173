// @flow
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

type Props = {
    position?: string,
    options: Array,
    selectItem: string,
    closeDropdown: () => void,
};

DropdownMenu.defaultProps = {
    position: 'right',
};

function DropdownMenu({ position, options, selectItem, closeDropdown }: Props) {
    const optionListRef = useRef(null);
    useOutsideAlerter(optionListRef);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeDropdown();
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <DropdownLinksContainer
            ref={optionListRef}
            items={options.length}
            position={position}
        >
            <DropdownLinksList>
                {options.map((option, index) => {
                    return (
                        <DropdownItem
                            key={index}
                            onClick={() => selectItem(option.code)}
                            data-testid={`new-item-${option.code}`}
                        >
                            {option.name}
                        </DropdownItem>
                    );
                })}
            </DropdownLinksList>
        </DropdownLinksContainer>
    );
}

export default DropdownMenu;

/* --- START STYLING --- */
const DropdownLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 2px;
    background: transparent;
    z-index: 99;

    ${props =>
        props.position === 'right' &&
        css`
            bottom: 0;
            left: 6.4rem;
        `}

    ${props =>
        props.position === 'top' &&
        css`
            top: -${props.items * 32}px;
            right: 0;
        `}

    ${props =>
        props.position === 'bottom' &&
        css`
            top: 0;
            left: 0;
        `};
`;

const DropdownLinksList = styled.div`
    position: relative;
    background: ${props => props.theme.colors.card.background.default};
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.32);
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    max-height: 90vh;
    width: 24rem;
    overflow-y: auto;
`;

const DropdownItem = styled.span`
    display: inline-block;
    text-decoration: none;
    padding: 0.4rem 0.8rem;
    color: ${props => props.theme.colors.textDefault};
    font-size: 1.3rem;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background: ${props => props.theme.colors.greyLightest};
        color: ${props => props.theme.colors.primary};
    }
`;
/* --- END STYLING --- */
