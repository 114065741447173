import axiosInstance from '../default-setup/AxiosInstance';

export default {
    toggleArchive: function(customerItemFieldId, toggleArchive) {
        let requestUrl = `/customer-item-field/${customerItemFieldId}/${
            toggleArchive ? 'archive' : 'activate'
        }`;

        return axiosInstance.post(requestUrl);
    },
};
