import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import CustomerItemAPI from '../../../../../../api/customer-item/CustomerItemAPI';
import { CustomerItemsContext } from '../../../../../../context/CustomerItemsContext';
import { useNotification } from "../../../../../../context/NotificationContext";
import fileDownload from 'js-file-download';
import CustomerItemAttachmentAPI from '../../../../../../api/customer-item-attachment/CustomerItemAttachmentAPI';
import SectionField from '../view/parts/SectionField';
import { MdDeleteForever } from 'react-icons/all';

function CustomerItemAttachment({ customerItemId }) {
    const { selectedCustomerItem, updateCustomerItem } = useContext(
        CustomerItemsContext
    );
    const showNotification = useNotification();

    const onDropAccepted = useCallback(
        acceptedFiles => {
            // Add files to formData
            let formData = new FormData();

            acceptedFiles.forEach(file => {
                formData.append('attachments[]', file);
            });

            CustomerItemAPI.addAttachments(customerItemId, formData)
                .then(function(payload) {
                    updateCustomerItem(payload.data.data);
                })
                .catch(function(error) {
                    console.log(error);
                    if (error.response && error.response.status === 422) {
                        showNotification(
                            'Dit bestandstype mag niet geüpload worden',
                            'warning'
                        );
                    } else {
                        showNotification(
                            'Er is iets misgegaan met het opslaan van het bestand. Probeer het nogmaals, of neem contact met ons op.'
                        );
                    }
                });
        },
        // eslint-disable-next-line
        [customerItemId]
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        isDragAccept,
    } = useDropzone({
        onDropAccepted,
    });

    function downloadAttachment(attachment) {
        CustomerItemAttachmentAPI.downloadAttachment(attachment.id)
            .then(function(payload) {
                fileDownload(payload.data, attachment.filename);
            })
            .catch(function(error) {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met downloaden van het bestand. Probeer het nogmaals, of neem contact met ons op.'
                );
            });
    }

    function deleteAttachment(attachment) {
        CustomerItemAttachmentAPI.deleteAttachment(attachment.id)
            .then(function() {
                const updatedCustomerItem = {
                    ...selectedCustomerItem,
                    customerItemAttachments: selectedCustomerItem.customerItemAttachments.filter(
                        item => item.id !== attachment.id
                    ),
                };
                updateCustomerItem(updatedCustomerItem);
            })
            .catch(function(error) {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het verwijderen van het bestand. Probeer het nogmaals, of neem contact met ons op.'
                );
            });
    }

    return (
        <AttachmentsSection>
            <SectionField field={{ id: null, value: 'Documenten' }} />
            <AttachmentsWrapper>
                {selectedCustomerItem &&
                    selectedCustomerItem.customerItemAttachments && selectedCustomerItem.customerItemAttachments.map(attachment => (
                        <Attachment key={attachment.id}>
                            <AttachmentLink
                                key={attachment.id}
                                onClick={() => downloadAttachment(attachment)}
                            >
                                {attachment.filename}
                            </AttachmentLink>
                            <AttachmentDelete
                                onClick={() => deleteAttachment(attachment)}
                            />
                        </Attachment>
                    ))}
            </AttachmentsWrapper>
            <StyledDropzone
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
                <input {...getInputProps()} />
                {isDragAccept && <p>Laat los om dit bestand te uploaden </p>}
                {isDragReject && <p>Dit bestand kan niet worden geüpload</p>}
                {!isDragActive && (
                    <p>Sleep bestanden hierheen, of klik om te uploaden</p>
                )}
            </StyledDropzone>
        </AttachmentsSection>
    );
}

export default CustomerItemAttachment;

/* --- START STYLING --- */
const StyledDropzone = styled.div`
    background: ${props => props.theme.colors.background};
    border-radius: 5px;
    border: 2px dashed ${props => props.theme.colors.grey};
    padding: 1rem 2rem;

    @media only screen and (max-width: 900px) {
        padding: 1rem;
    }

    ${props =>
        props.isDragActive &&
        css`
            border-color: ${props.theme.colors.dropzone.active.borderColor};
        `}

    ${props =>
        props.isDragAccept &&
        css`
            border-color: ${props.theme.colors.dropzone.accepted.borderColor};
        `}

    ${props =>
        props.isDragReject &&
        css`
            border-color: ${props.theme.colors.dropzone.rejected.borderColor};
            background: ${props.theme.colors.dropzone.rejected.background};
        `}
`;

const AttachmentsSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 40rem;
    margin-top: 2rem;

    @media only screen and (max-width: 900px) {
        justify-content: flex-start;
        max-width: 100%;
    }
`;

const AttachmentsWrapper = styled.div`
    margin-bottom: 1rem;
`;

const Attachment = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AttachmentLink = styled.div`
    text-decoration: underline;
    cursor: pointer;
    color: ${props => props.theme.colors.link};
`;

const AttachmentDelete = styled(MdDeleteForever)`
    font-size: 2rem;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.colors.danger};
    }
`;
/* --- END STYLING --- */
