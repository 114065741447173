import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { getCategoryIcon } from '../../../../../../helpers/getCategoryIcon';
import moment from 'moment';
import PasswordField from './parts/PasswordField';
import SectionField from './parts/SectionField';
import TextField from './parts/TextField';
import CustomerItemDetailsWrapper from '../ItemWrapper';
import { CustomerItemsContext } from '../../../../../../context/CustomerItemsContext';
import ButtonPrimary from '../../../../../../components/button/ButtonPrimary';
import ShowPreviousPasswords from './ShowPreviousPasswords';
import Attachment from '../attachment';
import LinkField from './parts/LinkField';

function CustomerItemDetailsView({
    toggleShowEdit,
    handleDuplicateItem,
}) {
    const [showPreviousPasswords, setShowPreviousPasswords] = useState(false);
    const { selectedCustomerItem } = useContext( CustomerItemsContext );
    if (selectedCustomerItem) {

        return (
            <CustomerItemDetailsWrapper
                toggleShowEdit={toggleShowEdit}
                handleDuplicateItem={handleDuplicateItem}
                action={'view'}
            >
                <ViewWrapper>
                    <TitleWrapper>
                        <TitleIcon>
                            {getCategoryIcon(
                                selectedCustomerItem.categoryCode,
                                'primary',
                                '4'
                            )}
                        </TitleIcon>
                        <TitleTextWrapper>
                            <TitleText>{selectedCustomerItem.title}</TitleText>
                            <TitleSubText>
                                {selectedCustomerItem.category
                                    ? selectedCustomerItem.category.name
                                    : 'Geen categorie naam'}
                            </TitleSubText>
                        </TitleTextWrapper>
                    </TitleWrapper>

                    <CustomerItemWrapper>
                        {selectedCustomerItem.customerItemFields && selectedCustomerItem.customerItemFields.map(field => {

                            switch (field.typeCode) {
                                case 'password':
                                    return (
                                        <PasswordField
                                            key={field.id}
                                            field={field}
                                        />
                                    );
                                case 'section':
                                    return (
                                        <SectionField
                                            key={field.id}
                                            field={field}
                                        />
                                    );
                                case 'link':
                                    return (
                                        <LinkField
                                            key={field.id}
                                            field={field}
                                        />
                                    );
                                default:
                                    return (
                                        <TextField
                                            key={field.id}
                                            field={field}
                                        />
                                    );
                            }
                        })}
                    </CustomerItemWrapper>

                    {selectedCustomerItem.previousSavedPasswords && selectedCustomerItem.previousSavedPasswords.length ? (
                        <PasswordWrapper>
                            <PasswordButton>
                                <ButtonPrimary
                                    variant={'grey'}
                                    onClick={() =>
                                        setShowPreviousPasswords(
                                            !showPreviousPasswords
                                        )
                                    }
                                >
                                    {`${
                                        showPreviousPasswords
                                            ? 'Verberg'
                                            : 'Toon'
                                    } vorige
                    wachtwoorden`}
                                </ButtonPrimary>
                            </PasswordButton>

                            {showPreviousPasswords ? (
                                <ShowPreviousPasswords
                                    previousSavedPasswords={
                                        selectedCustomerItem.previousSavedPasswords
                                    }
                                />
                            ) : null}
                        </PasswordWrapper>
                    ) : null}

                    <Attachment customerItemId={selectedCustomerItem.id} />

                    <FooterWrapper>
                        <DisplayTextField>
                            <StyledLabel>Gewijzigd op</StyledLabel>
                            <StyledDisplayText>
                                {moment(selectedCustomerItem.updatedAt).format('LLL')}
                            </StyledDisplayText>
                        </DisplayTextField>
                        <DisplayTextField>
                            <StyledLabel>Gemaakt op</StyledLabel>
                            <StyledDisplayText>
                                {moment(selectedCustomerItem.createdAt).format('LLL')}
                            </StyledDisplayText>
                        </DisplayTextField>
                    </FooterWrapper>
                </ViewWrapper>
            </CustomerItemDetailsWrapper>
        );
    } else {
        return (
            <CustomerItemDetailsWrapper>
                Geen items gevonden.
            </CustomerItemDetailsWrapper>
        );
    }
}

export default CustomerItemDetailsView;

/* --- START STYLING --- */
const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    @media only screen and (max-width: 900px) {
        align-items: flex-start;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    min-width: 40rem;
    align-content: flex-start;

    @media only screen and (max-width: 900px) {
        min-width: 100%;
    }
`;

const TitleIcon = styled.div`
    margin: 0 1.6rem;
`;

const TitleTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 900px) {
        justify-content: flex-start;
    }
`;

const TitleText = styled.h3`
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.2rem;
`;

const TitleSubText = styled.small`
    font-size: 1.3rem;
    text-transform: uppercase;
    color: ${props => props.theme.colors.textGrey};
`;

const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledLabel = styled.label`
    flex: 1;
    text-align: end;
    padding: 0.4rem 1.2rem;
    color: ${props => props.theme.colors.textGrey};

    @media only screen and (max-width: 900px) {
        text-align: start;
    }
`;

const StyledDisplayText = styled.div`
    flex: 2;
    padding: 0.4rem 0;
    color: ${props => props.theme.colors.textDefault};
`;

const CustomerItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 80rem;

    @media only screen and (max-width: 900px) {
        justify-content: flex-start;
    }
`;

const PasswordWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
    margin-top: 3rem;
`;

const PasswordButton = styled.div`
    display: flex;
    justify-content: center;
`;

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
    margin-top: 2rem;

    @media only screen and (max-width: 900px) {
        justify-content: flex-start;
    }
`;
/* --- END STYLING --- */
