import React from 'react';
import styled from '@emotion/styled';
import { useNotification } from "../../../../../../../context/NotificationContext";
import ButtonPrimaryTiny from '../../../../../../../components/button/ButtonPrimaryTiny';

function LinkField({ field }) {
    const showNotification = useNotification();
    function copyField() {
        navigator.clipboard.writeText(field.value);
        showNotification('Gegevens gekopieerd!', 'info');
    }

    return (
        <DisplayTextField key={field.id}>
            <StyledLabel>{field.label}</StyledLabel>
            <StyledDisplayText>
                <a href={field.value} target="blank">
                    {field.value}
                </a>
                <ButtonPrimaryTiny
                    className={'action-button'}
                    onClick={copyField}
                >
                    Kopieer
                </ButtonPrimaryTiny>
            </StyledDisplayText>
        </DisplayTextField>
    );
}

export default LinkField;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledLabel = styled.label`
    flex: 1;
    text-align: end;
    padding: 0.4rem 1.2rem;
    color: ${props => props.theme.colors.textGrey};

    @media only screen and (max-width: 900px) {
        text-align: start;
        flex: 2;
    }
`;

const StyledDisplayText = styled.div`
    flex: 2;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
    white-space: pre-wrap;
    border: 2px dotted transparent;

    @media only screen and (max-width: 900px) {
        text-align: start;
        flex: 5;
    }

    .action-button {
        display: none;
    }

    &:hover {
        border-radius: 5px;
        border-color: ${props => props.theme.colors.grey};

        .action-button {
            display: inline;
        }
    }
`;
/* --- END STYLING --- */
