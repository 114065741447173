import React from 'react';
import styled from '@emotion/styled';
import { ClipLoader } from 'react-spinners';

function ButtonTertiary(props) {
    return (
        <StyledButton type={'button'} {...props}>
            {props.children}{' '}
            {props.isLoading ? <ClipLoader color={'white'} size={17} /> : null}
        </StyledButton>
    );
}

export default ButtonTertiary;

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.greyDark};
    border-radius: 3px;
    font-weight: 400;
    letter-spacing: 0.05rem;
    font-size: ${props => (props.fontSize ? props.fontSize : '1.4rem')};
    cursor: pointer;
    height: 28px;
    padding: 6px 0;
    padding-right: 0.8rem;

    &:last-of-type {
        padding-right: 0;
    }

    &:active,
    :hover {
        color: ${props =>
            props.variant ? props.variant : props.theme.colors.greyDarker};
        text-decoration: underline;
    }

    &:active {
        transform: scale(0.99);
    }

    &:focus {
        outline: 0;
    }
`;
