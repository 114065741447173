// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

function TableTopBar({ showWithArchived, handleChangeShowWithArchived }) {
    return (
        <StyledTableTopBar>
            <StyledArchivedButton
                data-testid={'toon'}
                active={showWithArchived === true}
                onClick={() => {
                    handleChangeShowWithArchived(true);
                }}
            >
                Toon
            </StyledArchivedButton>
            &nbsp;/&nbsp;
            <StyledArchivedButton
                data-testid={'verberg'}
                active={showWithArchived === false}
                onClick={() => {
                    handleChangeShowWithArchived(false);
                }}
            >
                verberg
            </StyledArchivedButton>
            &nbsp;gearchiveerd
        </StyledTableTopBar>
    );
}

export default TableTopBar;

/* --- START STYLING --- */
const StyledTableTopBar = styled.div`
    display: flex;
    justify-content: flex-end;
    color: ${props => props.theme.colors.grey};
    font-size: 1.1rem;
    text-transform: uppercase;
`;

const StyledArchivedButton = styled.span`
    text-decoration: none;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;

    ${props =>
        props.active &&
        css`
            color: ${props.theme.colors.primaryDark};
            text-decoration: underline;
        `}

    &:hover {
        color: ${props => props.theme.colors.primaryDarker};
        text-decoration: underline;
    }
`;
/* --- END STYLING --- */
