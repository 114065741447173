import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import Theme from '../components/theme/Theme.js';

const defaultContextData = {
    dark: false,
    toggle: () => {},
};

const ThemeContext = React.createContext(defaultContextData);
const useTheme = () => React.useContext(ThemeContext);

const localstorageThemeDark = '__customer-info-theme-dark__';

const useEffectDarkMode = () => {
    const [themeState, setThemeState] = React.useState({
        dark: false,
        hasThemeMounted: false,
    });
    React.useEffect(() => {
        const lsDark = localStorage.getItem(localstorageThemeDark) === 'true';
        setThemeState({ ...themeState, dark: lsDark, hasThemeMounted: true });
        // eslint-disable-next-line
    }, []);

    return [themeState, setThemeState];
};

const ThemeProvider = ({ children }) => {
    const [themeState, setThemeState] = useEffectDarkMode();

    if (!themeState.hasThemeMounted) {
        return <div />;
    }

    const toggle = () => {
        const dark = !themeState.dark;
        localStorage.setItem(localstorageThemeDark, JSON.stringify(dark));
        setThemeState({ ...themeState, dark });
    };

    const computedTheme = themeState.dark ? Theme('dark') : Theme('light');

    return (
        <EmotionThemeProvider theme={computedTheme}>
            <ThemeContext.Provider
                value={{
                    dark: themeState.dark,
                    toggle,
                }}
            >
                {children}
            </ThemeContext.Provider>
        </EmotionThemeProvider>
    );
};

export { ThemeProvider, useTheme };
