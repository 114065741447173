import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/nl';
import 'react-day-picker/lib/style.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { NotificationProvider } from "./context/NotificationContext";

// ReactDOM.render(<App />, document.getElementById('root'));
// Use the new React 18 createRoot API
if (document.getElementById('root')) {
    const root = createRoot(document.getElementById('root'));
     // document.addEventListener('DOMContentLoaded', () => {
        root.render(
            <NotificationProvider>
                <App />
            </NotificationProvider>
        );
     // });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
