import React, {useContext, useEffect, useRef, useState} from 'react';
import { CustomerItemsContext } from '../../../../../context/CustomerItemsContext';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { getCategoryIcon } from '../../../../../helpers/getCategoryIcon';
import { truncate } from 'lodash';
import { DefaultWrapper } from '../../base-style';
import AddItemDropdown from './AddItemDropdown';
import SortItemsDropdown from './SortItemsDropdown';
import useCustomerItemPolicy from '../../../../../policies/useCustomerItemPolicy';

function CustomerItemList({ handleSelectItem, handleNewItem }) {
    const {
        customerId,
        filteredCustomerItems,
        selectedCustomerItemId,
        handleChangeSearchFilter,
        handleChangeShowWithArchived,
        searchFilter,
        showWithArchived,
    } = useContext(CustomerItemsContext);
    const scrollRef = useRef(null);
    const hasCreatePermission = useCustomerItemPolicy('create');

    function handleOnChangeSearch(event) {
        const value = event.target.value;

        handleChangeSearchFilter(value, customerId);
    }

    function handleScroll(direction) {
        const osInstance = scrollRef.current._osInstance;

        if (direction === 'bottom') osInstance.scroll({ top: '100%' });
        if (direction === 'top') osInstance.scroll({ top: '0%' });
    }

    return (
        <SidebarWrapper>
            <SearchWrapper>
                <SearchHeaderWrapper>
                    <SortWrapper>
                        <SortLabel>Sorteren op:</SortLabel>
                        <SortSelect>
                            <SortItemsDropdown />
                        </SortSelect>
                    </SortWrapper>
                    <CountItemsWrapper>
                        <CountItemLabel>Items:</CountItemLabel>
                        <CountItem>{filteredCustomerItems.length}</CountItem>
                    </CountItemsWrapper>
                </SearchHeaderWrapper>
                <StyledArchived>
                    <StyledArchivedButton
                        data-testid={'toon'}
                        active={showWithArchived === true}
                        onClick={() => {
                            handleChangeShowWithArchived(true);
                        }}
                    >
                        Toon
                    </StyledArchivedButton>
                    &nbsp;/&nbsp;
                    <StyledArchivedButton
                        data-testid={'verberg'}
                        active={showWithArchived === false}
                        onClick={() => {
                            handleChangeShowWithArchived(false);
                        }}
                    >
                        verberg
                    </StyledArchivedButton>
                    &nbsp;gearchiveerd
                </StyledArchived>
                <StyledInput
                    onChange={handleOnChangeSearch}
                    value={searchFilter}
                    placeholder={'Zoeken ...'}
                />
            </SearchWrapper>
            <OverlayScrollbarsComponent
                style={{ height: '100%' }}
                ref={scrollRef}
            >
                {filteredCustomerItems.length
                    ? filteredCustomerItems.map(customerItem => {
                        return (
                              <CustomerItemWrapper
                                  key={customerItem.id}
                                  selected={selectedCustomerItemId == customerItem.id}
                                  onClick={() =>
                                      handleSelectItem(customerItem.id)
                                  }
                              >
                                  {getCategoryIcon(customerItem.categoryCode)}
                                  <CustomerTitleWrapper>
                                      <CustomerItemTitle
                                          selected={
                                              selectedCustomerItemId == customerItem.id
                                          }
                                          isArchived={customerItem.isArchived}
                                      >
                                          {customerItem.title}
                                      </CustomerItemTitle>
                                      <CustomerItemSubtitle>
                                          {truncate(customerItem.subTitle,  { length: 36 })}
                                      </CustomerItemSubtitle>
                                  </CustomerTitleWrapper>
                              </CustomerItemWrapper>
                          );
                      })
                    : 'Geen klantitems'}
            </OverlayScrollbarsComponent>
            <FooterWrapper>
                {hasCreatePermission ? (
                    <AddItemDropdown
                        handleNewItem={handleNewItem}
                        customerId={customerId}
                        handleScroll={handleScroll}
                    />
                ) : null}
            </FooterWrapper>
        </SidebarWrapper>
    );
}

export default CustomerItemList;

/* --- START STYLING --- */
const SidebarWrapper = styled(DefaultWrapper)`
    grid-area: sidebar-list;
    padding: 1rem 0.6rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const CustomerItemWrapper = styled.div`
    display: flex;
    margin: 0.4rem 0.1rem;
    margin-right: 1.4rem;
    padding: 0.4rem 0.2rem;
    border-radius: 3px;
    border-left: 4px solid transparent;

    :hover {
        background: rgba(225, 225, 236, 0.8);
        border-color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }

    ${props =>
        props.selected &&
        css`
            background: rgba(225, 225, 236, 0.8);
            border-color: ${props.theme.colors.primary};
        `};
`;

const CustomerTitleWrapper = styled.div`
    flex: 6;
`;

const CustomerItemTitle = styled.div`
    color: ${props => props.theme.colors.textDefault};
    font-size: 1.3rem;
    line-height: 1.5rem;

    ${props =>
        props.selected &&
        css`
            font-weight: bold;
        `};

    ${props =>
        props.isArchived &&
        css`
            color: ${props.theme.colors.danger};
        `}
`;

const CustomerItemSubtitle = styled.div`
    color: ${props => props.theme.colors.textGrey};
    font-size: 1.1rem;
    line-height: 1.3rem;
`;

const SearchWrapper = styled.div`
    padding: 1rem 0.4rem;
    padding-top: 0.2rem;
`;

const StyledInput = styled.input`
    width: 100%;
    max-width: 40rem;
    margin-top: 4px;
    padding: 4px;
    border-radius: 3px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${props => props.theme.colors.grey};
    background: ${props => props.theme.colors.backgroundContainer};
    color: ${props => props.theme.colors.textDefault};

    &::placeholder {
        color: ${props => props.theme.colors.grey};
        background: ${props => props.theme.colors.backgroundContainer};
    }

    &:focus {
        outline: none;
    }
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;

    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const StyledArchived = styled.div`
    display: flex;
    justify-content: flex-start;
    color: ${props => props.theme.colors.textGrey};
    font-size: 1rem;
    text-transform: uppercase;

    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const StyledArchivedButton = styled.span`
    text-decoration: none;
    color: ${props => props.theme.colors.textGrey};
    cursor: pointer;

    ${props =>
        props.active &&
        css`
            color: ${props.theme.colors.primaryDark};
            text-decoration: underline;
        `}

    &:hover {
        color: ${props => props.theme.colors.primaryDarker};
        text-decoration: underline;
    }
`;

const SearchHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CountItemsWrapper = styled.div`
    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const CountItem = styled.div`
    text-align: end;
    font-size: 1.3rem;
`;
const CountItemLabel = styled.div`
    text-align: end;
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1.2rem;
    color: ${props => props.theme.colors.textGrey};
`;

const SortWrapper = styled.div`
    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const SortLabel = styled.div`
    color: ${props => props.theme.colors.textGrey};
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1.2rem;
`;
const SortSelect = styled.div``;
/* --- END STYLING --- */
