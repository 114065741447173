import React, { useContext, useEffect, useState } from 'react';
import CustomerItemDetailsView from './view';
import CustomerItemDetailsEdit from './edit';
import { CustomerItemsContext } from '../../../../../context/CustomerItemsContext';
import CustomerItemDetailsNew from "./new/index";

function CustomerItemDetails({ handleSetSelectEditItem, toggleShowNew, showNew, toggleShowDuplicate, showDuplicate, newCustomerItem, handleDuplicateItem, handleSetSelectNewItem}) {
    const [showEdit, setShowEdit] = useState(false);
    const { selectedCustomerItem } = useContext(CustomerItemsContext);

    useEffect(
        function() {
            // todo WM: check wat hier nut van is??
            // If title is empty then always go to edit modus
            if (selectedCustomerItem && selectedCustomerItem.title === '') {
                setShowEdit(true);
            } else {
                setShowEdit(false);
            }
        },
        [selectedCustomerItem]
    );

    function toggleShowEdit() {
        setShowEdit(!showEdit);
    }

    if (!selectedCustomerItem) return null;

    if (showNew || showDuplicate) {
        return (
            <CustomerItemDetailsNew showDuplicate={showDuplicate} newCustomerItem={newCustomerItem} toggleShowNew={toggleShowNew} toggleShowDuplicate={toggleShowDuplicate}
                                    handleSetSelectNewItem={handleSetSelectNewItem}
            />
        );
    } else if (showEdit) {
        return (
            <CustomerItemDetailsEdit
                toggleShowEdit={toggleShowEdit}
                handleSetSelectEditItem={handleSetSelectEditItem}
            />
        );
    } else {
        return (
            <CustomerItemDetailsView
                toggleShowEdit={toggleShowEdit}
                handleDuplicateItem={handleDuplicateItem}
            />
        );
    }
}

export default CustomerItemDetails;

/* --- START STYLING --- */

/* --- END STYLING --- */
