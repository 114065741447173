import React from 'react';
import styled from '@emotion/styled';

const StyledSidenavListItem = styled.li`
    display: flex;
    justify-content: left;
    align-content: baseline;
    padding: 10px 10px 10px 30px;
    color: ${props => props.theme.colors.sidenav.color};
    text-transform: uppercase;
    font-size: 1.2rem;
`;

function SidenavListTextDivider({ children }) {
    return <StyledSidenavListItem>{children}</StyledSidenavListItem>;
}

export default SidenavListTextDivider;
