import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    text-decoration: none;

    &:hover,
    :active {
        text-decoration: none;
    }
`;

const StyledSidenavListItem = styled.li`
    display: flex;
    justify-content: left;
    align-content: baseline;
    padding-left: 30px;
    color: ${props => props.theme.colors.sidenav.color};

    &:hover {
        background: ${props => props.theme.colors.sidenav.menuItemHover};
        color: ${props => props.theme.colors.sidenav.color};
        cursor: pointer;
    }
`;

const StyledIcon = styled.div`
    flex: 1;
    font-size: 2rem;
`;

const StyledName = styled.div`
    flex: 3;
    text-align: left;
`;

function SidenavListItem({ icon, children, value, to, closeMenu }) {
    return (
        <StyledLink to={to} onClick={closeMenu}>
            <StyledSidenavListItem>
                <StyledIcon>{icon}</StyledIcon>
                <StyledName>{children}</StyledName>
            </StyledSidenavListItem>
        </StyledLink>
    );
}

export default SidenavListItem;
