// @flow
import React from 'react';
import styled from '@emotion/styled';

type Props = {
    children?: React.Node,
};

function Card({ children }: Props) {
    return <StyledCard>{children}</StyledCard>;
}

export default Card;

const StyledCard = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    /* Medium-sized screen breakpoint (tablet, 1050px) */
    @media only screen and (min-width: 1050px) {
        grid-template-columns: minmax(12rem, 24rem) 1fr;
    }
`;
