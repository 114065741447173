import React from 'react';
import styled from '@emotion/styled';
import BackgroundImage from '../../../images/background-custinfo.jpg';

const StyledContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    background: url(${BackgroundImage}) no-repeat;
    background-size: cover;

    @media only screen and (max-width: 900px) {
        align-items: start;
        background: ${props => props.theme.colors.white};
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '60rem')};
    min-height: 50rem;
    padding: 2rem;
    margin: 1rem;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.6);
    color: ${props => props.theme.colors.greyDarkest};

    @media only screen and (max-width: 900px) {
        box-shadow: none;
    }
`;

function CommonContainer({ children, maxWidth }) {
    return (
        <StyledContainer>
            <Wrapper maxWidth={maxWidth}>{children}</Wrapper>
        </StyledContainer>
    );
}

export default CommonContainer;
