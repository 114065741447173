import React, { useState } from 'react';
import ForgotForm from './Form';
import AuthAPI from '../../../api/auth/AuthAPI';
import LogoXarisImage from '../../../images/logo-xaris.png';
import { H3, P } from '../../../components/typography';
import CommonContainer from '../../../components/layout/common-container';
import styled from '@emotion/styled';

const StyledLogoImage = styled.img`
    position: relative;
    padding: 0 1rem;
    max-width: 28rem;
`;

const StyledLoginSection = styled.section`
    margin: 3rem auto;
    max-width: 50rem;
`;

function Forgot(props) {
    const [error, setError] = useState('');
    const [showSuccessMessage, toggleSuccessMessage] = useState(false);

    function handleSubmit(values, submittingFinished) {
        AuthAPI.forgot(values)
            .then(payload => {
                setError('');
                toggleSuccessMessage(true);
                submittingFinished();
            })
            .catch(error => {
                // If login fails show error and then set submitting back to false
                setError('Er is iets misgegaan met de netwerkverbinding');
                submittingFinished();
            });
    }

    return (
        <CommonContainer>
            <StyledLogoImage src={LogoXarisImage} />
            <StyledLoginSection>
                <H3>Wachtwoord vergeten?</H3>
                <P>
                    Vul het e-mailadres in waarmee je inlogt. Je ontvangt van
                    ons een e-mail, waarmee je een nieuw wachtwoord kunt
                    instellen.
                </P>
                <ForgotForm
                    handleSubmit={handleSubmit}
                    showSuccessMessage={showSuccessMessage}
                />
                <div>{error}</div>
            </StyledLoginSection>
        </CommonContainer>
    );
}

export default Forgot;
