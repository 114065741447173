import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { IoMdAddCircle, IoMdCloseCircle } from 'react-icons/all';
import fieldTypes from '../../../data/fieldTypes';
import DropdownMenu from '../../dropdown-menu';

function AddFieldDropdown({ arrayHelpers, emptyValueObject }) {
    const [showOptions, setShowOptions] = useState(false);
    const optionListRef = useRef(null);
    useOutsideAlerter(optionListRef);

    function addItem(choosedType) {
        arrayHelpers.push({
            ...emptyValueObject,
            label: choosedType === 'section' ? 'Sectie' : '',
            typeCode: choosedType,
        });
        setShowOptions(false);
    }

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowOptions(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <Wrapper>
            {showOptions ? (
                <>
                    <StyledIoMdCloseCircle />
                    <DropdownMenu
                        options={fieldTypes}
                        selectItem={addItem}
                        closeDropdown={() => setShowOptions(false)}
                        position={'top'}
                    />
                </>
            ) : (
                <StyledIoMdAddCircle onClick={() => setShowOptions(true)} />
            )}
        </Wrapper>
    );
}

export default AddFieldDropdown;

/* --- START STYLING --- */
const StyledIoMdAddCircle = styled(IoMdAddCircle)`
    color: ${props => props.theme.colors.primaryDark};
    font-size: 2.4rem;
    cursor: pointer;
`;

const StyledIoMdCloseCircle = styled(IoMdCloseCircle)`
    color: ${props => props.theme.colors.primaryDark};
    font-size: 2.4rem;
    cursor: pointer;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 1rem;
`;
/* --- END STYLING --- */
