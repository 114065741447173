import styled from '@emotion/styled';

export const DefaultWrapper = styled.div`
    padding: 1rem 2rem;
    background: ${props => props.theme.colors.backgroundContainer};
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);

    @media only screen and (max-width: 900px) {
        padding: 1rem;
    }
`;
