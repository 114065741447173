import React from 'react';
import { IoMdPerson } from 'react-icons/io';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

function HeaderAccountDropdown({ openChangePasswordModal }) {
    return (
        <Links>
            <div href="#" data-testid={'dropdown'}>
                <IoMdPerson size={22} />
            </div>
            <DropdownLinksContainer className="dropdownLinks-container">
                <DropdownLinksLinks>
                    <DropdownItem
                        onClick={openChangePasswordModal}
                        data-testid={'wijzig-wachtwoord'}
                    >
                        Wijzig wachtwoord
                    </DropdownItem>
                    <DropdownItemLink to={'/loguit'} data-testid={'logout'}>
                        Log uit
                    </DropdownItemLink>
                </DropdownLinksLinks>
            </DropdownLinksContainer>
        </Links>
    );
}

export default HeaderAccountDropdown;

/* --- START STYLING --- */
const Links = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 1rem;

    &:hover .dropdownLinks-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 24px;
        right: 0px;
        padding: 2px 10px;
        margin-right: -8px;
        background: transparent;
    }
`;

const DropdownLinksContainer = styled.div`
    display: none;
    min-width: 200px;
`;

const DropdownLinksLinks = styled.div`
    position: relative;
    background: ${props => props.theme.colors.card.background.default};
    border: 1px solid ${props => props.theme.colors.grey};
    display: flex;
    flex-direction: column;
    border-radius: 3px;

    &:after,
    &:before {
        bottom: 100%;
        right: 5px;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 5px;
        right: 6px;
        background-clip: padding-box;
    }

    &:before {
        border-color: rgba(184, 184, 184, 0);
        border-bottom-color: #b8b8b8;
        border-width: 6px;
        background-clip: padding-box;
    }
`;

const DropdownItemLink = styled(Link)`
    text-decoration: none;
    padding: 15px 30px;
    color: ${props => props.theme.colors.textDefault};

    &:hover {
        background: ${props => props.theme.colors.greyLightest};
        color: ${props => props.theme.colors.primary};
    }

    &:first-of-type {
        padding-top: 15px;
    }

    &:last-of-type {
        padding-bottom: 15px;
    }
`;

const DropdownItem = styled.span`
    text-decoration: none;
    padding: 15px 30px;
    color: ${props => props.theme.colors.textDefault};
    cursor: pointer;

    &:hover {
        background: ${props => props.theme.colors.greyLightest};
        color: ${props => props.theme.colors.primary};
    }

    &:first-of-type {
        padding-top: 15px;
    }

    &:last-of-type {
        padding-bottom: 15px;
    }
`;
/* --- END STYLING --- */
