import axiosInstance from '../default-setup/AxiosInstance';

export default {
    downloadAttachment: function(attachmentId) {
        let requestUrl = `/customer-item-attachment/${attachmentId}/download`;

        return axiosInstance.get(requestUrl, { responseType: 'blob' });
    },

    deleteAttachment: function(attachmentId) {
        let requestUrl = `/customer-item-attachment/${attachmentId}/delete`;

        return axiosInstance.get(requestUrl);
    },
};
