// NotificationContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import Notification from '../helpers/Notification';

// Create a Context for the Notification system
const NotificationContext = createContext();

// Notification Provider component
export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    // Function to remove a notification
    const removeNotification = useCallback((id) => {
        setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, []);

    // Function to show a new notification
    const showNotification = useCallback((message, type = 'error', duration = 3600) => {
        const id = Date.now(); // Unique ID for each notification
        const newNotification = { id, message, type };

        setNotifications((prev) => [...prev, newNotification]);

        // Auto-remove the notification after the duration
        setTimeout(() => {
            removeNotification(id);
        }, duration);
    }, [removeNotification]);

    return (
        <NotificationContext.Provider value={showNotification}>
            {children}
            {/* Render Notifications */}
            <div className="notification-container">
                {notifications.map(({ id, message, type }) => (
                    <Notification
                        key={id}
                        message={message}
                        type={type}
                        onClose={() => removeNotification(id)}
                    />
                ))}
            </div>
        </NotificationContext.Provider>
    );
};

// Custom hook to access the notification function
export const useNotification = () => useContext(NotificationContext);
