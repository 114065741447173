import axios from 'axios';
import moment from 'moment';

const BASE_URL = process.env.REACT_APP_API_URL;
const LOCAL_STORAGE_TOKEN = '__customer-info-token__';
const LOCAL_STORAGE_LAST_ACTIVITY = '__customer-info-last-activity__';

const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
});

axiosInstance.interceptors.request.use(
    function(config) {
        // First check if token is expired or not
        checkTokenExpiration();
        // Do something before request is sent
        const AUTH_TOKEN =
            'Bearer ' + localStorage.getItem(LOCAL_STORAGE_TOKEN);
        config.headers.Authorization = AUTH_TOKEN;
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Check token expiration and redirect if token is expired
function checkTokenExpiration() {
    const lastActivity = moment(
        localStorage.getItem(LOCAL_STORAGE_LAST_ACTIVITY)
    );

    if (
        !localStorage.getItem(LOCAL_STORAGE_LAST_ACTIVITY) ||
        lastActivity.add('4', 'hours').format() < moment().format()
    ) {
        if (
            window.location.hash !== '/login' &&
            window.location.hash !== '/loguit'
        ) {
            setTimeout(() => {
                localStorage.removeItem(LOCAL_STORAGE_TOKEN);
                localStorage.removeItem(LOCAL_STORAGE_LAST_ACTIVITY);
                window.location.reload();
            }, 100);
        }
    } else {
        localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, moment().format());
    }
}

export default axiosInstance;
