// @flow
import React from 'react';
import styled from '@emotion/styled';
import InputBadge from './parts/InputBadge';
import ErrorMessage from './parts/ErrorMessage';

const StyledInputBadgeSet = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
    max-width: 30rem;
`;

type Option = {
    code: string,
    name: string,
};

type Props = {
    options: Array<Option>,
    idPrefix?: string,
};

function InputBadgeSet(props: Props) {
    return (
        <StyledInputBadgeSet>
            {props.options.map(item => (
                <InputBadge
                    label={item.name}
                    name={item.code}
                    id={(props.idPrefix ? props.idPrefix : '') + item.code}
                    key={item.code}
                />
            ))}
            {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
        </StyledInputBadgeSet>
    );
}

export default InputBadgeSet;
