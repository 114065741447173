import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { DefaultWrapper } from '../../base-style';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ButtonPrimary from '../../../../../components/button/ButtonPrimary';
import ButtonGroup from '../../../../../components/button/ButtonGroup';
import ButtonTertiary from '../../../../../components/button/ButtonTertiary';
import ButtonSecondary from '../../../../../components/button/ButtonSecondary';
import useCustomerItemPolicy from '../../../../../policies/useCustomerItemPolicy';
import { useNotification } from "../../../../../context/NotificationContext";
import CustomerItemAPI from '../../../../../api/customer-item/CustomerItemAPI';
import { CustomerItemsContext } from '../../../../../context/CustomerItemsContext';
import { IoIosArchive, IoIosSave, IoMdCopy, MdEdit } from 'react-icons/all';

function CustomerItemDetailsWrapper({
    children,
    showDuplicate,
    toggleShowEdit,
    toggleShowNew,
    toggleShowDuplicate,
    handleSubmit,
    isSubmitting,
    handleDuplicateItem,
    resetForm,
    action,
}) {
    const hasArchivePermission = useCustomerItemPolicy('archive');
    const hasUpdatePermission = useCustomerItemPolicy('update');
    const [isProcessingArchiving, setProcessingArchiving] = useState(false);
    const {
        selectedCustomerItem,
        selectedCustomerItemId,
        updateCustomerItem,
        removeCustomerItem,
    } = useContext(CustomerItemsContext);
    const showNotification = useNotification();

    function toggleArchive() {
        setProcessingArchiving(true);
        CustomerItemAPI.toggleArchive(selectedCustomerItem.id, !selectedCustomerItem.isArchived)
            .then(() => {
                updateCustomerItem({
                    ...selectedCustomerItem,
                    isArchived: !selectedCustomerItem.isArchived,
                });

                setProcessingArchiving(false);
            })
            .catch(() => {
                showNotification(
                    'Er is iets misgegaan met het archiveren van dit item. Herlaad de pagina.'
                );

                setProcessingArchiving(false);
            });
    }

    function duplicateItem() {
        handleDuplicateItem(selectedCustomerItem);
    }

    return (
        <ContainerWrapper>
            <OverlayScrollbarsComponent style={{ height: '100%' }}>
                {children}
            </OverlayScrollbarsComponent>
            <FooterWrapper>
                {action === 'new' ? (
                    <ButtonGroup>
                        <ButtonTertiary
                            onClick={() => {
                                resetForm();
                                showDuplicate ? toggleShowDuplicate() : toggleShowNew();

                                if (selectedCustomerItemId !== null && selectedCustomerItemId.toString().includes('new')) {
                                    removeCustomerItem(selectedCustomerItemId);
                                }
                            }}
                        >
                            Annuleren
                        </ButtonTertiary>
                        <ButtonPrimary
                            type={'submit'}
                            onClick={handleSubmit}
                            isLoading={isSubmitting}
                            icon={<IoIosSave />}
                        >
                            Opslaan
                        </ButtonPrimary>
                    </ButtonGroup>
                ) : action === 'edit' ? (
                    <ButtonGroup>
                        <ButtonTertiary
                            onClick={() => {
                                resetForm();
                                toggleShowEdit();
                            }}
                        >
                            Annuleren
                        </ButtonTertiary>
                        <ButtonPrimary
                            type={'submit'}
                            onClick={handleSubmit}
                            isLoading={isSubmitting}
                            icon={<IoIosSave />}
                        >
                            Opslaan
                        </ButtonPrimary>
                    </ButtonGroup>
                ) : action === 'view' ? (
                    <>
                        {hasArchivePermission ? (
                            <ButtonSecondary
                                variant={
                                    selectedCustomerItem.isArchived
                                        ? 'primary'
                                        : 'danger'
                                }
                                onClick={toggleArchive}
                                isLoading={isProcessingArchiving}
                                icon={<IoIosArchive />}
                            >
                                {selectedCustomerItem.isArchived
                                    ? 'Activeren'
                                    : 'Archiveren'}
                            </ButtonSecondary>
                        ) : null}
                        {hasUpdatePermission ? (
                            <ButtonSecondary
                                onClick={duplicateItem}
                                icon={<IoMdCopy />}
                            >
                                Dupliceren
                            </ButtonSecondary>
                        ) : null}
                        {hasUpdatePermission ? (
                            <ButtonPrimary
                                onClick={toggleShowEdit}
                                icon={<MdEdit />}
                            >
                                Wijzigen
                            </ButtonPrimary>
                        ) : null}
                    </>
                ) : null}
            </FooterWrapper>
        </ContainerWrapper>
    );
}

export default CustomerItemDetailsWrapper;

/* --- START STYLING --- */
const ContainerWrapper = styled(DefaultWrapper)`
    grid-area: details;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 900px) {
        display: none;
    }
`;
/* --- END STYLING --- */
