// @flow
import * as React from 'react';
import styled from '@emotion/styled';

const StyledErrorMessage = styled.div`
    margin-top: 0.9rem;
    margin-left: 0.8rem;
    font-size: ${props => (props.fontSize ? props.fontSize : '1.2rem')};
    color: ${props => props.theme.colors.danger};
`;

type Props = {
    children?: React.Node,
    fontSize?: string,
};

function ErrorMessage({ children, fontSize, testId }: Props) {
    return (
        <StyledErrorMessage fontSize={fontSize} data-testid={`error-${testId}`}>
            {children}
        </StyledErrorMessage>
    );
}

export default ErrorMessage;
