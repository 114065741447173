import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { MdLock, MdArrowForward } from 'react-icons/all';

function TableDefaultBodyRow({
    record,
    columns,
    handleOnClickRow,
    usePolicy,
    usePolicyType,
}) {
    const hasPermission = usePolicy(usePolicyType);

    return (
        <StyledTr
            onClick={
                hasPermission ? () => handleOnClickRow(record) : null
            }
            isArchived={record.isArchived}
            hasPermission={hasPermission}
        >
            <StyledTd>
                <StyledIcon>
                    {hasPermission ? (
                        <MdArrowForward title={'Bewerken'} />
                    ) : (
                        <MdLock title={'Geen rechten om te bewerken'} />
                    )}
                </StyledIcon>
            </StyledTd>
            {columns.map((column, index) => {
                return (
                    <StyledTd
                        key={index}
                        mobileHidden={column.mobileHidden}
                    >
                        {column.customCell ? column.customCell(record) : record[column.accessorKey]}
                    </StyledTd>
                );
            })}
        </StyledTr>
    );
}

TableDefaultBodyRow.defaultProps = {
    usePolicy: () => {
        return true;
    },
    usePolicyType: null,
};

export default TableDefaultBodyRow;

const StyledTr = styled.tr`
    &:hover {
        background: rgba(0, 0, 0, 0.08);

        ${props =>
            props.hasPermission &&
            css`
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
                cursor: pointer;
            `};
    }

    ${props =>
        props.isArchived &&
        css`
            color: ${props.theme.colors.danger};
        `};
`;

const StyledTd = styled.td`
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};

    ${props =>
        props.mobileHidden &&
        css`
            @media only screen and (max-width: 1050px) {
                display: none;
            }
        `};
`;

/* --- START STYLING --- */
const StyledIcon = styled.span`
    color: ${props => props.theme.colors.grey};
    font-size: 1.4rem;

    :hover,
    :active {
        color: ${props => props.theme.colors.greyDark};
    }
`;
/* --- END STYLING --- */
