//#4abfbd
// greyText: hsl(201, 23%, 34%); //#435D6B
// greyTextLight: hsl(203, 15%, 47%); //#667C8A
const defaultColors = {
    primary: '#358fd1',
    primaryDark: '#3084bd',
    primaryDarker: '#2d79af',
    primaryLight: '#35a3e7',
    greyDarkest: '#2a2b3c',
    greyDarker: '#313347',
    greyDark: '#71808f',
    grey: '#c4c5cd',
    greyLight: '#d8d9e1',
    greyLighter: '#e2e3eb',
    greyLightest: '#f3f4fc',
    white: '#fff',
    textWhite: '#fff',
    danger: '#cc3c3c',
    dangerDark: '#a53a3a',
    success: '#2E8B57',
    successDark: '#267349',
    info: '#4986E7',
    infoDark: '#4778d7',
    link: '#4778d7',
};

const buttonVariants = {
    button: {
        primary: {
            background: defaultColors.primaryDark,
            backgroundDark: defaultColors.primaryDarker,
            border: defaultColors.primaryDark,
            color: defaultColors.white,
        },
        success: {
            background: defaultColors.success,
            backgroundDark: defaultColors.successDark,
            border: defaultColors.successDark,
            color: defaultColors.white,
        },
        info: {
            background: defaultColors.info,
            backgroundDark: defaultColors.infoDark,
            border: defaultColors.infoDark,
            color: defaultColors.white,
        },
        danger: {
            background: defaultColors.danger,
            backgroundDark: defaultColors.dangerDark,
            border: defaultColors.dangerDark,
            color: defaultColors.white,
        },
        grey: {
            background: defaultColors.greyLight,
            backgroundDark: defaultColors.grey,
            border: defaultColors.greyDarker,
            color: defaultColors.greyDarker,
        },
        transparent: {
            background: 'rgba(0,0,0,.16)',
            backgroundDark: 'rgba(0,0,0,.2)',
            color: defaultColors.white,
        },
    },
};

const modalVariants = {
    primary: {
        backgroundHeader: defaultColors.primaryDark,
    },
    danger: {
        backgroundHeader: defaultColors.danger,
    },
};

const badgeVariants = {
    purple: {
        background: '#451A8D',
    },
    green: {
        background: '#2E8B57',
    },
    orange: {
        background: '#EB670F',
    },
    blue: {
        background: '#4986E7',
    },
    red: {
        background: '#cc3c3c',
    },
    grey: {
        background: defaultColors.grey,
    },
    primary: {
        background: defaultColors.primaryDark,
    },
};

const dropzoneVariants = {
    accepted: {
        borderColor: defaultColors.green,
    },
    active: {
        borderColor: defaultColors.primaryDark,
    },
    rejected: {
        borderColor: defaultColors.danger,
        background: 'rgba(204,60,60,0.4)',
    },
};

const themeLight = {
    colors: {
        ...defaultColors,
        ...buttonVariants,
        textDefault: defaultColors.greyDarkest,
        textGrey: defaultColors.greyDark,
        header: {
            background: '#3a3c48',
            textColor: defaultColors.white,
        },
        hamburgerMenu: {
            background: '#5a5555',
            barsColor: defaultColors.white,
            barsColorSidenav: defaultColors.white,
        },
        sidenav: {
            background: '#252635',
            backgroundHighlight: '#5a5555',
            menuItem: '#ddd',
            menuItemHover: '#246fa7',
            menuItemActive: '#246fa7',
            color: defaultColors.white,
            nameColor: '#e7e7e7',
        },
        card: {
            background: {
                default: defaultColors.white,
                primary: defaultColors.primary,
            },
        },
        form: {
            background: '#efefef',
            border: defaultColors.greyLight,
        },
        modal: {
            background: defaultColors.white,
            ...modalVariants,
        },
        background: defaultColors.greyLightest,
        backgroundContainer: defaultColors.white,
        badge: badgeVariants,
        dropzone: dropzoneVariants,
    },
};

const themeDark = {
    colors: {
        ...defaultColors,
        ...buttonVariants,
        textDefault: defaultColors.white,
        textLightGray: '#a3a3a3',
        textGrey: defaultColors.greyDark,
        header: {
            background: '#313347',
            textColor: defaultColors.white,
        },
        hamburgerMenu: {
            background: '#5a5555',
            barsColor: defaultColors.white,
            barsColorSidenav: defaultColors.white,
        },
        sidenav: {
            background: '#252635',
            backgroundHighlight: '#5a5555',
            menuItem: '#ddd',
            menuItemHover: '#246fa7',
            menuItemActive: '#246fa7',
            color: defaultColors.white,
            brandcolor: '#e7e7e7',
        },
        card: {
            background: {
                default: defaultColors.greyDarker,
                primary: defaultColors.primary,
            },
        },
        form: {
            background: defaultColors.greyDarkest,
            border: defaultColors.greyDarker,
        },
        modal: {
            background: defaultColors.greyDarker,
            ...modalVariants,
        },
        background: defaultColors.greyDarkest,
        backgroundContainer: defaultColors.greyDarker,
        badge: badgeVariants,
        dropzone: dropzoneVariants,
    },
};

const theme = mode => (mode === 'dark' ? themeDark : themeLight);

export default theme;
