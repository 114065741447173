// @flow
import React from 'react';
import ButtonPrimary from '../button/ButtonPrimary';
import ButtonTertiary from '../button/ButtonTertiary';
import StyledModalFooter from './style/Footer';

type Props = {
    closeModalText: string,
    closeModal: Function,
    confirmAction: Function,
    confirmText: string,
    variant: string,
};

const ModalFooter = ({
    closeModal,
    closeModalText,
    confirmAction,
    confirmText,
    variant,
    isLoading,
}: Props) => {
    return (
        <StyledModalFooter>
            <ButtonTertiary onClick={closeModal}>
                {closeModalText}
            </ButtonTertiary>
            {confirmAction ? (
                <ButtonPrimary
                    onClick={confirmAction}
                    variant={variant}
                    isLoading={isLoading}
                    type={'submit'}
                    data-testid={confirmText}
                >
                    {confirmText}
                </ButtonPrimary>
            ) : null}
        </StyledModalFooter>
    );
};

ModalFooter.defaultProps = {
    closeModalText: 'Annuleren',
    confirmText: 'Opslaan',
    isLoading: false,
};

export default ModalFooter;
