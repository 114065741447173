import React, { useState } from 'react';
import Modal from '../../../components/modal';
import AuthAPI from '../../../api/auth/AuthAPI';
import { validationPasswordSchema } from '../validation/passwordSchema';
import { Formik } from 'formik';
import ChangePasswordModalForm from './Form';
import { useNotification } from "../../../context/NotificationContext";

function ChangePasswordModal({ closeChangePasswordModal }) {
    const [submitButtonText, setSubmitButtonText] = useState(
        'Wijzig wachtwoord'
    );
    const showNotification = useNotification();

    function handleSubmit(values, submittingFinished) {
        AuthAPI.changePassword({
            password: values.password,
            password_confirmation: values.passwordConfirmation,
        })
            .then(payload => {
                setSubmitButtonText('Wachtwoord is gewijzigd!');

                setTimeout(() => {
                    closeChangePasswordModal();
                }, 500);
            })
            .catch(error => {
                // If login fails show error and then set submitting back to false
                showNotification('Er is iets misgegaan met de verbinding');
                submittingFinished();
            });
    }

    return (
        <Formik
            initialValues={{ password: '' }}
            validationSchema={validationPasswordSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        title={'Wijzig wachtwoord'}
                        closeModal={closeChangePasswordModal}
                        confirmAction={handleSubmit}
                        confirmText={submitButtonText}
                    >
                        <ChangePasswordModalForm />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default ChangePasswordModal;
