import React, { useState } from 'react';
import moment from 'moment';

const AuthContext = React.createContext();

const LOCAL_STORAGE_TOKEN = '__customer-info-token__';
const LOCAL_STORAGE_LAST_ACTIVITY = '__customer-info-last-activity__';

const AuthProvider = function(props) {
    const [isAuth, setAuth] = useState(checkIfAuth());

    function login(token, callback) {
        // Remove stored filters
        for (const item in localStorage) {
            if (
                item.includes('__customer-info-filters_') ||
                item.includes('__customer-info-load_with_archived_') ||
                item.includes('__customer-info-sort_by_')
            ) {
                localStorage.removeItem(item);
            }
        }

        window.localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
        window.localStorage.setItem(
            LOCAL_STORAGE_LAST_ACTIVITY,
            moment().format()
        );
        setAuth(true);
        callback();
    }

    function logout() {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_LAST_ACTIVITY);

        setAuth(false);
    }

    function checkIfAuth() {
        const token = getToken();
        if (!token) {
            // Redirect to login
            return false;
        }
        return true;
    }

    function getToken() {
        return window.localStorage.getItem(LOCAL_STORAGE_TOKEN);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuth: isAuth,
                login: login,
                logout: logout,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
