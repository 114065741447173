import React from 'react';
import styled from '@emotion/styled';
import InputText from '../../../../../../../components/form/parts/InputText';
import FieldWrapper from '../FieldWrapper';

function SectionField(props) {
    return (
        <FieldWrapper {...props}>
            <StyledLabel>Sectienaam</StyledLabel>
            <StyledDisplayText>
                <InputText
                    {...props}
                    name={`${props.name}.value`}
                    placeholder={'Waarde'}
                />
            </StyledDisplayText>
        </FieldWrapper>
    );
}

export default SectionField;

/* --- START STYLING --- */
const StyledDisplayText = styled.div`
    flex: 4;
    padding: 0.4rem 0;
    color: ${props => props.theme.colors.textDefault};
`;

const StyledLabel = styled.label`
    flex: 2;
    text-align: end;
    padding: ${props => (props.hasInputField ? '0.4' : '1.2')}rem 0.8rem;
    color: ${props => props.theme.colors.textGrey};
`;
/* --- END STYLING --- */
