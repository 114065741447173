import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import categories from '../../../../../../../data/categories';
import Select from '../../../../../../../components/form/parts/Select';
import { IoMdCreate, IoMdWarning } from 'react-icons/all';
import CustomerItemAPI from '../../../../../../../api/customer-item/CustomerItemAPI';
import { CustomerItemsContext } from '../../../../../../../context/CustomerItemsContext';
import { useNotification } from "../../../../../../../context/NotificationContext";
import useCustomerItemPolicy from '../../../../../../../policies/useCustomerItemPolicy';

function ChangeCategoryField(props) {
    const { updateCustomerItem } = useContext(CustomerItemsContext);
    const [field] = useField(props);
    const [showEdit, setShowEdit] = useState(false);
    const hasUpdateCategoryPermission = useCustomerItemPolicy('updateCategory');
    const showNotification = useNotification();

    function handleChangeCategory(selectedOption) {
        if (selectedOption.code === field.value) return;

        CustomerItemAPI.updateCustomerItemCategory(props.customerItemId, {
            categoryCode: selectedOption.code,
        })
            .then(function(payload) {
                updateCustomerItem(payload.data.data);

                toggleShowEdit();
            })
            .catch(function(error) {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het opslaan. Probeer het nogmaals.'
                );
                toggleShowEdit();
            });
    }

    function toggleShowEdit() {
        setShowEdit(!showEdit);
    }

    return (
        <DisplayTextField>
            <StyledLabel htmlFor={props.id || props.name} hasInputField={false}>
                {props.label}
            </StyledLabel>
            <StyledDisplayText>
                {showEdit ? (
                    <>
                        <Select
                            name={'categoryCode'}
                            options={categories}
                            onChangeExtraAction={handleChangeCategory}
                            isClearable={false}
                        />
                        <StyledWarning>
                            <StyledIoMdWarning /> Bij aanpassen categorie wordt dit <u>direct</u> doorgevoerd.
                            <br />
                            Na aanpassen categorie worden de velden van de huidige categorie bewaard. En de velden van
                            de nieuwe categorie toegevoegd.
                            <br />
                            Niet opgeslagen wijzigingen van huidige velden gaan verloren!
                        </StyledWarning>
                    </>
                ) : (
                    <StyledDisplayValue>
                        {
                            categories.find(
                                category => category.code === field.value
                            ).name
                        }
                        {hasUpdateCategoryPermission ? (
                            <StyledChangeButton onClick={toggleShowEdit}>
                                <StyledIconIoMdCreate />
                            </StyledChangeButton>
                        ) : null}
                    </StyledDisplayValue>
                )}
            </StyledDisplayText>
            <StyledActions />
        </DisplayTextField>
    );
}

export default ChangeCategoryField;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
`;

const StyledLabel = styled.label`
    flex: 2;
    text-align: end;
    padding: ${props => (props.hasInputField ? '0.4' : '1.2')}rem 0.8rem;
    color: ${props => props.theme.colors.textGrey};
`;

const StyledDisplayText = styled.div`
    flex: 4;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
`;

const StyledActions = styled.div`
    flex: 1;
`;

const StyledDisplayValue = styled.div`
    flex: 2;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
    white-space: pre-wrap;
    border: 2px dotted transparent;
`;

const StyledChangeButton = styled.span`
    margin-left: 1rem;
    padding: 0;
`;

const StyledIconIoMdCreate = styled(IoMdCreate)`
    font-size: 1.8rem;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
`;

const StyledWarning = styled.small`
    color: ${props => props.theme.colors.danger};
`;

const StyledIoMdWarning = styled(IoMdWarning)`
    font-size: 2rem;
    position: relative;
    top: 0.6rem;
`;
/* --- END STYLING --- */
