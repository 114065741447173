import React from 'react';
import { Formik, Form } from 'formik';
import styled from '@emotion/styled';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import ButtonLinkTertiary from '../../../components/button/ButtonLinkTertiary';
import { validationPasswordSchema } from '../validation/passwordSchema';
import InputPasswordSet from '../../../components/form/InputPasswordSet';

const StyledLoginFormSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledLoginButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
`;

function PasswordNewAccountForm({ handleSubmit, showSuccessMessage }) {
    return (
        <Formik
            initialValues={{ password: '' }}
            validationSchema={validationPasswordSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <StyledLoginFormSection>
                            <InputPasswordSet
                                type="password"
                                label="Nieuw wachtwoord"
                                name="password"
                                placeholder="Nieuw..."
                                autoComplete={'new-password'}
                            />

                            <StyledLoginButtons>
                                <ButtonLinkTertiary to={'/login'}>
                                    Terug naar login
                                </ButtonLinkTertiary>
                                <ButtonPrimary
                                    isLoading={isSubmitting}
                                    disabled={
                                        isSubmitting || showSuccessMessage
                                    }
                                    type={'submit'}
                                >
                                    Stel wachtwoord in
                                </ButtonPrimary>
                            </StyledLoginButtons>
                        </StyledLoginFormSection>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default PasswordNewAccountForm;
