// Notification.js
import React from 'react';
import './Notification.css'; // We'll style the notification later

const Notification = ({ message, type, onClose }) => {
    return (
        <div className={`notification ${type}`}>
            <span>{message}</span>
            <button onClick={onClose}>x</button>
        </div>
    );
};

export default Notification;
