import React from 'react';
import AddUserForm from '../default/Form';
import UserApi from '../../../../api/user/UserAPI';
import Modal from '../../../../components/modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNotification } from "../../../../context/NotificationContext";

function AddUser(props) {
    const showNotification = useNotification();
    function handleSubmit(values, actions) {
        UserApi.createUser({
            ...values,
        })
            .then(function(payload) {
                props.addUserToList(payload.data.data);
                props.closeModal();
            })
            .catch(function(error) {
                console.log(error);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    for (let item in error.response.data.errors) {
                        actions.setFieldError(
                            item,
                            error.response.data.errors[item].join(', ')
                        );
                    }
                } else {
                    showNotification(
                        'Er is iets misgegaan met het opslaan. Probeer het nogmaals.'
                    );
                }
            });
    }

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                duoUsername: '',
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Verplicht'),
                email: Yup.string()
                    .email('Ongeldig e-mail adres')
                    .required('Verplicht'),
                duoUsername: Yup.string().required('Verplicht'),
            })}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                handleSubmit(values, actions);
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        title={'Toevoegen gebruiker'}
                        closeModal={props.closeModal}
                        confirmAction={handleSubmit}
                        confirmText={'Toevoegen'}
                        isLoading={isSubmitting}
                    >
                        <AddUserForm {...props} handleSubmit={handleSubmit} />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default AddUser;
