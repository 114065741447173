import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import categories from '../../../../../data/categories';
import ButtonPrimary from '../../../../../components/button/ButtonPrimary';
import { CustomerItemsContext } from '../../../../../context/CustomerItemsContext';
import DropdownMenu from '../../../../../components/dropdown-menu';
import { FaAngleDown, FaAngleUp } from 'react-icons/all';
import CategoryFieldsAPI from "../../../../../api/category-fields/CategoryFieldsAPI";

function AddItemDropdown({ handleNewItem, customerId, handleScroll }) {
    const { addCustomerItem } = useContext(CustomerItemsContext);

    const [showOptions, setShowOptions] = useState(false);

    function addItem(categoryCode) {
        CategoryFieldsAPI.fetchCategoryFields(categoryCode)
            .then(function(payload) {
                const newCustomerItem = addCustomerItem(
                    categoryCode,
                    customerId,
                    payload.data.data
                );
                handleNewItem(newCustomerItem);
                setShowOptions(false);
            })
            .catch(function(error) {
                console.log(error);
                setShowOptions(false);
            });
    }

    return (
        <Wrapper>
            {showOptions ? (
                <>
                    <ButtonPrimary>
                        Nieuw <StyledIconArrowUp />
                    </ButtonPrimary>
                    <DropdownMenu
                        options={categories}
                        selectItem={addItem}
                        closeDropdown={() => setShowOptions(false)}
                    />
                </>
            ) : (
                <ButtonPrimary onClick={() => setShowOptions(true)}>
                    Nieuw <StyledIconArrowDown />
                </ButtonPrimary>
            )}
        </Wrapper>
    );
}

export default AddItemDropdown;

/* --- START STYLING --- */
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 1rem;
`;

const StyledIconArrowDown = styled(FaAngleDown)`
    color: ${props => props.theme.colors.white};
    font-size: 1.4rem;
    margin-left: 4px;
`;

const StyledIconArrowUp = styled(FaAngleUp)`
    color: ${props => props.theme.colors.white};
    font-size: 1.4rem;
    margin-left: 4px;
`;
/* --- END STYLING --- */
