import React from 'react';
import styled from '@emotion/styled';
import Portal from './Portal';
import ModalHeader from './Header';
import ModalFooter from './Footer';
import ModalBody from './Body';

type Props = {
    children?: React.Node,
    id: string,
    variant: string,
    title: string,
    closeModal: Function,
    confirmAction?: Function,
    confirmText?: string,
    CustomFooter?: Function,
};

const Modal = ({
    id,
    children,
    title,
    closeModal,
    confirmAction,
    confirmText,
    variant,
    customFooter,
}: Props) => {
    return (
        <Portal id={id}>
            <StyledModalContainer>
                <StyledModalContent data-testid={'modal'}>
                    <ModalHeader variant={variant}>{title}</ModalHeader>
                    <ModalBody>{children}</ModalBody>
                    {customFooter ? (
                        customFooter()
                    ) : (
                        <ModalFooter
                            closeModal={closeModal}
                            confirmAction={confirmAction}
                            confirmText={confirmText}
                            variant={variant}
                        />
                    )}
                </StyledModalContent>
            </StyledModalContainer>
        </Portal>
    );
};

Modal.defaultProps = {
    id: 'portal-root',
    variant: 'primary',
};

export default Modal;

const StyledModalContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    outline: 0;
    transition: opacity 0.15s linear;
    padding: 2rem;
`;

const StyledModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 40rem;
    min-height: 20rem;
    background: ${props => props.theme.colors.modal.background};
    border-radius: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);

    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transform: translate(0, -50px);
`;
