import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import categories from '../../../../../../../data/categories';

function ViewCategoryField(props) {
    const [field] = useField(props);

    return (
        <DisplayTextField>
            <StyledLabel htmlFor={props.id || props.name} hasInputField={false}>
                {props.label}
            </StyledLabel>
            <StyledDisplayText>
                <StyledDisplayValue>
                    {
                        categories.find(
                            category => category.code === field.value
                        ).name
                    }
                </StyledDisplayValue>
            </StyledDisplayText>
            <StyledActions />
        </DisplayTextField>
    );
}

export default ViewCategoryField;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
`;

const StyledLabel = styled.label`
    flex: 2;
    text-align: end;
    padding: ${props => (props.hasInputField ? '0.4' : '1.2')}rem 0.8rem;
    color: ${props => props.theme.colors.textGrey};
`;

const StyledDisplayText = styled.div`
    flex: 4;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
`;

const StyledActions = styled.div`
    flex: 1;
`;

const StyledDisplayValue = styled.div`
    flex: 2;
    padding: 0.4rem;
    color: ${props => props.theme.colors.textDefault};
    white-space: pre-wrap;
    border: 2px dotted transparent;
`;
/* --- END STYLING --- */
