import React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../container/dashboard';
import Login from '../container/authorization/login';
import Logout from '../container/authorization/logout';
import Forgot from '../container/authorization/forgot';
import Reset from '../container/authorization/reset';
import PasswordNewAccount from '../container/authorization/password-new-account';
import NotFound from '../container/not-found';
import UserList from '../container/user/list';
import CustomerDetails from '../container/customer/details';

function Routes() {
    return (
        <Router>
            <Switch>
                <ProtectedRoute path="/" exact={true} component={Dashboard} />
                <ProtectedRoute
                    path="/klant/:customerId/:selectedItemId?"
                    component={CustomerDetails}
                />
                <ProtectedRoute path="/gebruikers" component={UserList} />
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/loguit" component={Logout} />
                <PublicRoute path="/wachtwoord-vergeten" component={Forgot} />
                <PublicRoute
                    path="/wachtwoord-wijzigen/:token/:email"
                    component={Reset}
                />
                <PublicRoute
                    path="/wachtwoord-instellen/:token/:email"
                    component={PasswordNewAccount}
                />
                <PublicRoute component={NotFound} />
            </Switch>
        </Router>
    );
}

export default Routes;
