import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
var zxcvbn = require('zxcvbn');

function PasswordStrengthMeter({ password }) {
    if (!password) return null;
    const result = zxcvbn(password);

    function createPasswordLabel(result) {
        switch (result.score) {
            case 0:
                return 'Zeer zwak';
            case 1:
                return 'Zwak';
            case 2:
                return 'Redelijk';
            case 3:
                return 'Goed';
            case 4:
                return 'Sterk';
            default:
                return 'Zwak';
        }
    }

    return (
        <Wrapper className="password-strength-meter">
            <PasswordStrengthMeterProgress value={result.score} max="4" />
            <StyledLabel>
                Wachtwoord sterkte:{' '}
                <strong>{createPasswordLabel(result)}</strong>
            </StyledLabel>
        </Wrapper>
    );
}

export default PasswordStrengthMeter;

/* --- START STYLING --- */
const Wrapper = styled.div`
    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const StyledLabel = styled.div`
    font-size: 1.2rem;
`;

const PasswordStrengthMeterProgress = styled.progress`
    -webkit-appearance: none;
    appearance: none;
    width: 25rem;
    height: 1.2rem;

    ::-webkit-progress-bar {
        background-color: #eee;
        border-radius: 3px;
    }

    ::-moz-progress-bar {
        background-color: #eee;
        border-radius: 3px;
    }

    ::-webkit-progress-value {
        border-radius: 2px;
        background-size: 35px 20px, 100% 100%, 100% 100%;
    }

    ::-moz-progress-bar {
        border-radius: 2px;
        background-size: 35px 20px, 100% 100%, 100% 100%;
    }

    ${props =>
        props.value === 1 &&
        css`
            ::-webkit-progress-value {
                background-color: #f25f5c;
            }
            ::-moz-progress-bar {
                background-color: #f25f5c;
            }
        `};

    ${props =>
        props.value === 2 &&
        css`
            ::-webkit-progress-value {
                background-color: #ffe066;
            }
            ::-moz-progress-bar {
                background-color: #ffe066;
            }
        `};

    ${props =>
        props.value === 3 &&
        css`
            ::-webkit-progress-value {
                background-color: #247ba0;
            }
            ::-moz-progress-bar {
                background-color: #247ba0;
            }
        `};

    ${props =>
        props.value === 4 &&
        css`
            ::-webkit-progress-value {
                background-color: #70c1b3;
            }
            ::-moz-progress-bar {
                background-color: #70c1b3;
            }
        `};
`;
/* --- END STYLING --- */
