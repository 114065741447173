// @flow
import * as React from 'react';
import styled from '@emotion/styled';

const StyledModalBody = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 2rem;
`;

type Props = {
    children?: React.Node,
};

const ModalBody = ({ children }: Props) => {
    return <StyledModalBody>{children}</StyledModalBody>;
};

export default ModalBody;
