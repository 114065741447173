import React from 'react';
import styled from '@emotion/styled';
import SidenavListItem from './SidenavListItem';
import {
    MdDashboard,
    IoMdPeople,
} from 'react-icons/all';

const StyledSidenavList = styled.ul`
    padding: 0;
    margin-top: 2rem;
    list-style-type: none;
`;

function SidenavList({ closeMenu }) {
    return (
        <StyledSidenavList>
            <SidenavListItem
                icon={<MdDashboard />}
                to={'/'}
                closeMenu={closeMenu}
            >
                Dashboard
            </SidenavListItem>
            <SidenavListItem
                icon={<IoMdPeople />}
                to={'/gebruikers'}
                closeMenu={closeMenu}
            >
                Gebruikers
            </SidenavListItem>
        </StyledSidenavList>
    );
}

export default SidenavList;
