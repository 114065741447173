import React, { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { UserContext } from '../context/UserContext';

function IdleTimer({ children, logout }) {
    const { userIP, secureIpAddresses } = useContext(UserContext);
    // If user is 5 minutes inactive then automatically logout except if user ip is secure
    function handleOnIdle() {
        const isSecureIpAddress = secureIpAddresses.some(
            item => item.ipAddress === userIP
        );

        if (isSecureIpAddress) return;
        logout();
    }

    useIdleTimer({
        timeout: 1000 * 60 * 5,
        onIdle: handleOnIdle,
    });

    return <>{children}</>;
}

export default IdleTimer;
