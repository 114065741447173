import styled from '@emotion/styled';

const StyledModalFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export default StyledModalFooter;
