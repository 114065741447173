import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import InputText from './InputText';
import { IoMdEye, IoMdEyeOff } from 'react-icons/all';

const StyledPasswordWrapper = styled.div`
    position: relative;
    max-width: 40rem;
`;

const StyledInputPassword = styled(InputText)`
    width: 100%;
`;

const StyledIcon = styled.span`
    position: absolute;
    right: 1rem;
    margin-top: 0.8rem;
    color: ${props => props.theme.colors.grey};
    font-size: 2rem;
`;

function InputPassword({ autoComplete = 'off', ...props }) {
    const [field] = useField(props);
    const [showPassword, toggleShowPassword] = useState(false);

    return (
        <StyledPasswordWrapper>
            <StyledIcon onClick={() => toggleShowPassword(!showPassword)}>
                {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
            </StyledIcon>
            <StyledInputPassword
                {...props}
                {...field}
                id={props.id || field.name}
                data-testid={field.name}
                autoComplete={autoComplete}
                type={showPassword ? 'text' : 'password'}
            />
        </StyledPasswordWrapper>
    );
}

export default InputPassword;
