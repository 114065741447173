import axiosInstance from '../default-setup/AxiosInstance';

export default {
    fetchLoggedInUser: function() {
        const requestUrl = `/me`;

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    fld: ['id', 'name', 'email'],
                    rlt: {
                        userRoles: { fld: ['roleCode'] },
                    },
                },
            },
        });
    },

    fetchCustomersVisited: function() {
        const requestUrl = `/me`;

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    fld: ['id'],
                    rlt: {
                        customersVisited: {
                            fld: ['id', 'name'],
                        },
                    },
                },
            },
        });
    },

    fetchSecureIpAddresses: function() {
        const requestUrl = `/jory/secure-ip-address`;

        return axiosInstance.get(requestUrl);
    },
};
