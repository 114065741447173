import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import ErrorMessage from './parts/ErrorMessage';
import Label from './parts/Label';
import InputText from './parts/InputText';

const StyledInputTextSet = styled.div`
    display: flex;
    flex-direction: column;
`;

function InputTextSet({ label, required, ...props }) {
    const [field, meta] = useField(props);

    return (
        <StyledInputTextSet>
            <Label htmlFor={field.id || field.name} required={required}>
                {label}
            </Label>
            <InputText {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage testId={field.id || field.name}>
                    {meta.error}
                </ErrorMessage>
            ) : null}
        </StyledInputTextSet>
    );
}

InputTextSet.defaultProps = {
    required: false,
};

export default InputTextSet;
