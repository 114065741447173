import axiosInstance from '../default-setup/AxiosInstance';

const defaultFieldsGrid = {
    fld: [
        'id',
        'categoryCode',
        'title',
        'subTitle',
        'isArchived',
        'createdAt',
        'updatedAt',
    ],
    srt: ['title'],
    rlt: {
        category: { fld: ['name'] },
        customerItemAttachments: [],
    },
};
const defaultFields = {
    fld: [
        'id',
        'categoryCode',
        'title',
        'subTitle',
        'isArchived',
        'previousSavedPasswords',
        'createdAt',
        'updatedAt',
    ],
    srt: ['title'],
    rlt: {
        customerItemFields: {
            fld: [
                'id',
                'customerItemId',
                'categoryFieldId',
                'label',
                'typeCode',
                'value',
                'valueEncrypted',
                'order',
                'isArchived',
                'createdAt',
                'updatedAt',
            ],
            // Fields detached on category are always in front of non category fields
            srt: ['order'],
            // todo WM: Filter op is archived kan wellicht ook met andere nieuwe relation: customerItemActiveFields ?
            flt: {
                field: 'isArchived',
                operator: '=',
                data: false,
            },
        },
        category: { fld: ['name'] },
        customerItemAttachments: {
            fld: ['id',
                'customerItemId',
                'userId',
                'filename',
                'createdAt',
                'updatedAt',
            ]
        },
    },
};

export default {
    fetchCustomerItems: function(customerId, showWithArchived) {
        const requestUrl = `/jory/customer-item`;

        let filter = {
            and: [
                {
                    field: 'customerId',
                    operator: '=',
                    data: customerId,
                },
            ],
        };

        if (!showWithArchived) {
            filter.and.push({
                field: 'isArchived',
                operator: '=',
                data: false,
            });
        }

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    ...defaultFieldsGrid,
                    filter,
                },
            },
        });
    },

    fetchCustomerItem: function(id, customerId) {
        const requestUrl = `/jory/customer-item/${id}`;

        let filter = {
            and: [
                {
                    field: 'customerId',
                    operator: '=',
                    data: customerId,
                },
            ],
        };

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    ...defaultFields,
                    filter,
                },
            },
        });
    },

    storeCustomerItem: function(payload) {
        const requestUrl = `/customer-item`;

        return axiosInstance.post(requestUrl, payload, {
            params: {
                jory: {
                    ...defaultFields,
                },
            },
        });
    },

    updateCustomerItem: function(customerItemId, payload) {
        const requestUrl = `/customer-item/${customerItemId}`;

        return axiosInstance.post(requestUrl, payload, {
            params: {
                jory: {
                    ...defaultFields,
                },
            },
        });
    },

    updateCustomerItemCategory: function(customerItemId, payload) {
        const requestUrl = `/customer-item/${customerItemId}/update-category`;

        return axiosInstance.post(requestUrl, payload, {
            params: {
                jory: {
                    ...defaultFields,
                },
            },
        });
    },

    toggleArchive: function(customerItemId, toggleArchive) {
        const requestUrl = `/customer-item/${customerItemId}/${
            toggleArchive ? 'archive' : 'activate'
        }`;

        return axiosInstance.post(requestUrl);
    },

    addAttachments: function(customerItemId, payload) {
        const requestUrl = `/customer-item/${customerItemId}/store-attachments`;

        return axiosInstance.post(requestUrl, payload, {
            params: {
                jory: {
                    ...defaultFields,
                },
            },
        });
    },
};
