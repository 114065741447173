import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { DefaultWrapper } from '../base-style';
import SidenavListItem from './SidenavListItem';
import { RiShieldKeyholeLine } from 'react-icons/all';
import SidenavListTextDivider from './SidenavListTextDivider';
import { CustomerItemsContext } from '../../../../context/CustomerItemsContext';
import categories from '../../../../data/categories';
import { getCategoryIcon } from '../../../../helpers/getCategoryIcon';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function CustomerSidebar() {
    const {
        handleChangeCategoryFilter,
        activeCategories,
        categoryFilter,
    } = useContext(CustomerItemsContext);

    function renderCategory(activeCategory) {
        const category = categories.find(item => item.code === activeCategory);

        return (
            <SidenavListItem
                key={activeCategory}
                icon={getCategoryIcon(activeCategory, 'white', 2)}
                value={activeCategory}
                action={handleChangeCategoryFilter}
                active={categoryFilter === activeCategory}
            >
                {category ? category.name : activeCategory}
            </SidenavListItem>
        );
    }

    return (
        <SidebarWrapper>
            <OverlayScrollbarsComponent style={{ maxHeight: '100%' }}>
                <StyledSidenavList>
                    <SidenavListItem
                        icon={<RiShieldKeyholeLine />}
                        value={'all_items'}
                        action={handleChangeCategoryFilter}
                        active={categoryFilter === 'all_items'}
                    >
                        Alle items
                    </SidenavListItem>
                    {/*<SidenavListItem*/}
                    {/*    icon={<IoMdStarOutline />}*/}
                    {/*    value={'favorites'}*/}
                    {/*    action={console.log}*/}
                    {/*>*/}
                    {/*    Favorieten*/}
                    {/*</SidenavListItem>*/}
                    <SidenavListTextDivider>
                        Categorieën filter
                    </SidenavListTextDivider>
                    {activeCategories
                        .sort()
                        .map(category => renderCategory(category))}
                </StyledSidenavList>
            </OverlayScrollbarsComponent>
        </SidebarWrapper>
    );
}

export default CustomerSidebar;

/* --- START STYLING --- */
const SidebarWrapper = styled(DefaultWrapper)`
    grid-area: sidebar;
    padding: 1rem 0.8rem;
    background: ${props => props.theme.colors.primaryDark};

    @media only screen and (max-width: 1050px) {
        display: none;
    }
`;

const StyledSidenavList = styled.ul`
    padding: 0;
    margin-top: 2rem;
    list-style-type: none;
`;
/* --- END STYLING --- */
