import React from 'react';
import TableDefault from '../../../../components/table/default';
import { withRouter } from 'react-router-dom';
import styled from "@emotion/styled";


function CustomerListTable({ customers, history }) {
    const columns = React.useMemo(
        () => [
            {
                header: 'Naam',
                accessorKey: 'name',
                width: 200,
                canSort: true,
                canFilter: true,
            },
            {
                header: 'Adres',
                accessorKey: 'address',
                searchKey: 'fullAddress', // use the FullAddress object
                canSort: true,
                canFilter: true,
                customCell: (row) => {
                    const { address, postalCode, city } = row;
                    return (
                        <div>
                            <StyledAddressHeading>{address}</StyledAddressHeading>
                            <StyledAddressSubHeading>{postalCode} {city}</StyledAddressSubHeading>
                        </div>
                    );
                },
                width: 300,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                width: 200,
                canSort: true,
                canFilter: true,
                mobileHidden: true,
            },
            {
                header: 'Telefoon',
                accessorKey: 'telephone',
                width: 200,
                canSort: true,
                canFilter: true,
                mobileHidden: true,
            },
        ],
        []
    );

    return (
        <TableDefault
            tableName={'customers'}
            columns={columns}
            data={customers}
            handleOnClickRow={rowData => {
                history.push(`/klant/${rowData.id}`);
            }}
            defaultPageSize={10}
        />
    );
}

export default withRouter(CustomerListTable);



/* --- START STYLING --- */
const StyledAddressHeading = styled.div`
    line-height: 1.6rem;
`;

const StyledAddressSubHeading = styled.div`
    color: ${props => props.theme.colors.textGrey};
    font-size: 1.1rem;
    line-height: 1.4rem;
    text-transform: uppercase;
`;
/* --- END STYLING --- */
