// @flow
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const StyledLabel = styled.label`
    margin-top: 0;
    color: ${props => props.theme.colors.textGrey};

    ${props =>
        props.required &&
        css`
            &::after {
                content: ' *';
                color: ${props.theme.colors.danger};
                font-size: 1rem;
            }
        `};
`;

type Props = {
    children?: React.Node,
    htmlFor: string,
    required: boolean,
};

function Label({ htmlFor, children, required }: Props) {
    return (
        <StyledLabel htmlFor={htmlFor} required={required}>
            {children}:
        </StyledLabel>
    );
}

Label.defaultProps = {
    required: false,
};

export default Label;
