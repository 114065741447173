import styled from '@emotion/styled';

const H1 = styled.h1`
    font-size: ${props => (props.size ? props.size : '2.4')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textDefault};
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const H2 = styled.h2`
    font-size: ${props => (props.size ? props.size : '2.0')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textDefault};
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const H3 = styled.h3`
    font-size: ${props => (props.size ? props.size : '1.8')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.textDefault};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '300')};
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const H4 = styled.h4`
    font-size: ${props => (props.size ? props.size : '1.6')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textDefault};
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 500;

    :after {
        content: '';
        display: block;
        margin: 0;
        width: 4.8rem;
        padding-top: 4px;
        border-bottom: 3px solid ${props => props.theme.colors.primary};
    }
`;

const H5 = styled.h5`
    font-size: ${props => (props.size ? props.size : '1.4')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textDefault};
`;

const H6 = styled.h6`
    font-size: ${props => (props.size ? props.size : '1.4')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textDefault};
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const SubHeading = styled.span`
    font-size: ${props => (props.size ? props.size : '1.2')}rem;
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textDefault};
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const P = styled.p`
    color: ${props =>
        props.variant ? props.variant : props.theme.colors.textGrey};
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : '1rem'};
`;

export { H1, H2, H3, H4, H5, H6, SubHeading, P };
