import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ReactSelect from 'react-select';
import { useTheme } from 'emotion-theming';
import { withRouter } from 'react-router-dom';
import CustomerAPI from '../../../api/customer/CustomerAPI';
import { useNotification } from "../../../context/NotificationContext";

function CustomerSelect({ history }) {
    const themeApp = useTheme();
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const showNotification = useNotification();
    useEffect(function() {
        (function callFetchCustomer() {
            setLoading(true);
            CustomerAPI.fetchCustomerOptions()
                .then(payload => {
                    setCustomerOptions(payload.data.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    showNotification(
                        'Er is iets misgegaan met het laden van de gegevens. Herlaad de pagina.'
                    );
                    setLoading(false);
                });
        })();
    }, []);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: themeApp.colors.header.background,
            height: '3rem',
            border: '0',
            borderRadius: '0',
            fontSize: '1.5rem',
        }),
        menuList: base => ({
            ...base,
            padding: 0,
            background: themeApp.colors.backgroundContainer,
            color: themeApp.colors.textDefault,
            maxHeight: '80vh',
        }),
        singleValue: (styles, { data }) => ({
            ...styles,
            color: themeApp.colors.textGrey,
        }),
    };

    return (
        <CustomerSelectWrapper>
            <ReactSelect
                options={customerOptions}
                getOptionLabel={option => option['name']}
                getOptionValue={option => option['id']}
                onChange={item => history.push(`/klant/${item.id}`)}
                noOptionsMessage={() => 'Geen klanten'}
                placeholder={'Selecteer klant ...'}
                isLoading={isLoading}
                styles={customStyles}
                value={null}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: themeApp.colors.greyLighter,
                        primary: themeApp.colors.primaryDark,
                        neutral50: themeApp.colors.greyLighter,
                        neutral80: themeApp.colors.white,
                    },
                    spacing: {
                        ...theme.spacing,
                        baseUnit: 2,
                        controlHeight: 24,
                        menuGutter: 4,
                    },
                })}
            />
        </CustomerSelectWrapper>
    );
}

export default withRouter(CustomerSelect);

/* --- START STYLING --- */
const CustomerSelectWrapper = styled.div`
    padding: 0.4rem 1rem;
    width: 40rem;
    margin: 0 2rem;

    @media only screen and (max-width: 960px) {
        width: 16rem;
    }

    @media only screen and (max-width: 1050px) {
        width: 24rem;
    }
`;
/* --- END STYLING --- */
