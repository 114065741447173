import React, { useState } from 'react';
import EditUserForm from '../default/Form';
import UserApi from '../../../../api/user/UserAPI';
import { UserConsumer } from '../../../../context/UserContext';
import Modal from '../../../../components/modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import StyledModalFooter from '../../../../components/modal/style/Footer';
import styled from '@emotion/styled';
import ButtonTertiary from '../../../../components/button/ButtonTertiary';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import ButtonSecondary from '../../../../components/button/ButtonSecondary';
import useUserPolicy from '../../../../policies/useUserPolicy';

function EditUser({
    initialValues,
    updateIsArchivedUser,
    userRoles,
    isSavingArchive,
    ...props
}) {
    const [hasError, setError] = useState(false);

    let currentSelectedRoles = {};
    initialValues.userRoles.forEach(userRole => {
        currentSelectedRoles[userRole.roleCode] = true;
    });

    const hasPermissionArchive = useUserPolicy('archive');

    function handleSubmit(values, actions) {
        callUpdateUserApi(values, actions);
    }

    function callUpdateUserApi(values, actions) {
        UserApi.updateUser(values)
            .then(function(payload) {
                const user = payload.data.data;
                if (user.id === props.user.id) {
                    props.updateUser({
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        userRoles: user.userRoles,
                    });
                }
                props.updateUserInList(user);
                actions.setSubmitting(false);
            })
            .catch(function(error) {
                console.log(error);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    for (let item in error.response.data.errors) {
                        actions.setFieldError(
                            item,
                            error.response.data.errors[item].join(', ')
                        );
                    }
                }

                setError(true);
                actions.setSubmitting(false);
            });
    }

    function customFooter(handleSubmit, isSubmitting) {
        return (
            <StyledCustomFooter>
                <StyledButtonLeftFooter>
                    {hasPermissionArchive ? (
                        initialValues.isArchived ? (
                            <ButtonSecondary
                                variant={'info'}
                                onClick={() =>
                                    updateIsArchivedUser(
                                        initialValues.id,
                                        false
                                    )
                                }
                                isLoading={isSavingArchive}
                            >
                                Activeer
                            </ButtonSecondary>
                        ) : (
                            <ButtonSecondary
                                variant={'danger'}
                                onClick={() =>
                                    updateIsArchivedUser(initialValues.id, true)
                                }
                                isLoading={isSavingArchive}
                            >
                                Archiveer
                            </ButtonSecondary>
                        )
                    ) : null}
                </StyledButtonLeftFooter>
                <StyledButtonRightFooter>
                    <ButtonTertiary onClick={props.closeModal}>
                        Annuleren
                    </ButtonTertiary>
                    <ButtonPrimary
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                        type={'submit'}
                    >
                        Opslaan
                    </ButtonPrimary>
                </StyledButtonRightFooter>
            </StyledCustomFooter>
        );
    }

    return (
        <Formik
            initialValues={{ ...initialValues, ...currentSelectedRoles }}
            validationSchema={Yup.object({
                name: Yup.string().required('Verplicht'),
                email: Yup.string()
                    .email('Ongeldig e-mail adres')
                    .required('Verplicht'),
                duoUsername: Yup.string().required('Verplicht'),
            })}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                handleSubmit(values, actions);
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        title={'Bewerken gebruiker'}
                        closeModal={props.closeModal}
                        confirmAction={handleSubmit}
                        confirmText={'Opslaan'}
                        isLoading={isSubmitting}
                        customFooter={() =>
                            customFooter(handleSubmit, isSubmitting)
                        }
                    >
                        <EditUserForm
                            {...props}
                            handleSubmit={handleSubmit}
                            currentSelectedRoles={currentSelectedRoles}
                            hasError={hasError}
                        />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default function EditUserWithContext(props) {
    return (
        <UserConsumer>
            {({ updateUser, user }) => (
                <EditUser {...props} updateUser={updateUser} user={user} />
            )}
        </UserConsumer>
    );
}

const StyledCustomFooter = styled(StyledModalFooter)`
    justify-content: space-between;
`;

const StyledButtonLeftFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledButtonRightFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;
