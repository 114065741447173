import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DefaultWrapper } from '../base-style';
import useCustomerPolicy from '../../../../policies/useCustomerPolicy';
import ButtonSecondary from '../../../../components/button/ButtonSecondary';
import { IoIosArchive } from 'react-icons/all';

function CustomerDetailsInfo({ customer, toggleArchive }) {
    const [isArchiving, setArchiving] = useState(false);
    const hasArchivePermission = useCustomerPolicy('archive');

    return (
        <CustomerWrapper>
            <CustomerSection>
                <div>
                    <CustomerLabel>Klant</CustomerLabel>
                    <CustomerText>
                        {customer.name}{' '}
                        {customer.isArchived ? (
                            <ArchivedSpan> GEARCHIVEERD</ArchivedSpan>
                        ) : null}
                    </CustomerText>
                </div>
                <div>
                    <CustomerLabel>Adres</CustomerLabel>
                    <CustomerText>{customer.address}</CustomerText>
                </div>
                <div>
                    <CustomerLabel>Email</CustomerLabel>
                    <CustomerText>{customer.email}</CustomerText>
                </div>

                <div>
                    <CustomerLabel>Telefoon</CustomerLabel>
                    <CustomerText>{customer.telephone}</CustomerText>
                </div>
                <div>
                    <CustomerLabel>Postcode / plaats</CustomerLabel>
                    <CustomerText>{`${customer.postalCode} ${customer.city}`}</CustomerText>
                </div>
                {hasArchivePermission ? (
                    <WrapperArchived>
                        <CustomerLabel>Acties</CustomerLabel>
                        {customer.isArchived ? (
                            <ArchiveButton
                                variant="primary"
                                onClick={() => toggleArchive(setArchiving)}
                                isLoading={isArchiving}
                                icon={<IoIosArchive />}
                            >
                                Activeren
                            </ArchiveButton>
                        ) : (
                            <ArchiveButton
                                variant="danger"
                                onClick={() => toggleArchive(setArchiving)}
                                isLoading={isArchiving}
                                icon={<IoIosArchive />}
                            >
                                Archiveren klant
                            </ArchiveButton>
                        )}
                    </WrapperArchived>
                ) : null}
            </CustomerSection>
        </CustomerWrapper>
    );
}

export default CustomerDetailsInfo;

/* --- START STYLING --- */
const ArchiveButton = styled(ButtonSecondary)`
    margin-left: 0;
    height: 20px;
`;

const ArchivedSpan = styled.span`
    color: ${props => props.theme.colors.danger};
    font-size: 1.3rem;
`;

const CustomerWrapper = styled(DefaultWrapper)`
    grid-area: customer;
`;

const CustomerSection = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 1rem;
`;

const CustomerLabel = styled.div`
    margin-top: 0;
    color: ${props => props.theme.colors.textGrey};
    text-transform: uppercase;
    font-size: 1rem;
`;

const CustomerText = styled.div`
    width: 100%;
    max-width: 40rem;
    padding: 0;
    color: ${props => props.theme.colors.textDefault};
    font-size: 1.4rem;
`;

const WrapperArchived = styled.div`
    @media only screen and (max-width: 900px) {
        display: none;
    }
`;
/* --- END STYLING --- */
