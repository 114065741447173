import React from 'react';
import { Form } from 'formik';
import InputTextSet from '../../../../components/form/InputTextSet';
import { H4 } from '../../../../components/typography';
import userRoles from '../../../../data/userRoles';
import InputBadgeSet from '../../../../components/form/InputBadgeSet';
import Card from '../../../../components/layout/card';
import FormSection from '../../../../components/layout/section/Form';

function EditUserForm() {
    return (
        <Form>
            <Card>
                <H4>Algemeen</H4>
                <FormSection>
                    <InputTextSet label="Naam" name="name" required={true} />
                    <InputTextSet label="E-mail" name="email" required={true} />
                    <InputTextSet
                        label="Duo gebruikersnaam"
                        name="duoUsername"
                        required={true}
                    />
                </FormSection>
                <H4>Rollen</H4>
                <FormSection>
                    <InputBadgeSet options={userRoles} />
                </FormSection>
            </Card>
        </Form>
    );
}

export default EditUserForm;
