const categories = [
    { code: 'afspraak', name: 'Afspraak' },
    { code: 'asp', name: 'ASP' },
    { code: 'backup', name: 'Backup' },
    { code: 'beschrijving_netwerk', name: 'Beschrijving netwerk' },
    // { code: 'database', name: 'Database' },
    // { code: 'document', name: 'Document' },
    { code: 'domein_hosting', name: 'Domein hosting' },
    { code: 'email', name: 'Email account' },
    { code: 'garantie', name: 'Garantie' },
    { code: 'internetgegevens', name: 'Internetgegevens' },
    { code: 'ip_camera', name: 'IP Camera' },
    { code: 'laptop', name: 'Laptop' },
    { code: 'login', name: 'Login gegevens' },
    { code: 'mobiele_abonnement', name: 'Mobiele abonnement' },
    { code: 'mobile_devices', name: 'Mobile devices' },
    { code: 'monitoren', name: 'Monitoren' },
    { code: 'netwerkapparatuur', name: 'Netwerkapparatuur' },
    { code: 'notities', name: 'Notities' },
    { code: 'onderdeel', name: 'Onderdeel' },
    { code: 'onderhoud', name: 'Onderhoud' },
    { code: 'printer', name: 'Printer' },
    { code: 'procedures', name: 'Procedures' },
    { code: 'remote_beheer', name: 'Remote Beheer' },
    { code: 'router', name: 'Router' },
    { code: 'server', name: 'Server' },
    { code: 'software', name: 'Software' },
    // { code: 'software_license', name: 'Software licentie' },
    { code: 'switch', name: 'Switch' },
    // { code: 'telecom', name: 'Telecom' },
    { code: 'ups', name: 'UPS' },
    { code: 'voip', name: 'VoIP' },
    { code: 'werkstation', name: 'Werkstation' },
    { code: 'wifi', name: 'Wifi' },
];

export default categories;
