// @flow
import React from 'react';
import styled from '@emotion/styled';

type Props = {
    children?: React.Node,
};

function FormSection({ children }: Props) {
    return <StyledFormSection>{children}</StyledFormSection>;
}

export default FormSection;

const StyledFormSection = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px 20px;
    margin-bottom: 20px;

    /* Medium-sized screen breakpoint (tablet, 768px) */
    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        min-width: 70rem;
    }

    /* Medium-sized screen breakpoint (tablet, 1050px) */
    @media only screen and (min-width: 1050px) {
        justify-self: end;
        max-width: 48rem;
    }

    /* Medium-sized screen breakpoint (tablet, 1280px) */
    @media only screen and (min-width: 1280px) {
        justify-self: end;
        max-width: 64rem;
    }
`;
