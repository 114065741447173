import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from '../../../context/AuthContext';

function Logout({ logout }) {
    // Execute logout procedure
    logout();
    //Redirect to login page
    return <Redirect to={'/login'} />;
}

export default function LogoutWithContext(props) {
    return (
        <AuthConsumer>
            {({ logout }) => <Logout {...props} logout={logout} />}
        </AuthConsumer>
    );
}
