import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

function useCustomerItemPolicy(policy) {
    const { userRoles } = useContext(UserContext);
    let hasPermission = false;

    userRoles.forEach(userRole => {
        if (policies[policy].includes(userRole)) {
            hasPermission = true;
        }
    });

    return hasPermission;
}

export default useCustomerItemPolicy;

const policies = {
    create: ['admin', 'employee_expanded', 'employee'],
    update: ['admin', 'employee_expanded', 'employee'],
    archive: ['admin', 'employee_expanded', 'employee'],
    updateCategory: ['admin', 'employee_expanded'],
};
