import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

function useCustomerPolicy(policy) {
    const { userRoles } = useContext(UserContext);
    let hasPermission = false;

    userRoles.forEach(userRole => {
        if (policies[policy].includes(userRole)) {
            hasPermission = true;
        }
    });

    return hasPermission;
}

export default useCustomerPolicy;

const policies = {
    archive: ['admin', 'employee_expanded', 'employee'],
};
