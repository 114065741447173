import React, { createContext, useEffect, useState } from 'react';
import PublicIp from 'react-public-ip';

const UserContext = createContext({
    user: { id: 1, name: 'test' },
    userRoles: [],
    updateUser: () => {},
    userIP: null,
    secureIpAddresses: [],
});

const UserProvider = function(props) {
    const [user, setUser] = useState({});
    const [userRoles, setUserRoles] = useState([]);
    const [userIP, setUserIP] = useState(null);
    const [secureIpAddresses, setSecureIpAddresses] = useState([]);

    useEffect(function() {
        PublicIp.v4().then(function(payload) {
            setUserIP(payload);
        });
    }, []);

    function updateUser(user) {
        setUser(user);

        let userRoles = [];
        user.userRoles.forEach(role => {
            userRoles.push(role.roleCode);
        });
        setUserRoles(userRoles);
    }

    function resetCurrentUserToDefault() {
        setUser({});
        setUserRoles([]);
    }

    function updateSecureIpAddress(payload) {
        setSecureIpAddresses(payload);
    }

    return (
        <UserContext.Provider
            value={{
                user: user,
                userRoles: userRoles,
                updateUser: updateUser,
                resetCurrentUserToDefault,
                updateSecureIpAddress,
                userIP,
                secureIpAddresses,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

const UserConsumer = UserContext.Consumer;

export { UserProvider, UserConsumer, UserContext };
