// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';

const StyledInput = styled.input`
    width: 100%;
    max-width: 40rem;
    margin-top: 4px;
    padding: 8px;
    border-radius: 3px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${props => props.theme.colors.grey};
    background: ${props => props.theme.colors.backgroundContainer};
    color: ${props => props.theme.colors.textDefault};

    &::placeholder {
        color: ${props => props.theme.colors.grey};
        background: ${props => props.theme.colors.backgroundContainer};
    }

    &:focus {
        outline: none;
    }
`;

type Props = {
    autoComplete: string,
    id?: string,
};

function InputText({ autoComplete, id, ...props }: Props) {
    const [field] = useField(props);

    return (
        <StyledInput
            {...props}
            {...field}
            id={id || field.name}
            autoComplete={autoComplete}
        />
    );
}

InputText.defaultProps = {
    autoComplete: 'off',
};

export default InputText;
