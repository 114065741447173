import React from 'react';
import styled from '@emotion/styled';
import SidenavList from './SidenavList';
import MenuCloseButton from './MenuCloseButton';
import LogoXarisImage from '../../../images/logo-xaris.png';

const StyledSidenav = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    min-width: 240px;
    width: 240px;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    background: ${props => props.theme.colors.sidenav.background};
    overflow-y: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    z-index: 20;
    -webkit-transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out;
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out;
    left: ${props => (props.menuOpen ? '0' : '-240px')};
`;

const StyledLogoSection = styled.div`
    display: inline-flex;
    padding: 0 1rem;
    justify-content: center;
    margin-top: 10px;
`;

const StyledLogoImage = styled.img`
    max-height: 4rem;
`;

const StyledName = styled.h2`
    display: inline-flex;
    justify-content: center;
    color: ${props => props.theme.colors.sidenav.nameColor};
    font-size: 1.7rem;
    font-weight: 400;
    font-family: 'Nanum Gothic', 'Lato', sans-serif;
    text-transform: uppercase;
`;

const StyledNameBold = styled.span`
    font-weight: 800;
`;

function Sidenav({ menuOpen, closeMenu }) {
    return (
        <StyledSidenav menuOpen={menuOpen} data-testid="sidenav">
            <StyledLogoSection>
                <StyledLogoImage src={LogoXarisImage} />
            </StyledLogoSection>
            <StyledName>
                Customer<StyledNameBold>Info</StyledNameBold>
            </StyledName>
            <MenuCloseButton closeMenu={closeMenu} />
            <SidenavList closeMenu={closeMenu} />
        </StyledSidenav>
    );
}

export default Sidenav;
