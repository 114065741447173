import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PasswordNewAccountForm from './Form';
import AuthAPI from '../../../api/auth/AuthAPI';
import { AuthConsumer } from '../../../context/AuthContext';
import CommonContainer from '../../../components/layout/common-container';
import LogoXarisImage from '../../../images/logo-xaris.png';
import styled from '@emotion/styled';
import { H3, P } from '../../../components/typography';
import { useNotification } from "../../../context/NotificationContext";
import DuoModal from '../duo-modal';

const StyledLogoImage = styled.img`
    position: relative;
    padding: 0 1rem;
    max-width: 28rem;
`;

const StyledLoginSection = styled.section`
    margin: 3rem auto;
    max-width: 50rem;
`;

const StyledUl = styled.ul`
    margin-left: 2.4rem;
    color: ${props => props.theme.colors.textGrey};
    margin-bottom: 1rem;
`;

const PasswordNewAccount = ({ location, match, login }) => {
    const [showSuccessMessage, toggleSuccessMessage] = useState(false);
    const [duoSettings, setDuoSettings] = useState({
        host: '',
        sigRequest: '',
    });
    const [loginCredentials, setLoginCredentials] = useState(null);

    const token = decodeURIComponent(match.params.token);
    const email = decodeURIComponent(match.params.email);

    const showNotification = useNotification();

    const [redirectToReferrer, toggleRedirect] = useState(false);
    let { from } = location.state || { from: { pathname: '/' } };

    function handleSubmit(values, submittingFinished) {
        AuthAPI.reset({
            token,
            email,
            password: values.password,
        })
            .then(payload => {
                toggleSuccessMessage(true);
                AuthAPI.login({ email, password: values.password })
                    .then(payload => {
                        setDuoSettings({
                            host: payload.data.host,
                            sigRequest: payload.data.sigRequest,
                        });
                        setLoginCredentials({ email, ...values });
                    })
                    .catch(error => {
                        // If login fails show error and then set submitting back to false
                        showNotification(
                            'Er is iets misgegaan met de verbinding'
                        );
                        submittingFinished();
                    });
            })
            .catch(error => {
                // If login fails show error and then set submitting back to false
                showNotification('Er is iets misgegaan met de verbinding');
                submittingFinished();
            });
    }

    function duoAuthenticatedApproved(form) {
        // Verify code on server
        AuthAPI.verifyDuoResponse({
            ...loginCredentials,
            signedResponse: form.sig_response.value,
        })
            .then(function(payload) {
                if (!payload.data.access_token) {
                    setDuoSettings({
                        host: '',
                        sigRequest: '',
                    });
                    showNotification(
                        'Er is iets misgegaan met de autorisatie. Probeer het nogmaals.'
                    );
                    return;
                }
                // Then log user in
                login(payload.data.access_token, () => toggleRedirect(true));
            })
            .catch(function() {
                showNotification(
                    'Er is iets misgegaan met de autorisatie. Probeer het nogmaals.'
                );
            });
    }

    function redirect() {
        return <Redirect to={from} />;
    }

    return (
        <>
            {redirectToReferrer ? (
                redirect()
            ) : (
                <CommonContainer>
                    <StyledLogoImage src={LogoXarisImage} />
                    <StyledLoginSection>
                        {showSuccessMessage ? (
                            <>
                                <H3>Je wachtwoord is ingesteld</H3>
                                <P>
                                    Login 2 factor authenticatie wordt geopend
                                </P>
                                {duoSettings.sigRequest ? (
                                    <DuoModal
                                        {...duoSettings}
                                        duoAuthenticatedApproved={
                                            duoAuthenticatedApproved
                                        }
                                        closeModal={() =>
                                            setDuoSettings({
                                                host: '',
                                                sigRequest: '',
                                            })
                                        }
                                    />
                                ) : null}
                            </>
                        ) : (
                            <>
                                <H3>Wachtwoord instellen nieuw account</H3>
                                <P>
                                    Stel hier jouw wachtwoord voor het account:{' '}
                                    <u>{email}</u>
                                </P>
                                <P marginBottom={'0.6rem'}>
                                    Voor de veiligheid van jouw account, moet
                                    het wachtwoord voldoen aan de volgende
                                    criteria:
                                </P>
                                <StyledUl>
                                    <li>
                                        Lengte moet <em>minimaal 8 tekens</em>{' '}
                                        zijn
                                    </li>
                                    <li>
                                        Wachtwoord moet een <em>hoofdletter</em>{' '}
                                        bevatten
                                    </li>
                                    <li>
                                        Wachtwoord moet een <em>cijfer</em>{' '}
                                        bevatten
                                    </li>
                                </StyledUl>
                                <PasswordNewAccountForm
                                    handleSubmit={handleSubmit}
                                    email={email}
                                />
                            </>
                        )}
                    </StyledLoginSection>
                </CommonContainer>
            )}
        </>
    );
};

export default function PasswordNewAccountWithContext(props) {
    return (
        <AuthConsumer>
            {({ login }) => <PasswordNewAccount {...props} login={login} />}
        </AuthConsumer>
    );
}
