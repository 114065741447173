import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { CustomerItemsContext } from '../../../../../context/CustomerItemsContext';
import DropdownMenu from '../../../../../components/dropdown-menu';
import { FaAngleDown, FaAngleUp } from 'react-icons/all';
import sortingTypes from '../../../../../data/sortingTypes';

function SortItemsDropdown({ handleSelectItem, customerId, handleScroll }) {
    const { handleChangeSort } = useContext(CustomerItemsContext);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedSorting, setSelectedSorting] = useState('titleAZ');

    function handleChangeSelectedSorting(selectedItem) {
        setSelectedSorting(selectedItem);
        handleChangeSort(selectedItem);
        setShowOptions(false);
    }

    return (
        <Wrapper>
            {showOptions ? (
                <>
                    <StyledButton>
                        {
                            sortingTypes.find(
                                option => option.code === selectedSorting
                            ).name
                        }{' '}
                        <StyledIconArrowUp />
                    </StyledButton>
                    <DropdownMenu
                        options={sortingTypes}
                        selectItem={handleChangeSelectedSorting}
                        closeDropdown={() => setShowOptions(false)}
                        position={'bottom'}
                    />
                </>
            ) : (
                <StyledButton onClick={() => setShowOptions(true)}>
                    {
                        sortingTypes.find(
                            option => option.code === selectedSorting
                        ).name
                    }{' '}
                    <StyledIconArrowDown />
                </StyledButton>
            )}
        </Wrapper>
    );
}

export default SortItemsDropdown;

/* --- START STYLING --- */
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const StyledButton = styled.button`
    border: 0;
    font-weight: 400;
    letter-spacing: 0.05rem;
    font-size: 1.3rem;
    cursor: pointer;
    height: 24px;
    background: ${props => props.theme.colors.backgroundContainer};

    &:active,
    :hover {
        background: ${props => props.theme.colors.lightgray};
    }

    &:active {
        transform: scale(0.99);
    }

    &:focus {
        outline: 0;
    }
`;

const StyledIconArrowDown = styled(FaAngleDown)`
    color: ${props => props.theme.colors.gray};
    font-size: 1.4rem;
    margin-left: 4px;
`;

const StyledIconArrowUp = styled(FaAngleUp)`
    color: ${props => props.theme.colors.gray};
    font-size: 1.4rem;
    margin-left: 4px;
`;
/* --- END STYLING --- */
