import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import LoadingView from '../../../components/loading-view';
import CustomerDetailsInfo from './info';
import CustomerItemList from './customer-item/list';
import CustomerItemDetails from './customer-item/details';
import CustomerSidebar from './sidebar';
import { CustomerItemsContext } from '../../../context/CustomerItemsContext';

function CustomerDetails({ match }) {
    const [showNew, setShowNew] = useState(false);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [newCustomerItem, setNewCustomerItem] = useState('');

    const {
        setCustomerId,
        customer,
        setSelectedCustomerItemId,
        isLoadingCustomer,
        isLoadingItems,
        toggleCustomerArchive,
        duplicateCustomerItem,
        handleChangeCategoryFilter,
        handleChangeSearchFilter,
    } = useContext(CustomerItemsContext);

    // Set the customer ID based on the match.params.customerId
    useEffect(() => {
            setCustomerId(match.params.customerId ? match.params.customerId : null);
        },
        [match.params.customerId, setCustomerId]
    );
    // Set the selectedCustomerItem ID based on the match.params.selectedItemId
    // and before that set serch and category filters on initial values
    useEffect(() => {
        handleChangeSearchFilter('');
        handleChangeCategoryFilter('all_items');
        setSelectedCustomerItemId(match.params.selectedItemId ? match.params.selectedItemId : null);
    }, [match.params.selectedItemId, setSelectedCustomerItemId]);

    function toggleShowNew() {
        setShowNew(!showNew);
    }
    function toggleShowDuplicate() {
        setShowDuplicate(!showDuplicate);
    }
    function handleSetSelectEditItem(id) {
        setShowNew(false)
        setShowDuplicate(false);
        setSelectedCustomerItemId(id)
    }
    function handleSelectItem(id) {
        setSelectedCustomerItemId(id)
    }
    function handleNewItem(newCustomerItem) {
        setNewCustomerItem(newCustomerItem)
        setShowNew(true);
    }
    function handleDuplicateItem(duplicatedCustomerItem) {
        setNewCustomerItem(duplicateCustomerItem(duplicatedCustomerItem, match.params.customerId))
        setShowDuplicate(true);
    }
    function handleSetSelectNewItem(id) {
        setShowNew(false);
        setShowDuplicate(false);
        setSelectedCustomerItemId(id)
    }

    if (isLoadingCustomer || isLoadingItems) {
        return (
            <LoadingContainer>
                <LoadingView />
            </LoadingContainer>
        );
    } else {
        return (
            <Container>
                <CustomerSidebar />
                <CustomerItemList
                    handleSelectItem={handleSelectItem}
                    handleNewItem={handleNewItem}
                />
                <CustomerDetailsInfo
                    customer={customer}
                    toggleArchive={toggleCustomerArchive}
                />
                <CustomerItemDetails
                    handleSetSelectEditItem={handleSetSelectEditItem}
                    toggleShowNew={toggleShowNew}
                    showNew={showNew}
                    toggleShowDuplicate={toggleShowDuplicate}
                    showDuplicate={showDuplicate}
                    newCustomerItem={newCustomerItem}
                    handleDuplicateItem={handleDuplicateItem}
                    handleSetSelectNewItem={handleSetSelectNewItem}
                />
            </Container>
        );
    }
}

export default CustomerDetails;

/* --- START STYLING --- */
const LoadingContainer = styled.div`
    margin: 20px;
    padding: 20px;
    background: ${props => props.theme.colors.backgroundContainer};
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    width: 100%;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 24rem 24rem auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'sidebar sidebar-list customer'
        'sidebar sidebar-list details';
    grid-gap: 1rem;
    margin: 1rem;
    height: calc(100vh - 7rem);
    width: calc(100vw - 2rem);

    @media only screen and (max-width: 1050px) {
        grid-template-columns: 24rem 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            'sidebar-list customer'
            'sidebar-list details';
    }

    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-template-rows: 10rem 20rem 1fr;
        grid-template-areas:
            'customer'
            'sidebar-list'
            'details';
        width: 100%;
        grid-gap: 0.4rem;
        margin: 0.4rem;
    }
`;
/* --- END STYLING --- */
