import React from 'react';
import { Form } from 'formik';
import styled from '@emotion/styled';
import InputPasswordSet from '../../../components/form/InputPasswordSet';

const StyledLoginFormSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

function ChangePasswordModalForm({ handleSubmit, showSuccessMessage }) {
    return (
        <Form>
            <StyledLoginFormSection>
                <InputPasswordSet
                    type="password"
                    label="Nieuw wachtwoord"
                    name="password"
                    placeholder="Nieuw..."
                    autoComplete="new-password"
                />
            </StyledLoginFormSection>
        </Form>
    );
}

export default ChangePasswordModalForm;
