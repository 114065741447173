import React from 'react';
import styled from '@emotion/styled';

function SectionField({ field }) {
    return (
        <DisplayTextField key={field.id}>
            <StyledSection>{field.value}</StyledSection>
        </DisplayTextField>
    );
}

export default SectionField;

/* --- START STYLING --- */
const DisplayTextField = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    min-width: 40rem;

    @media only screen and (max-width: 900px) {
        min-width: 100%;
        align-self: flex-start;
    }
`;

const StyledSection = styled.div`
    padding: 0.4rem;
    padding-top: 2rem;
    color: ${props => props.theme.colors.textGrey};
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 300;
`;
/* --- END STYLING --- */
