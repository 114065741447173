// @flow
import React from 'react';
import ReactSelect from 'react-select';
import { useField, useFormikContext } from 'formik';
import { useTheme } from 'emotion-theming';

type Option = {
    code?: string,
    id?: number,
    name: string,
};

type Props = {
    options: Array<Option>,
    valueKey: string,
    valueLabel: string,
    placeholder: string,
    disabled: boolean,
    isClearable: boolean,
    // onChangeExtraAction: function,
};

function Select({
    options,
    valueKey,
    valueLabel,
    placeholder,
    onChangeExtraAction,
    id,
    disabled,
    isClearable,
    ...props
}: Props) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const themeApp = useTheme();

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: themeApp.colors.backgroundContainer,
            marginTop: '4px',
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            background: themeApp.colors.background,
        }),
        singleValue: (styles, { data }) => ({
            ...styles,
            color: themeApp.colors.textDefault,
        }),
    };
    return (
        <ReactSelect
            options={options}
            getOptionLabel={option => option[valueLabel]}
            getOptionValue={option => option[valueKey]}
            noOptionsMessage={() => 'Geen opties'}
            isClearable={isClearable}
            placeholder={placeholder}
            onChange={option => {
                option
                    ? setFieldValue(field.name, option[valueKey])
                    : setFieldValue(field.name, null);
                onChangeExtraAction(option);
            }}
            className={field.name}
            data-id={field.name}
            data-testid={field.name}
            isDisabled={disabled}
            value={
                options
                    ? field.value
                        ? options.find(
                              option => option[valueKey] === field.value
                          )
                        : ''
                    : ''
            }
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: themeApp.colors.greyLight,
                    primary: themeApp.colors.primaryDark,
                },
                spacing: {
                    ...theme.spacing,
                    baseUnit: 2,
                    controlHeight: 24,
                    menuGutter: 4,
                },
            })}
            styles={customStyles}
        />
    );
}

Select.defaultProps = {
    valueKey: 'code',
    valueLabel: 'name',
    placeholder: 'Selecteer',
    disabled: false,
    isClearable: true,
    onChangeExtraAction: function() {},
};

export default Select;
