import React from 'react';
import Modal from '../../../components/modal';
import DuoWebSDK from 'duo_web_sdk';
import styled from '@emotion/styled';

function DuoModal({ host, sigRequest, duoAuthenticatedApproved, closeModal }) {
    DuoWebSDK.init({
        iframe: 'duo-frame',
        host: host,
        sig_request: sigRequest,
        submit_callback: duoAuthenticatedApproved, //someCallback,
    });

    return (
        <Modal title={'DUO authenticatie'} closeModal={closeModal}>
            <StylediFrame id="duo-frame" />
        </Modal>
    );
}

export default DuoModal;

const StylediFrame = styled.iframe`
    width: 100%;
    min-width: 30rem;
    max-width: 62rem;
    height: 33rem;
    border: none;
`;
