import axiosInstance from '../default-setup/AxiosInstance';

const defaultFields = {
    fld: [
        'id',
        'name',
        'email',
        'duoUsername',
        'roleNamesString',
        'isArchived',
    ],
    rlt: {
        userRoles: {
            fld: ['id', 'userId', 'roleCode'],
        },
    },
};

export default {
    fetchUsers: function(showWithArchived) {
        const requestUrl = `/jory/user`;

        let filter = {};
        if (!showWithArchived) {
            filter = {
                flt: {
                    field: 'isArchived',
                    operator: '=',
                    data: false,
                },
            };
        }

        return axiosInstance.get(requestUrl, {
            params: {
                jory: {
                    ...defaultFields,
                    srt: ['name'],
                    ...filter,
                },
            },
        });
    },

    createUser: function(payload) {
        const requestUrl = `/user`;

        return axiosInstance.post(requestUrl, payload, {
            params: {
                jory: defaultFields,
            },
        });
    },

    updateUser: function(payload) {
        const requestUrl = `/user/${payload.id}`;

        return axiosInstance.post(requestUrl, payload, {
            params: {
                jory: defaultFields,
            },
        });
    },

    setToArchivedUser: function(id, setToArchived) {
        let requestUrl = `/user/${id}/`;
        requestUrl += setToArchived ? `archive` : `activate`;

        return axiosInstance.post(requestUrl, null, {
            params: {
                jory: defaultFields,
            },
        });
    },
};
