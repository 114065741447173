import axios from 'axios';
import axiosInstance from '../default-setup/AxiosInstance';

const BASE_URL = process.env.REACT_APP_API_URL;

export default {
    login: loginCredentials => {
        const requestUrl = `${BASE_URL}/auth/login`;

        return axios.post(requestUrl, loginCredentials);
    },

    verifyDuoResponse: payload => {
        const requestUrl = `${BASE_URL}/auth/verify-duo-response`;

        return axios.post(requestUrl, payload);
    },

    forgot: email => {
        const requestUrl = `${BASE_URL}/auth/send-reset-link`;

        return axios.post(requestUrl, email);
    },

    reset: payload => {
        const requestUrl = `${BASE_URL}/auth/reset-password`;

        return axios.post(requestUrl, payload);
    },

    changePassword: payload => {
        const requestUrl = `${BASE_URL}/me`;

        return axiosInstance.post(requestUrl, payload);
    },
};
