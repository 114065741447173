import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

function useUserPolicy(policy) {
    const { userRoles } = useContext(UserContext);
    let hasPermission = false;

    userRoles.forEach(userRole => {
        if (policies[policy].includes(userRole)) {
            hasPermission = true;
        }
    });

    return hasPermission;
}

export default useUserPolicy;

const policies = {
    create: ['admin'],
    update: ['admin'],
    archive: ['admin'],
};
