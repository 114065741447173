import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { keyframes } from '@emotion/core';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: center;
    align-items: center;
`;

const dots = keyframes`
    0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow: .45rem 0 0 rgba(0,0,0,0), .9rem 0 0 rgba(0,0,0,0);
    }
    40% {
    color: black;
    text-shadow: .45rem 0 0 rgba(0,0,0,0), .9rem 0 0 rgba(0,0,0,0);
    }
    60% {
    text-shadow: .45rem 0 0 black, .9rem 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
    text-shadow: .45rem 0 0 black, .9rem 0 0 black;
    }
`;

const LoadingText = styled.h4`
    font-size: 1.6rem;
    color: ${props => props.theme.colors.textDefault};
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 300;

    :after {
        content: '.';
        animation: ${dots} 3s steps(5, end) infinite;
        font-size: 2.6rem;
    }
`;

function LoadingView() {
    const theme = useTheme();

    return (
        <StyledContainer>
            <ClipLoader color={theme.colors.primary} size={48} />
            <LoadingText>Bezig met ophalen van de data</LoadingText>
        </StyledContainer>
    );
}

export default LoadingView;
