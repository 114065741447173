import React from 'react';
import styled from '@emotion/styled';
import CustomerAPI from '../../../api/customer/CustomerAPI';
import { useNotification } from "../../../context/NotificationContext";

const StyledFooter = styled.div`
    grid-area: footer;
    background-color: ${props => props.theme.colors.header.background};
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    font-size: 1.2rem;
`;

const StyledLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    font-size: 1.1rem;
`;


function Footer() {
    const showNotification = useNotification();

    function fetchImportCustomer() {
        CustomerAPI.importCustomers()
            .then(function() {
                showNotification(
                    'Klanten importeren succesvol afgerond. Herlaad de pagina.',
                    'success'
                );
            })
            .catch(function() {
                showNotification(
                    'Er is iets misgegaan met het importeren. Probeer het nogmaals.'
                );
            });
    }

    return (
        <StyledFooter>
            <StyledLink
                onClick={fetchImportCustomer}
                title={'Importeer klanten uit XcrmPlus'}
            >
                IMPORTEER KLANTEN
            </StyledLink>
            <div className="footer__copyright">&copy; 2020 Xaris ICT BV</div>
            <div className="footer__copyright" />
        </StyledFooter>
    );
}

export default Footer;
