import React from 'react';
import styled from '@emotion/styled';

const StyledMenuCloseButton = styled.div`
    position: absolute;
    visibility: visible;
    top: 8px;
    right: 2px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    padding: 8px;
    background: transparent;
    border-radius: 50%;

    &:hover,
    :active {
        cursor: pointer;
        //background: ${props => props.theme.colors.hamburgerMenu.background};
    }

    &:before,
    :after {
        position: absolute;
        top: 9px;
        left: 17px;
        content: ' ';
        height: 20px;
        width: 3px;
        border-radius: 3px;
        background: ${props =>
            props.theme.colors.hamburgerMenu.barsColorSidenav};
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

function MenuCloseButton({ closeMenu }) {
    return (
        <StyledMenuCloseButton onClick={closeMenu} data-testid="close-menu" />
    );
}

export default MenuCloseButton;
