// @flow
import styled from '@emotion/styled';
import React from 'react';
import {
    IoMdApps,
    MdEmail,
    MdPhoneIphone,
    IoIosKey,
    FaDatabase,
    IoMdDocument,
    MdWifi,
    FaServer,
    IoIosApps,
    MdComputer,
    MdRouter,
    IoMdSwitch,
    MdDateRange,
    FiMonitor,
    IoMdGitNetwork,
    MdReceipt,
    MdSettingsRemote,
    IoMdList,
    MdDeviceHub,
    MdSmartphone,
    IoMdBuild,
    FaRegStickyNote,
    MdKeyboardVoice,
    FaProcedures,
    IoMdCellular,
    MdBackup,
    IoMdPrint,
    FaLaptop,
    IoMdBatteryFull,
    IoMdCloud,
    IoMdCog,
    MdVideocam,
} from 'react-icons/all';

export function getCategoryIcon(
    category: string,
    color: string = 'greyDark',
    size: number = 2
) {
    let icon;
    switch (category) {
        case 'database':
            icon = <FaDatabase />;
            break;
        case 'document':
            icon = <IoMdDocument />;
            break;
        case 'email':
            icon = <MdEmail />;
            break;
        case 'login':
            icon = <IoIosKey />;
            break;
        case 'server':
            icon = <FaServer />;
            break;
        case 'software':
            icon = <IoIosApps />;
            break;
        case 'software_license':
            icon = <IoMdApps />;
            break;
        case 'telecom':
            icon = <MdPhoneIphone />;
            break;
        case 'wireless_router':
            icon = <MdWifi />;
            break;
        case 'werkstation':
            icon = <MdComputer />;
            break;
        case 'router':
            icon = <MdRouter />;
            break;
        case 'printer':
            icon = <IoMdPrint />;
            break;
        case 'switch':
            icon = <IoMdSwitch />;
            break;
        case 'ups':
            icon = <IoMdBatteryFull />;
            break;
        case 'afspraak':
            icon = <MdDateRange />;
            break;
        case 'monitoren':
            icon = <FiMonitor />;
            break;
        case 'beschrijving_netwerk':
            icon = <IoMdGitNetwork />;
            break;
        case 'garantie':
            icon = <MdReceipt />;
            break;
        case 'laptop':
            icon = <FaLaptop />;
            break;
        case 'remote_beheer':
            icon = <MdSettingsRemote />;
            break;
        case 'internetgegevens':
            icon = <IoMdList />;
            break;
        case 'ip_camera':
            icon = <MdVideocam />;
            break;
        case 'netwerkapparatuur':
            icon = <MdDeviceHub />;
            break;
        case 'mobile_devices':
            icon = <MdSmartphone />;
            break;
        case 'onderhoud':
            icon = <IoMdBuild />;
            break;
        case 'notities':
            icon = <FaRegStickyNote />;
            break;
        case 'voip':
            icon = <MdKeyboardVoice />;
            break;
        case 'procedures':
            icon = <FaProcedures />;
            break;
        case 'mobiele_abonnement':
            icon = <IoMdCellular />;
            break;
        case 'asp':
            icon = <IoMdCloud />;
            break;
        case 'backup':
            icon = <MdBackup />;
            break;
        case 'domein_hosting':
            icon = <FaServer />;
            break;
        case 'onderdeel':
            icon = <IoMdCog />;
            break;
        default:
            icon = <IoMdApps />;
            break;
    }

    return (
        <StyledIcon size={size} color={color}>
            {icon}
        </StyledIcon>
    );
}

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    color: ${props => props.theme.colors[props.color]};
    flex: 1;
    font-size: ${props => props.size}rem;
`;
