import React from 'react';
import TableDefault from '../../../components/table/default';
import ReactSelect from 'react-select';
import useUserPolicy from '../../../policies/useUserPolicy';
import userRoles from '../../../data/userRoles';

function UserListTable({ users, setSelectedRowData, setShowEditModal }) {
    const columns = React.useMemo(
        () => [
            {
                header: 'Naam',
                accessorKey: 'name',
                width: 200,
                canSort: true,
                canFilter: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                width: 200,
                canSort: true,
                canFilter: true,
            },
            {
                header: 'Rol(len)',
                accessorKey: 'roleNamesString',
                width: 200,
                canSort: true,
                canFilter: true,
                Filter: selectCustomRolesFilter,
                filter: 'equal',

            },
        ],
        []
    );

    function handleOnClickRow(rowData) {
        setSelectedRowData(rowData);
        setShowEditModal(true);
    }

    return (
        <TableDefault
            tableName={'users'}
            columns={columns}
            data={users}
            handleOnClickRow={rowData => {
                handleOnClickRow(rowData);
            }}
            defaultPageSize={20}
            usePolicy={useUserPolicy}
            usePolicyType={'update'}
        />
    );
}

export default UserListTable;

/* --- START CUSTOM FILTERS --- */
// User roles filter
function selectCustomRolesFilter({ column: { filterValue, setFilter } }) {
    let options = [{ code: null, name: 'Alles' }, ...userRoles];

    return (
        <ReactSelect
            options={options}
            onChange={option =>
                setFilter(option.name === 'Alles' ? null : option.name)
            }
            getOptionLabel={option => option.name}
            getOptionValue={option => option.name}
            value={
                options
                    ? options.find(option => option.name === filterValue)
                    : ''
            }
            defaultValue={options[0]}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: '#d8d9e1', //TODO get from props
                    primary: '#358fd1', //TODO get from props
                },
                spacing: {
                    ...theme.spacing,
                    baseUnit: 1,
                    controlHeight: 20,
                    menuGutter: 2,
                },
            })}
        />
    );
}
/* --- END FILTERS --- */
