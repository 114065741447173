// @flow

// Return message from http status code
export function getMessageFromHttpCode(httpStatusCode: number) {
    switch (httpStatusCode) {
        case 401:
            return 'Uw inloggegevens zijn onjuist';
        case 500:
            return 'Er is iets misgegaan met de verbinding';
        default:
            return 'Een onbekende fout is opgetreden';
    }
}
