// @flow
import * as React from 'react';
import styled from '@emotion/styled';
import { ClipLoader } from 'react-spinners';

type Props = {
    children?: React.Node,
    type: string,
    variant: string,
    isLoading?: boolean,
};

function ButtonPrimaryTiny(props: Props) {
    return (
        <StyledButton {...props} disabled={props.isLoading}>
            {props.children}{' '}
            {props.isLoading ? <ClipLoader color={'white'} size={17} /> : null}
        </StyledButton>
    );
}

ButtonPrimaryTiny.defaultProps = {
    type: 'button',
    variant: 'primary',
};

export default ButtonPrimaryTiny;

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: ${props => props.theme.colors.button[props.variant].background};
    color: ${props => props.theme.colors.button[props.variant].color};
    border-radius: 3px;
    font-weight: 400;
    letter-spacing: 0.05rem;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 4px 6px;
    height: 20px;
    margin-left: 4px;

    &:active,
    :hover {
        background: ${props =>
            props.theme.colors.button[props.variant].backgroundDark};
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
    }

    &:active {
        transform: scale(0.99);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    }

    &:focus {
        outline: 0;
    }
`;
