// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';
import usePortal from '../../helpers/usePortal';

type Props = {
    children?: React.Node,
    id: string,
};

/**
 * @example
 * <Portal>
 *   <p>Thinking with portals</p>
 * </Portal>
 */
const Portal = ({ id, children }: Props) => {
    const target = usePortal(id);
    return createPortal(children, target);
};

export default Portal;
