import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { IoMdSearch } from 'react-icons/all';
import SearchAPI from '../../../api/search/SearchAPI';
import { ClipLoader } from 'react-spinners';
import { useNotification } from "../../../context/NotificationContext";
import SearchResult from './Result';
import { H4 } from '../../../components/typography';

function Search() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const showNotification = useNotification();

    function handleChangeSearch(e) {
        const value = e.target.value;

        setSearchTerm(value);

        if (!value) {
            setSearchResult([]);
        }
    }

    // Do search when enter is pressed and searchTerm has a value
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    function handleSearch() {
        if (searchTerm.length <= 1) return;

        setIsSearching(true);

        SearchAPI.fetchSearch({ searchTerm: searchTerm })
            .then(function(payload) {
                setSearchResult(payload.data);
                setIsSearching(false);
            })
            .catch(function(error) {
                console.log(error);
                showNotification(
                    'Er is iets misgegaan met het zoeken. Probeer het nogmaals, of neem contact met ons op.'
                );
                setIsSearching(false);
            });
    }

    return (
        <>
            <StyledWrapper>
                <StyledH4>Algemeen zoeken</StyledH4>
                <StyledSearchInputWrapper>
                    <StyledInput
                        value={searchTerm}
                        onChange={handleChangeSearch}
                        placeholder={'Zoek klant, item of waarde ...'}
                        onKeyPress={handleKeyPress}
                    />
                    <StyledIcon
                        onClick={handleSearch}
                        active={searchTerm.length > 1}
                        almostActive={searchTerm.length === 1}
                        isSearching={isSearching}
                    >
                        {isSearching ? (
                            <ClipLoader color={'white'} size={17} />
                        ) : (
                            <IoMdSearch />
                        )}
                    </StyledIcon>
                </StyledSearchInputWrapper>
            </StyledWrapper>
            {searchResult.length && searchTerm.length ? (
                <SearchResult
                    searchResult={searchResult}
                    searchTerm={searchTerm}
                />
            ) : null}
        </>
    );
}

export default Search;

const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledSearchInputWrapper = styled.div`
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    height: 3.6rem;
    width: 40rem;

    /* Medium-sized screen breakpoint (tablet, 1050px) */
    @media only screen and (max-width: 1050px) {
        width: 100%;
    }
`;

const StyledIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    font-size: 2rem;
    background-color: ${props => props.theme.colors.primary};
    color: rgba(255, 255, 255, 0.7);
    transition-duration: 0.4s;
    transition-property: color;

    ${props =>
        props.active &&
        css`
            color: ${props.theme.colors.white};
            width: 8rem;
            transition-duration: 0.4s;
            transition-property: color;

            &:after {
                content: ' Zoeken';
                font-size: 1.4rem;
                cursor: pointer;
            }
        `}

    ${props =>
        props.almostActive &&
        css`
            width: 10rem;
            transition-duration: 0.4s;
            transition-property: color;

            &:after {
                content: ' Min.2 tekens';
                font-size: 1.2rem;
            }
        `}

    ${props =>
        props.isSearching &&
        css`
            &:after {
                content: '';
            }
        `}
`;

const StyledInput = styled.input`
    border: 0;
    padding: 8px;
    flex: 1;
    font-size: 1.5rem;
    background: ${props => props.theme.colors.backgroundContainer};
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.08);

    &::placeholder {
        background: ${props => props.theme.colors.backgroundContainer};
    }

    &:focus {
        outline: none;
    }
`;

const StyledH4 = styled(H4)`
    margin-top: 0;
    margin-bottom: 0;
`;
