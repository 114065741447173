import React from 'react';
import styled from '@emotion/styled';

const StyledMenuHamburgerButton = styled.div`
    width: 36px;
    padding: 8px;
    background: transparent;
    border-radius: 50%;

    &:hover,
    active {
        cursor: pointer;
        background: ${props => props.theme.colors.hamburgerMenu.background};
    }

    @media only screen and (min-width: 80rem) {
        display: none;
    }
`;

const StyledMenuHamburgerBar = styled.div`
    background-color: ${props => props.theme.colors.hamburgerMenu.barsColor};
    margin: 3px 0;
    border-radius: 3px;
    height: 3px;
`;

function MenuHamburgerButton({ setMenuOpen, menuOpen }) {
    return (
        <StyledMenuHamburgerButton
            data-testid={'open-menu'}
            onClick={() => setMenuOpen(!menuOpen)}
        >
            <StyledMenuHamburgerBar />
            <StyledMenuHamburgerBar />
            <StyledMenuHamburgerBar />
        </StyledMenuHamburgerButton>
    );
}

export default MenuHamburgerButton;
