// @flow
import * as React from 'react';
import styled from '@emotion/styled';
import { H3 } from '../typography';

const StyledModalHeader = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 2rem;
    background-color: ${props =>
        props.theme.colors.modal[props.variant].backgroundHeader};
    color: white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
`;

type Props = {
    children?: React.Node,
    variant: string,
};

const ModalHeader = ({ children, variant }: Props) => {
    return (
        <StyledModalHeader variant={variant}>
            <H3 variant={'white'}>{children}</H3>
        </StyledModalHeader>
    );
};

ModalHeader.defaultProps = {
    variant: 'primary',
};

export default ModalHeader;
