import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import CustomerAPI from '../../../../api/customer/CustomerAPI';
import CustomerListTable from './Table';
import LoadingView from '../../../../components/loading-view';
import TableTopBar from '../../../../components/table/top-bar';
import { useNotification } from "../../../../context/NotificationContext";

const LOCAL_STORAGE_WITH_ARCHIVED = `__customer-info-load_with_archived_customers__`;

function CustomerList() {
    const [customers, setCustomers] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const showNotification = useNotification();

    const storedWithShowArchived = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_WITH_ARCHIVED)
    );
    const [showWithArchived, setShowWithArchived] = useState(
        storedWithShowArchived || false
    );

    useEffect(
        function() {
            (function callFetchCustomer() {
                setLoading(true);
                CustomerAPI.fetchCustomers(showWithArchived)
                    .then(payload => {
                        setCustomers(payload.data.data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        showNotification(
                            'Er is iets misgegaan met het laden van de gegevens. Herlaad de pagina.'
                        );
                        setLoading(false);
                    });
            })();
        },
        [showWithArchived]
    );

    function handleChangeShowWithArchived(value) {
        localStorage.setItem(
            LOCAL_STORAGE_WITH_ARCHIVED,
            JSON.stringify(value)
        );
        setShowWithArchived(value);
    }

    if (isLoading) {
        return (
            <LoadingContainer>
                <LoadingView />
            </LoadingContainer>
        );
    } else {
        return (
            <Container>
                <TableTopBar
                    showWithArchived={showWithArchived}
                    handleChangeShowWithArchived={handleChangeShowWithArchived}
                />
                <CustomerListTable
                    customers={customers}
                />
            </Container>
        );
    }
}

export default CustomerList;

/* --- START STYLING --- */
const Container = styled.div`
    width: 100%;
`;
const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;
/* --- END STYLING --- */
